import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoRadio } from "@disco-ui"
import { Grid, RadioGroup } from "@material-ui/core"
import classNames from "classnames"

interface IconRadioOption {
  label?: string
  icon: React.ReactElement
  value: string
  key?: string
  className?: string
}

interface Props {
  options: IconRadioOption[]
  value?: string | null
  onChange(value: string): void
}

/**
 * A grid of icons that act as radio buttons which can have a value selected.
 * Alternatively, you could just have each button perform an action on click.
 */
export default function DiscoIconRadioGroup(props: Props) {
  const { options, value, onChange } = props

  const classes = useStyles()

  return (
    <RadioGroup
      value={value || ""}
      onChange={handleChange}
      classes={{ root: classes.radioGroup }}
    >
      <Grid container spacing={2}>
        {options.map((option) => {
          const checked = option.value === value
          return (
            <Grid key={option.key || option.value} item xs={6}>
              <DiscoRadio
                classes={{ root: classNames(classes.radioRoot) }}
                controlClasses={{
                  root: classNames(classes.radioControlRoot, option.className, {
                    checked,
                  }),
                  label: classes.radioControlLabel,
                }}
                value={option.value}
                label={option.icon}
              />
            </Grid>
          )
        })}
      </Grid>
    </RadioGroup>
  )

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange(e.target.value)
  }
}

const useStyles = makeUseStyles((theme) => ({
  radioGroup: {},
  // The root of each individual button
  radioControlRoot: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(1.5, 2.5),
    borderRadius: theme.measure.borderRadius.medium,
    borderWidth: 0,
    "&.checked": {
      background: theme.palette.groovy.neutral[100],
    },
    "&:hover": {
      borderWidth: 0,
    },
  },
  // Center the SVG
  radioControlLabel: {
    lineHeight: 0,
    margin: "auto",
  },
  // Hide actual radio button input. We use Radio for accessibility / correctness
  radioRoot: {
    display: "none",
  },
}))
