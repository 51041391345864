import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoInput from "@disco-ui/input/DiscoInput"
import DiscoSelect from "@disco-ui/select/DiscoSelect"
import { GOLANG_DURATION_UNIT_LABELS } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import pluralize from "pluralize"

interface Props extends TestIDProps {
  value: string | null | undefined
  onChange(v: string): void
  disabled?: boolean
}

function DiscoDurationInput(props: Props) {
  const { value, onChange, disabled, testid = "DiscoDurationInput" } = props

  const classes = useStyles()

  const amount = value?.slice(0, -1)
  const unit = value?.slice(-1)

  return (
    <div className={classes.container}>
      <DiscoInput
        data-testid={`${testid}.number-input`}
        classes={{ input: classes.input }}
        type={"number"}
        placeholder={"4"}
        value={Number(amount) || null}
        onChange={(e) => onChange(`${e.target.value}${unit}`)}
        disabled={disabled}
      />
      <DiscoSelect
        testid={`${testid}.unit-select`}
        classes={{ root: classes.unitOptionsInput }}
        placeholder={"Hours"}
        disableClearable
        value={unit}
        onChange={(newUnit) => {
          if (!newUnit) return
          onChange(`${amount || "1"}${newUnit}`)
        }}
        options={Object.entries(GOLANG_DURATION_UNIT_LABELS).map(([v, title]) => ({
          value: v,
          title: pluralize(title, value ? parseInt(value) : 0),
        }))}
        autoComplete={false}
        disabled={disabled}
      />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(1),
  },
  input: {
    maxWidth: "100px",
    fontSize: "16px",
  },
  unitOptionsInput: {
    width: "100%",
    maxWidth: "250px",
  },
}))

export default DiscoDurationInput
