import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import DiscoInput, { DiscoInputProps } from "@disco-ui/input/DiscoInput"
import { TestIDProps } from "@utils/typeUtils"
import React, { useRef } from "react"
import { ChromePicker, ColorResult } from "react-color"

type Props = Omit<DiscoInputProps, "onChange" | "ref" | "endAdornment"> &
  TestIDProps & {
    value: string | null | undefined
    hideText?: boolean
    onChange(v: string): void
  }

const DiscoTabColorPicker: React.FC<Props> = (props) => {
  const { value, onChange, disabled, hideText = false, testid, ...rest } = props
  const inputRef = useRef<HTMLElement | null>(null)
  const classes = useStyles()
  const handleColorChange = (data: ColorResult) => {
    const alphaHex = data.rgb.a
      ? Math.round(data.rgb.a * 255)
          .toString(16)
          .padStart(2, "0")
      : "00"
    const hexWithAlpha = `${data.hex}${alphaHex}`
    onChange(hexWithAlpha)
  }
  return (
    <div className={classes.container}>
      <ChromePicker
        className={classes.chromePicker}
        color={value || undefined}
        onChange={handleColorChange}
      />
      <DiscoInput
        ref={(e) => (inputRef.current = e)}
        type={hideText ? "hidden" : ""}
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        endAdornment={
          <div
            className={classes.colorBadge}
            style={{ background: value || undefined }}
          />
        }
        disabled={disabled}
        data-testid={testid}
        fullWidth
        {...rest}
      />
    </div>
  )
}

export default DiscoTabColorPicker

const useStyles = makeUseStyles((theme) => ({
  chromePicker: {
    width: `100% !important`,
    marginTop: theme.spacing(3),
    boxShadow: "unset !important",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    alignItems: "center",
  },
  colorBadge: {
    // Square badge
    height: "24px",
    width: "24px",
    flexShrink: 0,
    // Rounded border with outline for when color = background color
    borderRadius: theme.measure.borderRadius.default,
    border: `1px solid white`,
    outline: "none",
    cursor: "pointer",
  },
}))
