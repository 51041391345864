import makeUseStyles from "@assets/style/util/makeUseStyles"
import { range } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"

interface DiscoDrawerHeaderProgressDividerProps extends TestIDProps {
  totalItems: number
  activeItemIndex: number
}
function DiscoDrawerHeaderProgressDivider({
  totalItems,
  activeItemIndex,
}: DiscoDrawerHeaderProgressDividerProps) {
  const classes = useProgressStyles()
  return (
    <div className={classes.container}>
      {range(totalItems).map((item, i) => (
        <div
          key={item}
          className={classNames(classes.item, {
            [classes.completedItem]: i <= activeItemIndex,
            [classes.rounded]: i === activeItemIndex && i < totalItems - 1,
          })}
        />
      ))}
    </div>
  )
}

const useProgressStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
    height: theme.spacing(1),
  },
  item: {
    backgroundColor: theme.palette.groovy.neutral[100],
    height: "100%",
    flex: "1 1 0",
  },
  completedItem: {
    backgroundColor: theme.palette.primary.main,
  },
  rounded: {
    borderTopRightRadius: theme.measure.borderRadius.default,
    borderBottomRightRadius: theme.measure.borderRadius.default,
  },
}))

export default observer(DiscoDrawerHeaderProgressDivider)
