import { TestIDProps } from "@utils/typeUtils"
import { useRef } from "react"
import { Button, useDayRender } from "react-day-picker"

export interface DayProps extends TestIDProps {
  /** The month where the date is displayed. */
  displayMonth: Date
  /** The date to render. */
  date: Date
}

function CalendarDay(props: DayProps) {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const { date, displayMonth, testid } = props
  const dayRender = useDayRender(date, displayMonth, buttonRef)

  if (dayRender.isHidden) {
    return <></>
  }
  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />
  }
  return (
    <Button
      ref={buttonRef}
      data-testid={`${testid}.day`}
      name={"day"}
      {...dayRender.buttonProps}
    />
  )
}

export default CalendarDay
