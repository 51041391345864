import { TestIDProps } from "@utils/typeUtils"
import React, { Suspense } from "react"

export interface IconsaxIconProps extends TestIDProps, React.SVGProps<SVGSVGElement> {
  icon: string
  variant?: "bold" | "linear"
}

const linearCtx = require.context("../../core/ui/iconsax/linear", false, /\.svg$/)
const boldCtx = require.context("../../core/ui/iconsax/bold", false, /\.svg$/)
const logoCtx = require.context("../../core/ui/disco/icons/color-logo", false, /\.svg$/)
const linearIcons = importFromContext(linearCtx)
const boldIcons = importFromContext(boldCtx)
const logoIcons = importFromContext(logoCtx)

function importFromContext(context: __WebpackModuleApi.RequireContext) {
  return context.keys().reduce<Record<string, any>>((icons, path) => {
    // The name of the icon will be the filename without the extension
    const iconName = path.split("/").pop()?.replace(".svg", "")

    // Import the icon and add it to the icons object
    if (iconName) icons[iconName] = context(path).default

    return icons
  }, {})
}

function IconsaxIcon({ icon, variant = "linear", testid, ...props }: IconsaxIconProps) {
  let Icon
  if (icon.startsWith("logo-")) {
    Icon = logoIcons[icon]
  } else if (variant === "bold") {
    Icon = boldIcons[icon]
  } else {
    Icon = linearIcons[icon]
  }

  if (!Icon) return null

  return (
    <Suspense fallback={() => null}>
      <Icon data-testid={testid} {...props} />
    </Suspense>
  )
}

export default IconsaxIcon
