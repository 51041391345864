import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import PageContent from "@/main/page/content/PageContent"
import HeaderContent, { HeaderContentProps } from "@/main/page/header/HeaderContent"
import { DiscoTextSkeleton } from "@disco-ui"
import { Grid, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import classNames from "classnames"
import { ClassNameMap } from "notistack"
import React from "react"

export interface DiscoPageProps {
  headerContent?: React.ReactNode
  subtabs?: React.ReactNode
  contentClasses?: {
    productTabContent?: string
  }
  pageRightColumn?: React.ReactNode
  children: React.ReactNode
  className?: string
  HeaderProps?: Pick<
    HeaderContentProps,
    | "appKey"
    | "leftIcon"
    | "title"
    | "descriptionTooltip"
    | "rightOptions"
    | "rightOverflow"
    | "rightTitleContent"
  >
  classes?: Partial<ClassNameMap<"container" | "content" | "main" | "rhs">>
}

function DiscoPage({
  children,
  subtabs,
  className,
  contentClasses,
  pageRightColumn,
  headerContent,
  HeaderProps,
  classes: propsClasses,
}: DiscoPageProps) {
  const classes = useStyles()
  return (
    <>
      {headerContent || <HeaderContent {...HeaderProps} />}
      <PageContent
        rightColumn={pageRightColumn}
        customClassName={className}
        classes={propsClasses}
      >
        <div
          className={classNames(
            classes.productTabContent,
            contentClasses?.productTabContent
          )}
        >
          {subtabs && <div className={classes.subtabContainer}>{subtabs}</div>}
          {children}
        </div>
      </PageContent>
    </>
  )
}

export const DiscoPageSkeleton: React.FC = ({ children }) => {
  const classes = useStyles({})
  const theme = useTheme()

  return (
    <PageContent>
      {children || (
        <>
          <DiscoTextSkeleton
            variant={"heading-xl"}
            width={"30%"}
            className={classes.productTabHeader}
          />
          <div className={classes.productTabContent}>
            {/* Just some random blocks to take up space... */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Skeleton
                  variant={"rect"}
                  style={{
                    borderRadius: theme.measure.borderRadius.default,
                    height: "300px",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Skeleton
                  variant={"rect"}
                  style={{
                    borderRadius: theme.measure.borderRadius.default,
                    height: "300px",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Skeleton
                  variant={"rect"}
                  style={{
                    borderRadius: theme.measure.borderRadius.default,
                    height: "300px",
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </PageContent>
  )
}

const useStyles = makeUseStyles((theme) => ({
  productTabHeader: {
    marginBottom: theme.spacing(1),
  },
  productTabContent: {
    position: "relative",
    flexGrow: 1,
  },
  subtabContainer: {
    marginBottom: theme.spacing(3),
  },
}))

export default DiscoPage
