import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import classnames from "classnames"
import { useEffect, useState } from "react"
import { VirtuosoGrid } from "react-virtuoso"

export interface DiscoGridProps<T> {
  /** A callback function that will give you access to the
   * data that you passed in and returns the item to render.
   * NOTE: The rendered items should have a fixed height.
   */
  item: (index: number, data: T[]) => React.ReactNode
  /** The data that will be passed into the item callback function */
  data: T[]
  /** This prop should be used if pagination is required */
  refetch?: {
    isLoading: boolean
    hasNext: boolean
    loadMore: VoidFunction
  }
  /** Update the layout of the grid, default is a 3 column layout for large screens */
  listClassName?: string
  /** Custom scroll parent selector
   *  This is useful when the virtualized list isn't rendered in the page element (e.g. When it is rendered in a modal),
   *  we will need to pass a different scroll parent than the app page layout scroll container.
   */
  customScrollParentSelector?: string
}

function DiscoGrid<T extends Record<string, any>>({
  data,
  item,
  refetch,
  listClassName,
  customScrollParentSelector,
}: DiscoGridProps<T>) {
  const classes = useStyles()
  const [el, setEl] = useState<HTMLElement | null>(null)
  useEffect(() => {
    setEl(
      document.querySelector<HTMLElement>(
        customScrollParentSelector || "#app-page-layout-scroll-container"
      )
    )
  }, [customScrollParentSelector])

  if (!el) return null

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <VirtuosoGrid
        style={{ width: "100%" }}
        totalCount={data.length}
        context={data}
        itemContent={item}
        listClassName={classnames(classes.list, listClassName)}
        customScrollParent={el}
      />
      {refetch?.hasNext && (
        <DiscoScrolledIntoView
          isLoading={refetch.isLoading}
          onScrolledIntoView={refetch.loadMore}
        />
      )}
    </div>
  )
}

export function DiscoGridSkeleton({ children }: { children: React.ReactNode }) {
  const classes = useStyles()
  return <div className={classes.list}>{children}</div>
}

const useStyles = makeUseStyles((theme) => ({
  list: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
}))

export default DiscoGrid
