import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { Spacing } from "@assets/style/appMuiTheme"
import makeSpacingStyles from "@assets/style/util/makeSpacingStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import { LinearProgress, LinearProgressProps } from "@material-ui/core"

export type DiscoProgressBarProps = {
  /**
   * The value of the progress bar.
   * This values is between 0 and 100
   */
  percentage: number
  /**
   * The buffer value of the progress bar.
   * This values is between 0 and 100, default is 0
   */
  bufferPercentage?: number
  /**
   * The custom width.
   *  Default width is 100%
   */
  width?: string
  /**
   * The custom height.
   *  Default height is 8px
   */
  height?: string
  /**
   * The custom colour.
   *  Default colour green[400]
   */
  color?: string
  /**
   * The custom buffer colour.
   *  Default colour green[200]
   */
  bufferColor?: string
  classes?: {
    root?: string
    bar?: string
  }
} & Spacing &
  Pick<LinearProgressProps, "variant">

function DiscoProgressBar(props: DiscoProgressBarProps) {
  const {
    percentage,
    bufferPercentage,
    width,
    height,
    color,
    bufferColor,
    classes: customClasses,
    variant = "buffer",
    ...spacing
  } = props

  const spacingStyles = useSpacingStyles({
    margin: spacing.margin ?? 0,
    padding: spacing.padding ?? 0,
    ...spacing,
  })

  const classes = useStyles({
    width,
    height,
    color,
    bufferColor,
  })

  return (
    <LinearProgress
      variant={variant}
      classes={mergeClasses(classes, customClasses, spacingStyles)}
      value={percentage}
      valueBuffer={bufferPercentage || 0}
    />
  )
}

type StyleProps = {
  width?: string
  height?: string
  color?: string
  bufferColor?: string
}

const useStyles = makeUseStyles((theme) => ({
  root: (props: StyleProps) => ({
    display: "inline-block",
    width: props.width ?? "100%",
    height: props.height ?? "8px",
    borderRadius: theme.measure.borderRadius.default,
  }),
  bar: {
    borderRadius: theme.measure.borderRadius.default,
  },
  colorPrimary: {
    backgroundColor: theme.palette.groovy.grey[100],
  },
  barColorPrimary: (props: StyleProps) => ({
    backgroundColor: props.color ?? theme.palette.groovy.green[400],
  }),
  bar2Buffer: (props: StyleProps) => ({
    backgroundColor: props.bufferColor ?? theme.palette.groovy.green[200],
  }),
  dashed: {
    display: "none",
  },
}))

const useSpacingStyles = makeSpacingStyles()

export default DiscoProgressBar
