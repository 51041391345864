import makeUseStyles from "@assets/style/util/makeUseStyles"
import { FC } from "react"

const DiscoChartTooltipContent: FC = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.tooltip}>{children}</div>
}

const useStyles = makeUseStyles((theme) => ({
  tooltip: {
    boxShadow: theme.palette.groovyDepths.boxShadow,
    backgroundColor: theme.palette.groovy.grey[700],
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(1, 1.5),
    textAlign: "left",
  },
}))

export default DiscoChartTooltipContent
