import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoIcon, DiscoInput, DiscoInputProps } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import classnames from "classnames"
import format from "date-fns/format"
import React from "react"

interface TimeInputProps extends Pick<DiscoInputProps, "fullWidth"> {
  testid: string
  value: Date | null
  onChange: (timeString: string) => void
  isDisabled?: boolean
  placeholder?: string
  name?: string
  error?: boolean
  className?: string
}

function DiscoTimeInput({
  testid,
  value,
  onChange,
  isDisabled = false,
  placeholder = format(new Date(), DATE_FORMAT.LONG_TIME_FORMAT),
  name = "",
  error,
  className,
  ...rest
}: TimeInputProps) {
  const inputTime = value ? format(value, "HH:mm") : ""
  const classes = useStyles()
  const theme = useTheme()

  return (
    <DiscoInput
      type={"time"}
      data-testid={`${testid}.time`}
      className={classnames(className, classes.input)}
      name={name}
      value={inputTime}
      placeholder={placeholder}
      disabled={isDisabled || !value}
      onChange={handleChange}
      error={error}
      {...rest}
      endAdornment={<DiscoIcon icon={"time"} color={theme.palette.text.primary} />}
    />
  )

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    // Not supporting clearable time inputs right now.
    if (!event.target.value) return
    // The input value is in 24h format HH:mm
    // Send onChange time string with seconds set to 0
    onChange(`${event.target.value}:00`)
  }
}

const useStyles = makeUseStyles({
  input: {
    position: "relative",

    // Make the clock icon bigger
    "& input[type='time']::-webkit-calendar-picker-indicator": {
      display: "none",
    },
  },
})

export default DiscoTimeInput
