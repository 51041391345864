import { useTheme } from "@material-ui/core"
import { buildChartTheme } from "@visx/xychart"

// Custom theme for the XYChart
export default function useCustomChartTheme() {
  const theme = useTheme()

  return buildChartTheme({
    backgroundColor: theme.palette.background.default,
    // required colors for series, we don't really use these since our charts are monotone
    colors: [
      theme.palette.text.primary,
      theme.palette.text.primary,
      theme.palette.text.primary,
    ],
    gridColor: theme.palette.text.secondary,
    gridColorDark: theme.palette.text.secondary,
    svgLabelBig: { fill: theme.palette.text.primary },
    svgLabelSmall: { fill: theme.palette.text.primary },
    tickLength: 8,
  })
}
