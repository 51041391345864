import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import classNames from "classnames"
import React from "react"

interface DiscoImageProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  testid?: string
}

const DiscoImage = React.forwardRef<HTMLImageElement, DiscoImageProps>((props, ref) => {
  const { alt, testid, className, src, ...rest } = props
  const classes = useStyles({ height: rest.height, width: rest.width })
  return (
    <img
      ref={ref}
      alt={alt}
      data-testid={testid}
      src={src}
      className={classNames(classes.img, className)}
      {...rest}
    />
  )
})

type StyleProps = {
  height?: DiscoImageProps["height"]
  width?: DiscoImageProps["width"]
}

const useStyles = makeUseStyles({
  img: ({ width, height }: StyleProps) => ({
    width: width ?? "100%",
    height: height ?? "auto",
  }),
})

export default DiscoImage
