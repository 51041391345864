import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import DiscoText from "@disco-ui/text/DiscoText"
import { CuePoint } from "@disco-ui/video/DiscoVideo"
import { TranscriptReaderFragment$key } from "@disco-ui/video/interactive-player/tabs/transcript-reader/__generated__/TranscriptReaderFragment.graphql"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { graphql, useFragment } from "react-relay"
import { Virtuoso } from "react-virtuoso"

interface Props extends TestIDProps {
  assetTranscriptionKey: TranscriptReaderFragment$key | null
  onTimeChange: (time: number) => void
  activeCuePoint: CuePoint | undefined
}

function TranscriptReader(props: Props) {
  const { assetTranscriptionKey, activeCuePoint, onTimeChange } = props
  const classes = useStyles()
  const transcription = useFragment<TranscriptReaderFragment$key>(
    graphql`
      fragment TranscriptReaderFragment on AssetTranscription {
        segments {
          text
          start
          startTimecode
        }
      }
    `,
    assetTranscriptionKey
  )

  if (!transcription?.segments?.length) return null

  return (
    // Use Virtuoso to render the list of segments because it's a long list
    <Virtuoso
      data={transcription.segments}
      itemContent={(_, s) => {
        const isSegmentActive = activeCuePoint?.time === s.start

        return (
          <DiscoContainerButton
            key={s.start}
            className={classNames(classes.segment, {
              [classes.isActive]: isSegmentActive,
            })}
            onClick={() => onTimeChange(s.start)}
          >
            <DiscoText className={classes.timecode}>{s.startTimecode}</DiscoText>
            <DiscoText className={classes.text}>{s.text}</DiscoText>
          </DiscoContainerButton>
        )
      }}
      style={{ height: 448 }}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  segment: {
    display: "flex",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.medium,
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.neutral[100],
    },
  },
  isActive: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.aiColors.blueDark
        : theme.palette.groovy.blue[100],
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.aiColors.blueDark
          : theme.palette.groovy.blue[100],
    },
  },
  text: {
    color:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[100]
        : theme.palette.text.secondary,
  },
  timecode: {
    textAlign: "right",
    // Make all numbers to have the same space, this makes the timecode align vertically
    fontVariantNumeric: "tabular-nums",
    color:
      theme.palette.type === "dark"
        ? theme.palette.groovy.blue[300]
        : theme.palette.primary.main,
  },
}))

export default TranscriptReader
