import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Modal, { ModalProps } from "@components/modal/Modal"
import {
  DiscoButton,
  DiscoButtonProps,
  DiscoFormControl,
  DiscoIconButton,
  DiscoInput,
  DiscoText,
} from "@disco-ui"
import { useMemo, useState } from "react"

interface WarningModalProps extends ModalProps {
  title: string
  description: string | React.ReactNode
  testid: string
  confirmButtonProps: DiscoButtonProps
  typeToConfirmText?: string
  disableTypeToConfirm?: boolean
}

function DiscoConfirmationModal({
  isOpen,
  onClose,
  title,
  description,
  typeToConfirmText,
  testid,
  confirmButtonProps,
  modalContentLabel,
  disableTypeToConfirm = false,
  zIndex,
}: WarningModalProps) {
  const classes = useStyles({})

  const [safeWordInputValue, setSafeWordInputValue] = useState<string>("")
  const valid = useMemo(() => {
    if (typeToConfirmText) return typeToConfirmText !== safeWordInputValue
    return false
  }, [typeToConfirmText, safeWordInputValue])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      customClassName={classes.container}
      modalContentLabel={modalContentLabel}
      zIndex={zIndex}
    >
      <div className={classes.header}>
        <DiscoIconButton onClick={onClose}>
          <CloseIcon />
        </DiscoIconButton>
        <DiscoText variant={"heading-sm"} data-testid={`${testid}.title`}>
          {title}
        </DiscoText>
      </div>
      <div className={classes.body}>
        {typeof description === "string" ? (
          <DiscoText data-testid={`${testid}.description`}>
            <span className={classes.warningText}>{`WARNING: `}</span>
            {description}
          </DiscoText>
        ) : (
          description
        )}

        {!disableTypeToConfirm && (
          <DiscoFormControl
            label={
              <DiscoText
                variant={"body-sm"}
                className={classes.typeToConfirmText}
              >{`Type "${typeToConfirmText}" to confirm:`}</DiscoText>
            }
          >
            <DiscoInput
              data-testid={`${testid}.confirmation-text-input`}
              onChange={(e) => setSafeWordInputValue(e.target.value)}
              value={safeWordInputValue}
              name={"title"}
              padding={"10px"}
            />
          </DiscoFormControl>
        )}

        <div className={classes.buttonsWrapper}>
          <DiscoButton
            testid={`${testid}.cancel-button`}
            color={"grey"}
            variant={"outlined"}
            onClick={onClose}
          >{`Cancel`}</DiscoButton>
          <DiscoButton
            color={"error"}
            data-testid={`${testid}.confirm-button`}
            {...confirmButtonProps}
            disabled={disableTypeToConfirm ? false : valid || confirmButtonProps.disabled}
            shouldDisplaySpinner={confirmButtonProps.shouldDisplaySpinner}
          >
            {confirmButtonProps.children}
          </DiscoButton>
        </div>
      </div>
    </Modal>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "480px",
    padding: "24px",
    [theme.breakpoints.down("md")]: {
      padding: "16px",
      marginRight: "12px",
    },
    gap: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.groovyDepths.insideCard,
    borderRadius: theme.measure.borderRadius.xl,
  },
  buttonsWrapper: {
    display: `flex`,
    gap: theme.spacing(1),
    justifyContent: "flex-end",
  },
  warningText: {
    color: theme.palette.text.danger,
    fontWeight: 700,
  },
  header: {
    display: "flex",
    marginTop: theme.spacing(1),
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row-reverse",
    gap: theme.spacing(2),
    width: "100%",
  },
  body: {
    textAlign: "start",
    width: "100%",
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  typeToConfirmText: {
    whiteSpace: "pre-wrap",
  },
}))

export default DiscoConfirmationModal
