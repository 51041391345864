import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"
import { range } from "@utils/array/arrayUtils"
import classNames from "classnames"
import Step from "../../core/ui/iconsax/linear/custom-stepper-shape.svg"

export type DiscoSimpleStepperProps = {
  /** a tuple where the first number represents the current step,
   * and the second number represents total number of steps
   */
  steps: [currentStep: number, totalSteps: number]
  testid?: string
}
export default function DiscoSimpleStepper(props: DiscoSimpleStepperProps) {
  const {
    steps: [step, totalSteps],
    testid = "DiscoSimpleStepper",
  } = props
  const classes = useStyles()
  return (
    <Grid data-testid={testid} container alignItems={"center"} direction={"column"}>
      <DiscoText
        color={"text.secondary"}
        marginBottom={1}
        variant={"body-sm"}
        testid={`${testid}.step-count`}
      >{`Step ${step} of ${totalSteps}`}</DiscoText>
      <div className={classes.stepContainer}>
        {range(totalSteps).map((s) => (
          <Step
            key={`DiscoSimpleStepper.${s}`}
            className={classNames(classes.step, {
              [classes.complete]: s + 1 <= step,
            })}
          />
        ))}
      </div>
    </Grid>
  )
}

const useStyles = makeUseStyles((theme) => ({
  stepContainer: {
    display: "flex",
    gap: theme.spacing(0.5),
    "& > :not(.step)": {
      color: theme.palette.constants.divider,
    },
  },
  complete: {
    "& > path": {
      color: theme.palette.green.main,
    },
  },
  step: {
    width: "24px",
    height: "4px",
  },
}))
