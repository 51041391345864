import NavFolderSettingsButton from "@/apps/add-nav-folder-button/NavFolderSettingsButton"
import ChatChannelSettingsModalButton from "@/apps/list/app/chat/button/ChatChannelSettingsModalButton"
import DeleteChatChannelAppButton from "@/apps/list/app/chat/button/DeleteChatChannelAppButton"
import GenericAppSettingsButton from "@/apps/list/app/common/settings/GenericAppSettingsButton"
import ContentAppSettingsButton from "@/apps/list/app/content/settings-button/ContentAppSettingsButton"
import FeedAppSettingsButton from "@/apps/list/app/feed/settings-button/FeedAppSettingsButton"
import LinkAppSettingsButton from "@/apps/list/app/link/settings-button/LinkAppSettingsButton"
import { useRenderProductAppSettingsButtonsFragment$data } from "@/experience/admin/dropdown/manage-apps/__generated__/useRenderProductAppSettingsButtonsFragment.graphql"
import DeleteNavFolderButton from "@/organization/common/topbar/nav-folder/DeleteNavFolderButton"
import { DiscoIcon } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { Fragment } from "react"
import { graphql } from "relay-runtime"

export default function useRenderProductAppSettingsButtons(testid: string) {
  /* eslint-disable complexity */
  return function renderSettingsButtons(
    productApp: Omit<useRenderProductAppSettingsButtonsFragment$data, " $fragmentType">
  ): React.ReactElement {
    switch (productApp.kind) {
      case "nav_folder": {
        return (
          <>
            <NavFolderSettingsButton appKey={productApp}>
              {(buttonProps) => (
                <DiscoDropdownItem
                  {...buttonProps}
                  key={`${productApp.id}.more-actions.edit`}
                  title={"Edit App"}
                  testid={`${testid}.${productApp.customAppTitle}.more-actions.edit`}
                  icon={<DiscoIcon icon={"pencil"} />}
                />
              )}
            </NavFolderSettingsButton>
            <DeleteNavFolderButton navFolderKey={productApp}>
              {(buttonProps) => (
                <DiscoDropdownItem
                  {...buttonProps}
                  key={`${productApp.id}.more-actions.delete`}
                  title={"Remove App"}
                  testid={`${testid}.${productApp.customAppTitle}.more-actions.remove`}
                  icon={<DiscoIcon icon={"trash"} />}
                />
              )}
            </DeleteNavFolderButton>
          </>
        )
      }
      case "posts": {
        if (!productApp.feed) return <></>
        return (
          <FeedAppSettingsButton appKey={productApp}>
            {(buttonProps) => (
              <DiscoDropdownItem
                {...buttonProps}
                key={`${productApp.id}.more-actions.edit`}
                title={"Edit App"}
                testid={`${testid}.${productApp.feed?.name}.more-actions.edit`}
                icon={<DiscoIcon icon={"pencil"} />}
              />
            )}
          </FeedAppSettingsButton>
        )
      }
      case "events":
      case "curriculum":
      case "collection": {
        return (
          <GenericAppSettingsButton appKey={productApp}>
            {(buttonProps) => (
              <DiscoDropdownItem
                {...buttonProps}
                key={`${productApp.id}.more-actions.edit`}
                title={"Edit App"}
                testid={`${testid}.${productApp.customAppTitle}.more-actions.edit`}
                icon={<DiscoIcon icon={"pencil"} />}
              />
            )}
          </GenericAppSettingsButton>
        )
      }
      case "content": {
        if (!productApp.contentUsage) return <></>
        return (
          <ContentAppSettingsButton appKey={productApp}>
            {(buttonProps) => (
              <DiscoDropdownItem
                {...buttonProps}
                key={`${productApp.id}.more-actions.edit`}
                testid={`${testid}.${productApp.customAppTitle}.more-actions.edit`}
                title={"Edit App"}
                icon={<DiscoIcon icon={"pencil"} />}
              />
            )}
          </ContentAppSettingsButton>
        )
      }
      case "link": {
        return (
          <LinkAppSettingsButton appKey={productApp}>
            {(buttonProps) => (
              <DiscoDropdownItem
                {...buttonProps}
                key={`${productApp.id}.more-actions.edit`}
                testid={`${testid}.${productApp.customAppTitle}.more-actions.edit`}
                title={"Edit App"}
                icon={<DiscoIcon icon={"pencil"} />}
              />
            )}
          </LinkAppSettingsButton>
        )
      }
      case "chat_channel":
        return (
          <>
            <ChatChannelSettingsModalButton appKey={productApp}>
              {(buttonProps) => (
                <DiscoDropdownItem
                  {...buttonProps}
                  key={`${productApp.id}.more-actions.edit`}
                  title={"Edit App"}
                  testid={`${testid}.${productApp.customAppTitle}.more-actions.edit`}
                  icon={<DiscoIcon icon={"pencil"} />}
                />
              )}
            </ChatChannelSettingsModalButton>
            <DeleteChatChannelAppButton appKey={productApp}>
              {(buttonProps) => (
                <DiscoDropdownItem
                  {...buttonProps}
                  key={`${productApp.id}.more-actions.edit`}
                  title={"Remove App"}
                  testid={`${testid}.${productApp.customAppTitle}.more-actions.remove`}
                  icon={<DiscoIcon icon={"trash"} />}
                />
              )}
            </DeleteChatChannelAppButton>
          </>
        )
      default:
        return <Fragment key={productApp.id} />
    }
  }
}

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment useRenderProductAppSettingsButtonsFragment on ProductApp {
    id
    kind
    customAppTitle
    feed {
      id
      name
    }
    contentUsage {
      id
    }
    ...ContentAppSettingsButtonFragment
    ...LinkAppSettingsButtonFragment
    ...FeedAppSettingsButtonFragment
    ...GenericAppSettingsButtonFragment
    ...ChatChannelSettingsModalButtonFragment
    ...ChatChannelSideBarItemFragment
    ...ChatChannelSettingsModalButtonFragment
    ...DeleteChatChannelAppButtonFragment
    ...MembersAppSettingsButtonFragment
    ...NavFolderSettingsButtonFragment
    ...DeleteNavFolderButtonFragment
  }
`
