import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import DiscoDatePicker from "@disco-ui/date/DiscoDatePicker"
import DiscoTimeInput from "@disco-ui/date/DiscoTimeInput"
import DiscoText from "@disco-ui/text/DiscoText"
import { Grid } from "@material-ui/core"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { format } from "date-fns"
import { ReactNode, useCallback } from "react"

type Props = {
  testid?: string
  value: InputValue
  onChange(v: Date | null): void
  minDate?: Date
  maxDate?: Date
  dateSelectTitle?: string | ReactNode
  timeSelectTitle?: string | ReactNode
  variant?: "one-column" | "two-column"
}

type InputValue = Date | string | null | undefined

/** Deprecation Note: Use DiscoDatetimePicker (new design) */
function DiscoDateTimePickerV1(props: Props) {
  const {
    testid,
    value,
    onChange,
    minDate,
    maxDate,
    dateSelectTitle,
    timeSelectTitle,
    variant = "one-column",
  } = props
  const { date, handleDateChange, time, handleTimeChange } = useDateAndTimeState(
    value,
    onChange
  )

  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      <Grid
        className={classes.dateSelectContainer}
        item
        {...(variant === "two-column" ? { xs: 12, md: 6 } : { xs: 12 })}
      >
        {dateSelectTitle &&
          (typeof dateSelectTitle === "string" ? (
            <DiscoText variant={"body-sm"}>{dateSelectTitle}</DiscoText>
          ) : (
            <>{dateSelectTitle}</>
          ))}
        <DiscoDatePicker
          testid={`${testid}.date-select`}
          onChange={(details) => {
            if (details.value) handleDateChange(details.value)
          }}
          value={date}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Grid>

      <Grid
        className={classes.timeSelectContainer}
        item
        {...(variant === "two-column" ? { xs: 12, md: 6 } : { xs: 12 })}
      >
        {timeSelectTitle &&
          (typeof timeSelectTitle === "string" ? (
            <DiscoText variant={"body-sm"}>{timeSelectTitle}</DiscoText>
          ) : (
            <>{timeSelectTitle}</>
          ))}
        <DiscoTimeInput
          testid={`${testid}.time-select`}
          value={time}
          onChange={handleTimeChange}
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

const useStyles = makeUseStyles((theme) => ({
  dateSelectContainer: {
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(1, 2),
    },
  },
  timeSelectContainer: {
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(1, 2),
    },
  },
}))

export default DiscoDateTimePickerV1

const ISO_DATE_FORMAT = DATE_FORMAT.API_FORMAT
const ISO_TIME_FORMAT = DATE_FORMAT.API_TIME_FORMAT

function useDateAndTimeState(
  value: InputValue,
  onChange: (v: Date | null) => void
): {
  date: Date | null
  handleDateChange: (v: Date | null) => void
  time: Date | null
  handleTimeChange: (timeString: string) => void
} {
  const datetime = value == null ? null : new Date(value)
  const date = datetime && format(datetime, ISO_DATE_FORMAT)
  const time = datetime && format(datetime, ISO_TIME_FORMAT)

  const handleDateChange = useCallback(
    (dateValue: Date | null) => {
      if (!dateValue) {
        onChange(null)
        return
      }
      // it's possible that the time value is null when date is set, so rely on that value to set fallback time
      const fallbackTime = format(dateValue, ISO_TIME_FORMAT)
      onChange(new Date(`${format(dateValue, ISO_DATE_FORMAT)}T${time ?? fallbackTime}`))
    },
    [time, onChange]
  )
  const handleTimeChange = useCallback(
    (timeString: string) => {
      onChange(new Date(`${date}T${timeString}`))
    },
    [date, onChange]
  )

  return {
    date: datetime,
    time: datetime,
    handleDateChange,
    handleTimeChange,
  }
}
