import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoChartTooltipContent from "@disco-ui/chart/DiscoChartTooltipContent"
import DiscoText from "@disco-ui/text/DiscoText"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { localPoint } from "@visx/event"
import { Group } from "@visx/group"
import { LegendItem, LegendOrdinal } from "@visx/legend"
import { ScaleSVG } from "@visx/responsive"
import { scaleOrdinal } from "@visx/scale"
import { Pie } from "@visx/shape"
import { Tooltip, useTooltip } from "@visx/tooltip"
import classNames from "classnames"
import React, { useRef } from "react"

const PIE_MAX_WIDTH = 300
// arbitrarily choose a width and height, since the SVG is responsive
const defaultDims = { width: 100, height: 100 }
const defaultMargin = { top: 10, right: 10, bottom: 10, left: 10 }
const pieSortValues = (a: number, b: number) => b - a

type Data = {
  uniqueId: string
  label: string
  frequency: number
  percentage: number
}

export type PieProps<T extends Data> = {
  data: T[]
  margin?: typeof defaultMargin
  showLegend?: boolean
  legendGlyphSize?: number
  sortLegend?: boolean
  /**
   *
   * @param data - data accessible to the tooltip
   * @returns tooltip content
   */
  renderTooltipData?: (data: T) => React.ReactNode
  /**
   * headers for the legend, defaults to ["Options", "Percentage"]
   * determines the number of columns for the legend
   */
  legendHeaders?: string[]
  /**
   * a callback that receives the data for that label
   * @returns nodes for each column in that row
   * */
  formatLegendRow?: (data: T) => React.ReactNode[]
}

function DiscoPieChart<T extends Data>({
  testid = "DiscoPieChart",
  margin = defaultMargin,
  data,
  showLegend = true,
  legendGlyphSize = 20,
  renderTooltipData,
  legendHeaders = ["Options", "Percentage"],
  formatLegendRow,
  sortLegend = true,
}: PieProps<T> & TestIDProps) {
  const ref = useRef<SVGSVGElement | null>(null)
  const { hideTooltip, showTooltip, tooltipLeft, tooltipTop, tooltipData, tooltipOpen } =
    useTooltip<T>()

  const innerWidth = defaultDims.width - margin.left - margin.right
  const innerHeight = defaultDims.height - margin.top - margin.bottom
  const radius = Math.min(innerWidth, innerHeight) / 2
  const centerY = innerHeight / 2
  const centerX = innerWidth / 2
  const top = centerY + margin.top
  const left = centerX + margin.left

  const theme = useTheme()

  const getColorScale = scaleOrdinal({
    domain: data.map((d) => d.uniqueId),
    range: [
      theme.palette.groovy.blue[400],
      theme.palette.groovy.orange[400],
      theme.palette.groovy.purple[400],
      theme.palette.groovy.cyan[400],
      theme.palette.groovy.yellow[400],
      theme.palette.groovy.pink[400],
    ],
  })

  const classes = useStyles({ legendColumnCount: legendHeaders.length, margin })
  if (!data.length) return null

  return (
    <div className={classes.container}>
      <div className={classes.boundingBox}>
        <ScaleSVG innerRef={ref} width={defaultDims.width} height={defaultDims.height}>
          <Group top={top} left={left}>
            <Pie
              data={data}
              pieValue={(d) => d.frequency}
              pieSortValues={pieSortValues}
              outerRadius={radius}
            >
              {(pie) => {
                return pie.arcs.map((arc, i) => {
                  const { uniqueId } = arc.data
                  const arcPath = pie.path(arc)
                  const arcFill = getColorScale(uniqueId)

                  return (
                    <g
                      // eslint-disable-next-line react/no-array-index-key
                      key={`arc-${uniqueId}-${i}`}
                      onMouseOver={(event) => {
                        // get the mouse coordinates relative to the svg the pie arc is rendered within
                        const coords = localPoint(ref.current!, event)
                        if (!coords) return
                        showTooltip({
                          tooltipData: arc.data,
                          tooltipTop: coords.y,
                          tooltipLeft: coords.x,
                        })
                      }}
                      onMouseLeave={() => hideTooltip()}
                    >
                      <path
                        d={arcPath ?? undefined}
                        fill={arcFill}
                        className={classes.path}
                      />
                    </g>
                  )
                })
              }}
            </Pie>
          </Group>
        </ScaleSVG>
        {tooltipOpen && tooltipData && (
          <Tooltip
            // set this to random so it correctly updates with parent bounds
            key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft}
            className={classes.tooltip}
            unstyled
          >
            <DiscoChartTooltipContent>
              {/* render the tooltip with custom formatting, or default to the label label */}
              {renderTooltipData?.(tooltipData) ?? (
                <DiscoText setColor={"currentColor"}>{`${tooltipData.label}`}</DiscoText>
              )}
            </DiscoChartTooltipContent>
          </Tooltip>
        )}
      </div>

      {showLegend && (
        <LegendOrdinal scale={getColorScale}>
          {(rows) => {
            if (sortLegend)
              rows.sort((a, b) => data[b.index].percentage - data[a.index].percentage)
            return (
              <div data-testid={`${testid}.legend`} className={classes.legend}>
                {legendHeaders.map((header) => (
                  <LegendItem
                    key={header}
                    className={classNames(classes.legendItem, classes.legendHeaderItem)}
                  >
                    <DiscoText variant={"body-sm-500"}>{header}</DiscoText>
                  </LegendItem>
                ))}
                {rows.map((row, i) => (
                  <React.Fragment
                    // eslint-disable-next-line react/no-array-index-key
                    key={`legend-quantile-${i}`}
                  >
                    {/**
                     * render custom rows, or default to the label and percentage,
                     * to match default headers
                     */}
                    {(
                      formatLegendRow?.(data[row.index]) ?? [
                        data[row.index].label,
                        `${data[row.index].percentage}%`,
                      ]
                    ).map((column, j) => (
                      <LegendItem
                        // eslint-disable-next-line react/no-array-index-key
                        key={`legend-quantile-${i}-${j}`}
                        data-testid={`${testid}.legend-row-${i}.column-${j}`}
                        className={classes.legendItem}
                      >
                        {j === 0 && (
                          <svg
                            width={legendGlyphSize}
                            height={legendGlyphSize}
                            className={classes.glyph}
                          >
                            <rect
                              fill={row.value}
                              width={legendGlyphSize}
                              height={legendGlyphSize}
                              rx={4}
                              ry={4}
                            />
                          </svg>
                        )}
                        {typeof column === "string" ? (
                          <DiscoText
                            data-testid={`${testid}.legend-row-${i}.column-${j}.text`}
                            variant={"body-sm"}
                          >
                            {column}
                          </DiscoText>
                        ) : (
                          column
                        )}
                      </LegendItem>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            )
          }}
        </LegendOrdinal>
      )}
    </div>
  )
}

type StyleProps = {
  legendColumnCount: number
  margin: typeof defaultMargin
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-around",
  },
  // bounding box for the pie chart/tooltip
  // it should always have the same dims as the child svg, since tooltip positioning logic relies on this
  boundingBox: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    flex: `0 1 ${PIE_MAX_WIDTH}px`,
  },
  tooltip: {
    position: "absolute",
    color: theme.palette.common.white,
    minWidth: 100,
  },
  path: {
    transition: "all 0.3s ease-out",
    opacity: 0.85,
    "&:hover": {
      transform: "scale(1.1)",
      opacity: 1,
    },
  },
  legend: {
    flex: "0 1 auto",
    display: "grid",
    gridTemplateColumns: ({ legendColumnCount }: StyleProps) =>
      `repeat(${legendColumnCount}, auto)`,
  },
  legendHeaderItem: ({ legendColumnCount }: StyleProps) => ({
    backgroundColor: theme.palette.groovy.neutral[100],

    "&:first-of-type": {
      borderTopLeftRadius: theme.measure.borderRadius.default,
      borderBottomLeftRadius: theme.measure.borderRadius.default,
    },
    [`&:nth-of-type(${legendColumnCount})`]: {
      padding: theme.spacing(1),
      borderTopRightRadius: theme.measure.borderRadius.default,
      borderBottomRightRadius: theme.measure.borderRadius.default,
    },
  }),
  legendItem: {
    padding: theme.spacing(1, 4, 1, 1),
  },
  glyph: {
    marginRight: theme.spacing(1),
    flex: "0 0 auto",
  },
}))

export default DiscoPieChart
