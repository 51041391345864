import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import TimezoneDropdown from "@components/dropdown/timezone/TimezoneDropdown"
import {
  DiscoButton,
  DiscoDivider,
  DiscoFormControl,
  DiscoInput,
  DiscoText,
} from "@disco-ui"
import DiscoCalendar from "@disco-ui/date/DiscoCalendar"
import DiscoTimeInput from "@disco-ui/date/DiscoTimeInput"
import { Popover } from "@material-ui/core"
import DateUtils from "@utils/date/dateUtils"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz"
import React, {
  ChangeEvent,
  FC,
  MouseEventHandler,
  createElement,
  useEffect,
  useState,
} from "react"

interface DiscoDatetimePickerProps extends TestIDProps {
  children: DiscoDatetimePickerChildren
  onSave: (newDate: Date) => void
  minDate?: Date
  initialDate?: Date
  hideTimezone?: boolean
  timeLabel?: string
}

type DiscoDatetimePickerChildren = FC<{
  onClick: MouseEventHandler
  selectedDatetime: Date | null
  selectedTimezone: string
  setSelectedDatetime: (date: Date | null) => void
  setSelectedTimezone: React.Dispatch<React.SetStateAction<string>>
}>

function DiscoDatetimePicker({
  testid,
  children,
  onSave,
  minDate,
  initialDate,
  hideTimezone = false,
  timeLabel,
}: DiscoDatetimePickerProps) {
  const classes = useStyles()
  const userTimezone = useUserTimezone()

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [initialTimezone, setInitialTimezone] = useState(userTimezone)
  const [selectedDatetime, setSelectedDatetime] = useState<Date | null>(
    initialDate ? utcToZonedTime(initialDate, initialTimezone) : null
  )
  const [selectedDatetimeHasError, setSelectedDatetimeHasError] = useState(false)
  const [dateText, setDateText] = useState(DateUtils.formatDateInput(selectedDatetime))
  const [selectedTimezone, setSelectedTimezone] = useState(initialTimezone)
  const [calendarMonth, setCalendarMonth] = useState(initialDate)

  // Expose selectedDatetime externally adjusted for the selected timezone
  const [externalDatetime, setExternalDatetime] = useState<Date | null>(
    initialDate || null
  )

  useEffect(() => {
    const newDateText = DateUtils.formatDateInput(selectedDatetime)
    if (newDateText) {
      setDateText(DateUtils.formatDateInput(selectedDatetime))
      setSelectedDatetimeHasError(false)
    } else {
      setSelectedDatetimeHasError(true)
    }

    setExternalDatetime(
      selectedDatetime ? zonedTimeToUtc(selectedDatetime, selectedTimezone) : null
    )
  }, [selectedDatetime, selectedTimezone])

  const selectedTimezoneSubtitle = formatDateWithOptions({
    timeZone: selectedTimezone,
    format: DATE_FORMAT.UTC_OFFSET,
  })(new Date())

  return (
    <>
      {createElement(children, {
        onClick: handleOpen,
        selectedDatetime: externalDatetime,
        selectedTimezone,
        setSelectedDatetime: handleExternalSetSelectedDatetime,
        setSelectedTimezone,
      })}
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCancel}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        data-testid={`${testid}.date-select.Popover`}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{ classes: { root: classes.popoverPaper } }}
      >
        <DiscoFormControl marginBottom={1.5}>
          <DiscoInput
            value={dateText}
            placeholder={"Select a date"}
            onChange={handleDateTextChange}
            onBlur={handleDateTextBlur}
            className={classes.dateTextInput}
            error={selectedDatetimeHasError}
          />
        </DiscoFormControl>
        <DiscoCalendar
          mode={"single"}
          selected={selectedDatetime || undefined}
          onSelect={handleSelectDate}
          fromDate={minDate}
          month={calendarMonth || undefined}
          onMonthChange={setCalendarMonth}
        />

        <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={0.5}>
          {timeLabel || "Time"}
        </DiscoText>
        <DiscoFormControl marginBottom={0}>
          <DiscoTimeInput
            testid={`${testid}.time-select`}
            value={selectedDatetime}
            onChange={handleSelectTime}
            fullWidth
          />
        </DiscoFormControl>

        {!hideTimezone && (
          <>
            <DiscoText
              variant={"body-sm"}
              color={"text.secondary"}
              marginTop={1.5}
              marginBottom={0.5}
            >
              {"Timezone"}
            </DiscoText>
            <TimezoneDropdown
              selectedOption={{
                value: selectedTimezone,
                title: selectedTimezone,
                context: {
                  subtitle: selectedTimezoneSubtitle,
                },
              }}
              onSelect={(t) => handleSelectTimezone(t!.value)}
              testid={`${testid}.timezone-dropdown`}
            />
          </>
        )}

        <DiscoDivider marginTop={1.5} marginBottom={1.5} />
        <div className={classes.buttonsContainer}>
          <DiscoButton onClick={handleCancel} color={"grey"} variant={"outlined"}>
            {"Cancel"}
          </DiscoButton>
          <DiscoButton
            onClick={handleSave}
            disabled={!selectedDatetime}
            data-testid={`${testid}.save-button`}
          >
            {"Save"}
          </DiscoButton>
        </div>
      </Popover>
    </>
  )

  function handleSave() {
    setAnchorEl(null)
    if (!externalDatetime) return

    setInitialTimezone(selectedTimezone)
    onSave(externalDatetime)
  }

  function handleSelectDate(newDatetime: Date | undefined) {
    if (!newDatetime) return
    const newDate = newDatetime.getDate()
    const newMonth = newDatetime.getMonth()
    const newYear = newDatetime.getFullYear()

    const newSelectedDatetime = selectedDatetime
      ? new Date(selectedDatetime.toISOString())
      : new Date()
    newSelectedDatetime.setFullYear(newYear, newMonth, newDate)

    // Switch to string then date so it triggers a re-render
    setSelectedDatetime(new Date(newSelectedDatetime.toISOString()))
  }

  /**
   * @param newTimeString a string in the format "XX:XX:00" (hours/minutes/seconds)
   */
  function handleSelectTime(newTimeString: string) {
    const [hours, minutes, seconds] = newTimeString.split(":").map((unit) => Number(unit))
    const newSelectedDatetime = selectedDatetime
      ? new Date(selectedDatetime.toISOString())
      : new Date()
    newSelectedDatetime.setHours(hours, minutes, seconds)

    // Switch to string then date so it triggers a re-render
    setSelectedDatetime(new Date(newSelectedDatetime.toISOString()))
  }

  function handleSelectTimezone(newTimezone: string) {
    setSelectedTimezone(newTimezone)
  }

  function handleOpen(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setAnchorEl(e.currentTarget)
  }

  function handleCancel() {
    setSelectedTimezone(initialTimezone)
    setAnchorEl(null)
  }

  function handleDateTextChange(e: ChangeEvent<HTMLInputElement>) {
    setDateText(e.target.value)
  }

  function handleDateTextBlur() {
    const date = DateUtils.parseDate(dateText, minDate)
    if (date) setCalendarMonth(date)
    setSelectedDatetime(DateUtils.parseDate(dateText, minDate))
  }

  function handleExternalSetSelectedDatetime(date: Date | null) {
    setSelectedDatetime(date ? utcToZonedTime(date, selectedTimezone) : null)
  }
}

const useStyles = makeUseStyles((theme) => ({
  checkbox: {
    marginBottom: theme.spacing(1),
  },

  input: {
    cursor: "pointer",
  },
  inputRoot: {
    cursor: "pointer",
  },
  popoverPaper: {
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(2.5),
    boxShadow: theme.palette.groovyDepths.boxShadow,
    margin: 0,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
  },
  dateTextInput: {
    "& input": {
      ...theme.typography["body-sm"],
    },
  },
}))

export default DiscoDatetimePicker
