import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoSpinner } from "@disco-ui"
import { ButtonBase, ButtonBaseProps } from "@material-ui/core"
import classNames from "classnames"
import { LocationDescriptorObject } from "history"
import React from "react"

export interface DiscoCardButtonProps extends ButtonBaseProps {
  children: React.ReactNode
  shouldDisplaySpinner?: boolean
  href?: string
  to?: string | LocationDescriptorObject<unknown>
  target?: string
  rel?: string
  className?: string
}

function DiscoCardButton({
  children,
  shouldDisplaySpinner,
  className,
  ...props
}: DiscoCardButtonProps) {
  const classes = useStyles()

  if (shouldDisplaySpinner) return <DiscoSpinner size={"sm"} />
  return (
    <ButtonBase classes={{ root: classNames(classes.root, className) }} {...props}>
      {children}
    </ButtonBase>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    borderRadius: theme.measure.borderRadius.big,
    border: `1px solid ${theme.palette.constants.stroke}`,
    transition: "background-color 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.groovy.neutral[100],
    },
  },
}))

export default DiscoCardButton
