import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import CaretIcon from "@/core/ui/iconsax/bold/arrow-down.svg"
import ExternalIcon from "@/core/ui/iconsax/linear/export-2.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { handleLocalStorageError } from "@/onboarding/utils/hooks/useLocalStorageState"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { DiscoLink, DiscoText } from "@disco-ui"
import DiscoIcon, { DiscoIconKinds } from "@disco-ui/icon/DiscoIcon"
import DiscoTooltip, { DiscoTooltipProps } from "@disco-ui/tooltip/DiscoTooltip"
import { Collapse, MenuItem, MenuItemProps, MenuList } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import classnames from "classnames"
import { LocationDescriptorObject } from "history"
import React, { useEffect, useState } from "react"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import { useLocation } from "react-router-dom"

export interface DiscoSideBarItemProps
  extends Omit<MenuItemProps, "button" | "classes" | "selected"> {
  to?: string | LocationDescriptorObject
  showItemsRoute?: string | LocationDescriptorObject
  externalLink?: string
  leftIcon?: DiscoIconKinds | React.ReactNode
  rightContent?: React.ReactNode
  /** Right side content that is only shown when the item is collapsed */
  showOnCollapsedRightContent?: React.ReactNode
  showOnHoverRightContent?: React.ReactNode
  selected?: MenuItemProps["selected"]
  menuItemClasses?: MenuItemProps["classes"]
  isAction?: boolean
  classes?: {
    content?: string
    leftIconContainer?: string
    externalLinkIcon?: string
    nameContainer?: string
  }
  testid?: string
  name: string
  nameNode?: React.ReactNode
  items?: DiscoSideBarItemProps[]
  collapsible?: boolean
  defaultCollapsed?: boolean
  /** Should the inner items collapse when the side bar item is selected, defaults to false */
  collapseWhenSelected?: boolean
  hideExternalLinkIcon?: boolean
  tooltip?: React.ReactNode
  tooltipProps?: Omit<DiscoTooltipProps, "content" | "disabled">
  onClick?: (
    e: React.MouseEvent<HTMLDivElement | HTMLLIElement | HTMLButtonElement, MouseEvent>
  ) => void
  dragHandleProps?: DraggableProvidedDragHandleProps
  isDragging?: boolean
  indentNestedItems?: boolean
  forceExpand?: boolean
  ignoreLocalStorage?: boolean
}

/* eslint-disable complexity */
function DiscoSideBarItem({
  to,
  showItemsRoute = "",
  externalLink,
  leftIcon: propsLeftIcon,
  rightContent,
  showOnCollapsedRightContent,
  showOnHoverRightContent,
  menuItemClasses,
  testid,
  name,
  nameNode,
  isAction,
  classes: propsClasses,
  items = [],
  collapsible,
  defaultCollapsed = false,
  collapseWhenSelected = false,
  hideExternalLinkIcon = false,
  tooltip,
  tooltipProps,
  onClick,
  children,
  dragHandleProps,
  isDragging = false,
  indentNestedItems = false,
  forceExpand = false,
  ignoreLocalStorage = false,
  ...props
}: DiscoSideBarItemProps) {
  // Default assume selected when current path matches link.
  // Simplifies basic checks for selected routes.
  const location = useLocation()
  const selected =
    props.selected === undefined
      ? to
        ? location.pathname === getRouteAsString(to)
        : false
      : props.selected
  const hasInnerItems = items.length > 0 || Boolean(children)

  const activeOrganization = useActiveOrganization()
  // Persist collapsed state in local storage
  const scopedKey = `DiscoSideBarItem-${
    activeOrganization?.viewerMembership?.id || "anonymous"
  }-${name}-collapsed-state`
  const [showInnerItems, setShowInnerItems] = useState(() => {
    if (forceExpand) return true
    // If collapsible, try to get the collapsed state from local storage
    if (collapsible) {
      if (ignoreLocalStorage) return true
      try {
        const storedState = localStorage.getItem(scopedKey)
        if (storedState) {
          return JSON.parse(storedState) as boolean
        }
        // collapsible items are expanded by default
        return !defaultCollapsed
      } catch (e) {
        // ignore
      }
    }
    // default to false otherwise
    return false
  })

  useEffect(() => {
    /**
     *  - If forcing expand, always show inner items
     *  - If the item is not selected, but the current path matches the showItemsRoute,
     *     then show the inner items (if there are any)
     *  - If the item is selected, always show the inner items
     *  - Otherwise, hide the inner items if the item is not collapsible
     */
    if (forceExpand) {
      setShowInnerItems(true)
    } else if (
      !!showItemsRoute &&
      location.pathname.startsWith(getRouteAsString(`${showItemsRoute}/`)) &&
      hasInnerItems
    ) {
      setShowInnerItems(true)
    } else if (selected) {
      setShowInnerItems(hasInnerItems ? !collapseWhenSelected : false)
    } else if (!collapsible) {
      setShowInnerItems(false)
    }
  }, [
    location.pathname,
    selected,
    showItemsRoute,
    hasInnerItems,
    collapseWhenSelected,
    collapsible,
    forceExpand,
  ])

  const classes = useStyles({
    selected: selected || showInnerItems,
    items,
    isAction: Boolean(isAction),
    isDragging,
    hasLeftIcon: Boolean(propsLeftIcon),
    indentNestedItems,
    isCollapsed: Boolean(collapsible && !showInnerItems),
  })

  const showOnHoverClasses = useShowOnHoverStyles({
    hideWithDisplay: { stylesWhenShown: { display: "flex" } },
  })

  const leftIcon = collapsible ? (
    <>
      <CaretIcon
        className={classnames(classes.collapsibleCaret, { isOpen: showInnerItems })}
        data-testid={`${testid}.caret-icon.${showInnerItems ? "open" : "closed"}`}
      />
      {propsLeftIcon}
    </>
  ) : (
    propsLeftIcon
  )

  let content = (
    <div className={classnames(classes.content, propsClasses?.content)}>
      {leftIcon && (
        <div
          className={classnames(
            classes.leftIconContainer,
            propsClasses?.leftIconContainer
          )}
        >
          {renderLeftIcon()}
          {externalLink && !hideExternalLinkIcon && (
            <ExternalIcon
              className={classnames(
                classes.externalLinkIcon,
                propsClasses?.externalLinkIcon,
                showOnHoverClasses.showable
              )}
            />
          )}
        </div>
      )}
      <div className={classnames(classes.name, propsClasses?.nameContainer)}>
        {nameNode || (
          <DiscoText
            testid={`${testid}-name`}
            variant={"body-sm-500"}
            truncateText={1}
            className={classes.nameText}
          >
            {name}
          </DiscoText>
        )}
      </div>
      {(rightContent || showOnCollapsedRightContent || showOnHoverRightContent) && (
        <>
          {/* Extra class is used to increase specificity from other files */}
          <div
            className={classnames(
              classes.rightContent,
              "DiscoSideBarItem__right-content",
              showOnHoverRightContent && showOnHoverClasses.hidable
            )}
          >
            {rightContent}
            {!showInnerItems && showOnCollapsedRightContent}
          </div>
          {showOnHoverRightContent && (
            <div
              className={classnames(
                classes.rightContent,
                "DiscoSideBarItem__right-content",
                showOnHoverClasses.showable
              )}
            >
              {showOnHoverRightContent}
            </div>
          )}
        </>
      )}
    </div>
  )

  if (to) {
    content = (
      <DiscoLink to={to} data-testid={`${testid}.link`}>
        {content}
      </DiscoLink>
    )
  }

  if (externalLink) {
    content = (
      <a
        href={externalLink}
        data-testid={`${testid}.link`}
        target={"_blank"}
        rel={"noreferrer noopener"}
      >
        {content}
      </a>
    )
  }

  return (
    <>
      <MenuItem
        classes={{
          ...menuItemClasses,
          root: classnames(
            classes.discoSideBarItem,
            menuItemClasses?.root,
            showOnHoverClasses.hoverable,
            classes.svgFillAndStroke
          ),
        }}
        disableGutters
        data-testid={testid}
        onClick={(e: React.MouseEvent<HTMLLIElement>) => {
          handleToggleItems(e)
          onClick?.(e)
        }}
        {...props}
        selected={selected}
        {...dragHandleProps}
      >
        <DiscoTooltip
          disabled={!tooltip}
          content={tooltip}
          placement={"right"}
          {...tooltipProps}
        >
          {content}
        </DiscoTooltip>
      </MenuItem>
      {/** Disable animation */}
      <Collapse
        in={showInnerItems}
        timeout={0}
        className={classes.collapse}
        mountOnEnter
        unmountOnExit
      >
        {hasInnerItems && !isDragging && (
          <MenuItem className={classnames(classes.nestedItemsList, "additional-items")}>
            <MenuList>
              {/* Inner items can be provided as children or as an array of props */}
              {children ||
                items.map((item) => (
                  <DiscoSideBarItem
                    key={item.name}
                    testid={`${testid}.${item.name}`}
                    {...item}
                  />
                ))}
            </MenuList>
          </MenuItem>
        )}
      </Collapse>
    </>
  )

  function renderLeftIcon() {
    return typeof leftIcon === "string" ? (
      <DiscoIcon icon={leftIcon as DiscoIconKinds} active={selected} />
    ) : (
      leftIcon
    )
  }

  function handleToggleItems(e: React.MouseEvent<HTMLLIElement>) {
    e.stopPropagation()

    if (collapsible) {
      setShowInnerItems((v) => {
        if (!ignoreLocalStorage) {
          try {
            localStorage.setItem(scopedKey, v ? "false" : "true")
          } catch (err) {
            handleLocalStorageError(err)
          }
        }
        return !v
      })
    }
  }

  function getRouteAsString(route: string | LocationDescriptorObject<unknown>) {
    if (typeof route === "string") {
      return route
    } else if (route.pathname) {
      return route.pathname
    }
    return ""
  }
}
interface StyleProps {
  selected?: boolean
  items: DiscoSideBarItemProps[]
  isAction: boolean
  isDragging: boolean
  hasLeftIcon: boolean
  indentNestedItems: boolean
  isCollapsed: boolean
}

const useStyles = makeUseStyles((theme) => ({
  discoSideBarItem: (props: StyleProps) => ({
    position: "relative",
    borderRadius: theme.measure.borderRadius.medium,
    "&, &.Mui-focusVisible": {
      backgroundColor: theme.palette.background.paper,
    },
    padding: 0,
    width: "100%",
    margin: 0,
    ...styleIf(props.isDragging, {
      boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    }),
    "& > a": {
      width: "100%",
    },
    "& p": {
      color:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[200]
          : theme.palette.groovy.neutral[500],
      ...styleIf(props.isAction, {
        color:
          theme.palette.type === "dark"
            ? theme.palette.groovy.onDark[300]
            : theme.palette.groovy.neutral[300],
      }),
    },
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.neutral[200],
      "& p": {
        color: theme.palette.groovy.neutral[700],
      },
    },
    "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected.Mui-focusVisible": {
      backgroundColor: theme.palette.primary.main,
      "& p": {
        color: theme.palette.primary.contrastText,
      },
    },
  }),
  svgFillAndStroke: (props: StyleProps) => ({
    "& svg": {
      color:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[200]
          : theme.palette.groovy.neutral[500],
      ...styleIf(props.isAction, {
        color:
          theme.palette.type === "dark"
            ? theme.palette.groovy.onDark[300]
            : theme.palette.groovy.neutral[300],
      }),
    },
    "&:hover": {
      "& svg": {
        color: theme.palette.groovy.neutral[700],
      },
    },
    "&.Mui-selected": {
      "& svg": {
        color: theme.palette.primary.contrastText,
      },
      "& $rightContent": {
        "& svg": {
          color: theme.palette.primary.contrastText,
          "&:hover": {
            color: theme.palette.primary.contrastText,
          },
        },
      },
    },
    "&.Mui-selected:hover": {
      "& svg": {
        color: theme.palette.primary.contrastText,
      },
    },
  }),
  content: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr max-content",
    alignItems: "center",
    alignContent: "center",
    height: "36px",
    padding: theme.spacing(0.5, 1),
    width: "100%",
    "& > p": {
      lineHeight: "20px",
    },
  },
  externalLinkIcon: {
    position: "absolute",
    top: 0,
    left: 0,
    background: theme.palette.background.paper,
    width: "24px",
    height: "24px",
    padding: "1px",
    borderRadius: theme.measure.borderRadius.default,
  },
  leftIconContainer: ({ hasLeftIcon }: StyleProps) => ({
    position: "relative",
    display: "flex",
    width: "auto",
    minWidth: 24,
    height: 24,
    justifyContent: "center",
    alignItems: "center",
    marginRight: hasLeftIcon ? theme.spacing(1) : 0,
  }),
  collapsibleCaret: {
    width: 16,
    height: 16,
    transform: "rotate(270deg)",
    cursor: "pointer",
    "&.isOpen": {
      transform: "rotate(0deg)",
    },
    "$leftIconContainer &": {
      color: theme.palette.groovy.neutral[400],
    },
  },
  rightContent: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(0.75),
    flexGrow: 1,
    "& svg": {
      color: `${theme.palette.groovy.neutral[300]}`,
      "&:hover": {
        color: theme.palette.groovy.neutral[700],
      },
    },
  },
  nestedItemsList: ({ hasLeftIcon, indentNestedItems, isCollapsed }: StyleProps) => ({
    padding: 0,
    paddingLeft: indentNestedItems ? 22 : 0,
    width: "100%",
    background: "none !important",
    "& ul": {
      width: "100%",
      padding: 0,
    },
    // LHS bar next to nested list; Don't show when no icon in parent (i.e. nav sections)
    ...styleIf(hasLeftIcon || indentNestedItems, {
      "&::before": {
        content: '""',
        height: "100%",
        width: 2,
        backgroundColor: theme.palette.groovy.grey[100],
        borderRadius: "2px",
        position: "absolute",
        left: 18,
      },
    }),
    ...styleIf(isCollapsed, {
      display: "none",
    }),
  }),
  name: {
    display: "grid",
    alignItems: "center",
  },
  nameText: {
    color: theme.palette.common.white,
  },
  collapse: {
    width: "100%",
  },
}))

export const DiscoSideBarItemSkeleton: React.FC = () => {
  const classes = useSkeletonStyles()
  return <Skeleton className={classes.skeleton} width={"100%"} height={"36px"} />
}

const useSkeletonStyles = makeUseStyles((theme) => ({
  skeleton: {
    transform: "none",
    borderRadius: theme.measure.borderRadius.medium,
  },
}))

export default DiscoSideBarItem
