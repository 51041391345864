import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoIconButton from "@disco-ui/button/DiscoIconButton"
import DiscoIcon from "@disco-ui/icon/DiscoIcon"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"

interface DiscoExpandButtonProps extends TestIDProps {
  isOpen: boolean
  onClick: VoidFunction
  size?: "small" | "medium"
}

function DiscoExpandButton({
  testid = "DiscoExpandButton",
  isOpen,
  onClick,
  size = "medium",
}: DiscoExpandButtonProps) {
  const classes = useStyles({ isExpanded: isOpen })
  const theme = useTheme()
  return (
    <>
      <DiscoIconButton
        onClick={onClick}
        testid={testid}
        variant={"outlined"}
        className={classes.iconButton}
        color={theme.palette.groovy.neutral[500]}
        size={size}
      >
        <DiscoIcon className={classes.chevronIcon} icon={"chevron"} />
      </DiscoIconButton>
    </>
  )
}

type StyleProps = {
  isExpanded: boolean
}

const useStyles = makeUseStyles({
  iconButton: {
    padding: 0,
    height: "40px",
    width: "40px",
  },
  chevronIcon: ({ isExpanded }: StyleProps) => ({
    transition: "transform 300ms ease-in-out",
    transform: `rotate(${isExpanded ? 180 : 90}deg)`,
  }),
})

export default DiscoExpandButton
