import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { IconButton } from "@material-ui/core"
import React, { useState } from "react"
import HidePassword from "../../core/ui/iconsax/linear/eye-slash.svg"
import ShowIcon from "../../core/ui/iconsax/linear/eye.svg"
import DiscoInput, { DiscoInputProps } from "./DiscoInput"

interface PasswordInputProps extends DiscoInputProps {
  value: string
  ref?: React.Ref<HTMLInputElement | HTMLTextAreaElement>
}

interface VisibilityButtonProps {
  value: boolean
}

const DiscoPasswordInput = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  PasswordInputProps
>((props, ref) => {
  const [isPasswordShown, setPasswordVisibility] = useState(false)
  const togglePasswordVisibility = () => {
    setPasswordVisibility(!isPasswordShown)
  }
  const visibilityClasses = useVisibilityButtonStyles({ value: Boolean(props.value) })

  let iconAriaLabel = "Show password"
  let icon = <ShowIcon width={24} height={24} />
  let inputType: "password" | "text" = "password"

  if (isPasswordShown) {
    iconAriaLabel = "Hide password"
    icon = <HidePassword width={24} height={24} />
    inputType = "text"
  }

  return (
    <DiscoInput
      ref={ref}
      {...props}
      type={inputType}
      endAdornment={
        <IconButton
          data-testid={"PasswordInput.icon-button"}
          aria-label={iconAriaLabel}
          onClick={togglePasswordVisibility}
          tabIndex={-1}
          classes={visibilityClasses}
        >
          {icon}
        </IconButton>
      }
    />
  )
})

const useVisibilityButtonStyles = makeUseStyles({
  root: {
    backgroundColor: "transparent",
    display: (props: VisibilityButtonProps) => (props.value ? "inline-block" : "none"),
    border: "none",
    padding: 0,
    position: "absolute",
    right: "10px",
  },
})

export default DiscoPasswordInput
