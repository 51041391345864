import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import DiscoIconButton from "@disco-ui/button/DiscoIconButton"
import DiscoIcon from "@disco-ui/icon/DiscoIcon"
import DiscoInput, { DiscoInputProps } from "@disco-ui/input/DiscoInput"
import { Popover, lighten } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import React, { useRef, useState } from "react"
import { ChromePicker, ColorResult } from "react-color"

type Props = Omit<DiscoInputProps, "onChange" | "ref" | "endAdornment"> &
  TestIDProps & {
    value: string | null | undefined
    hideText?: boolean
    onChange(v: string): void
    presets?: string[]
  }

const DiscoColorPicker: React.FC<Props> = (props) => {
  const { value, onChange, disabled, hideText = false, testid, presets, ...rest } = props

  const [showPicker, setShowPicker] = useState(false)
  const inputRef = useRef<HTMLElement | null>(null)
  const classes = useStyles()

  const handleColorChange = (data: ColorResult) => {
    const alphaHex = data.rgb.a
      ? Math.round(data.rgb.a * 255)
          .toString(16)
          .padStart(2, "0")
      : "00"
    const hexWithAlpha = `${data.hex}${alphaHex}`
    onChange(hexWithAlpha)
  }

  return (
    <>
      <DiscoInput
        ref={(e) => (inputRef.current = e)}
        type={hideText ? "hidden" : ""}
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        endAdornment={
          <button
            className={classes.colorBadge}
            style={{ background: value || undefined }}
            onClick={handleOnClick}
          />
        }
        disabled={disabled}
        data-testid={testid}
        {...rest}
      />
      {showPicker && (
        <Popover
          open={showPicker}
          onClose={() => setShowPicker(false)}
          anchorEl={inputRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          classes={{ paper: classes.paper }}
        >
          <ChromePicker color={value || undefined} onChange={handleColorChange} />
        </Popover>
      )}
      {presets?.length && (
        <div className={classes.presetsContainer}>
          {presets.map((preset, i) => (
            <DiscoIconButton
              key={preset}
              testid={`${testid}.preset.${i}`}
              width={32}
              height={32}
              backgroundColor={preset}
              hoverBackgroundColor={lighten(preset, 0.2)}
              classes={{ root: classes.presetButton }}
              onClick={() => onChange(preset)}
            />
          ))}
          <DiscoIconButton
            width={32}
            height={32}
            variant={"outlined"}
            borderStyle={"dashed"}
            classes={{ root: classes.presetButton }}
            onClick={handleOnClick}
          >
            <DiscoIcon icon={"add"} />
          </DiscoIconButton>
        </div>
      )}
    </>
  )

  function handleOnClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    if (disabled) return
    setShowPicker(true)
  }
}

export default DiscoColorPicker

const useStyles = makeUseStyles((theme) => ({
  colorBadge: {
    // Square badge
    height: "24px",
    width: "24px",
    flexShrink: 0,
    // Rounded border with outline for when color = background color
    borderRadius: theme.measure.borderRadius.default,
    border: `1px solid white`,
    outline: "none",
    cursor: "pointer",
  },
  paper: {
    padding: 0,
  },
  presetsContainer: {
    display: "flex",
    gap: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    flexWrap: "wrap",
  },
  presetButton: {
    borderRadius: 6,
    "& svg:not(:hover)": {
      color: theme.palette.text.secondary,
    },
  },
}))
