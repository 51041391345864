import Badge from "@/admin/experiences/badges/Badge"
import ToggleAppStatusButton from "@/apps/actions/ToggleAppStatusButton"
import RemoveAppButton from "@/apps/remove-app-button/RemoveAppButton"
import {
  APP_KIND_LABELS,
  APP_REMOVABLE_KINDS,
  CAN_DISABLE_APP_KINDS,
} from "@/apps/util/appConstants"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { useLabels } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { ProductAppListItemChildFragment$key } from "@/experience/admin/dropdown/manage-apps/__generated__/ProductAppListItemChildFragment.graphql"
import useRenderProductAppSettingsButtons from "@/experience/admin/dropdown/manage-apps/useRenderProductAppSettingsButtons"
import generateCoursePaths from "@/organization/common/sidebar/my-experiences-list/util/generateCoursePaths"
import { DiscoIcon, DiscoSideBarItem, DiscoSideBarItemProps } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import usePermissions from "@utils/hook/usePermissions"
import { graphql, useFragment } from "react-relay"
import { generatePath } from "react-router-dom"

export type ProductAppListItemChildProps = Omit<
  DiscoSideBarItemProps,
  "name" | "settingsButton"
> & {
  appKey: ProductAppListItemChildFragment$key
  hideOverFlow?: boolean
  disableNavigation?: boolean
}

export default function ProductAppListItemChild({
  appKey,
  testid = "ProductAppListItemChild",
  hideOverFlow = false,
  disableNavigation = false,
  ...rest
}: ProductAppListItemChildProps) {
  const app = useFragment<ProductAppListItemChildFragment$key>(
    graphql`
      fragment ProductAppListItemChildFragment on ProductApp {
        id
        kind
        status
        customAppTitle
        customAppUrl
        badge {
          ...BadgeFragment
        }
        product {
          slug
          ...usePermissionsFragment
        }
        ...RemoveAppButtonFragment
        ...ToggleAppStatusButtonFragment
        feed {
          id
          name
        }
        collection {
          id
        }
        contentUsage {
          id
        }
        chatChannel {
          id
        }
        ...useRenderProductAppSettingsButtonsFragment @relay(mask: false)
      }
    `,
    appKey
  )
  const contentUsageDrawer = useContentUsageDrawer()
  const isMobile = useIsMobile()
  const labels = useLabels()
  const permissions = usePermissions(app.product)
  const canManage = permissions.has("apps.manage")
  const removable = canManage && APP_REMOVABLE_KINDS.has(app.kind)
  const canDisable = canManage && CAN_DISABLE_APP_KINDS.has(app.kind)
  const renderSettingsButtons = useRenderProductAppSettingsButtons(testid)
  const settingsButtons = renderSettingsButtons(app)
  const showOverflowItems =
    !hideOverFlow && canManage && (removable || canDisable || settingsButtons)

  const isActive = app.status === "active"

  return (
    <DiscoSideBarItem
      {...rest}
      leftIcon={<Badge badgeKey={app.badge} />}
      name={getName()}
      to={disableNavigation ? "" : getRoute()}
      externalLink={app.kind === "link" ? app.customAppUrl || "" : undefined}
      onClick={handleOpenContentDrawer}
      rightContent={
        showOverflowItems && (
          <DiscoMoreActionsDropdown
            testid={`${testid}.more-actions`}
            menuButtonProps={{ size: "small" }}
          >
            {settingsButtons}
            {canDisable && (
              <ToggleAppStatusButton appKey={app}>
                {(buttonProps) => (
                  <DiscoDropdownItem
                    {...buttonProps}
                    testid={`${testid}.more-actions.toggle-status`}
                    title={`${isActive ? "Hide" : "Unhide"} for ${
                      labels.admin_member.plural
                    }`}
                  />
                )}
              </ToggleAppStatusButton>
            )}
            {removable && (
              <RemoveAppButton appKey={app}>
                {(buttonProps) => (
                  <DiscoDropdownItem
                    {...buttonProps}
                    testid={`${testid}.more-actions.remove`}
                    title={"Remove"}
                    icon={<DiscoIcon icon={"trash"} />}
                  />
                )}
              </RemoveAppButton>
            )}
          </DiscoMoreActionsDropdown>
        )
      }
    />
  )

  function handleOpenContentDrawer() {
    if (app.contentUsage)
      contentUsageDrawer.open({
        drawerContentUsageId: app.contentUsage!.id,
      })
  }

  function getRoute() {
    if (!isMobile) return ""

    const COURSE_PATHS = app.product ? generateCoursePaths(app.product.slug) : null

    switch (app.kind) {
      case "chat_channel":
        return generatePath(ROUTE_NAMES.PRODUCT.CHAT.CHANNEL, {
          productSlug: app.product!.slug,
          channelId: app.chatChannel!.id,
        })
      case "posts":
        return generatePath(ROUTE_NAMES.PRODUCT.FEED.POSTS.LIST, {
          productSlug: app.product!.slug,
          feedId: app.feed!.id,
        })
      case "curriculum":
        return COURSE_PATHS!.CURRICULUM.ROOT
      case "events":
        return COURSE_PATHS!.EVENTS
      case "resources":
        return COURSE_PATHS!.RESOURCES
      case "collection":
        return generatePath(ROUTE_NAMES.PRODUCT.COLLECTION.DETAIL, {
          productSlug: app.product!.slug,
          collectionId: app.collection!.id,
        })
      default:
        return ""
    }
  }

  function getName() {
    if (app.kind === "posts") {
      return app.feed?.name || "Feed"
    }
    if (app.customAppTitle) {
      return app.customAppTitle
    }
    if (app.kind === "members") {
      return labels.product_member.plural
    }
    if (app.kind === "organization_members") {
      return labels.organization_member.plural
    }
    if (app.kind === "curriculum") {
      return labels.curriculum.singular
    }
    return APP_KIND_LABELS[app.kind]
  }
}
