import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabels } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { isProductLevelRoute } from "@/core/route/util/routeUtils"
import { ExperienceAdminDropdownFragment$key } from "@/experience/admin/dropdown/__generated__/ExperienceAdminDropdownFragment.graphql"
import ManageAppsButton from "@/experience/admin/dropdown/manage-apps/ManageAppsButton"
import CreateInvitationButton from "@/invitation/create/button/CreateInvitationButton"
import CloneProductButton from "@/product/clone/button/CloneProductButton"
import ProductDeleteButton from "@/product/delete/button/ProductDeleteButton"
import { DiscoDivider, DiscoIcon } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { ArrayUtils } from "@utils/array/arrayUtils"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"

interface ExperienceAdminDropdownProps extends TestIDProps {
  productKey: ExperienceAdminDropdownFragment$key
  moreActionsButtonClassName?: string
  rotateIcon?: boolean
  handleRefetch?: () => void
}

function ExperienceAdminDropdown(props: ExperienceAdminDropdownProps) {
  const {
    testid = "ExperienceAdminDropdown",
    productKey,
    moreActionsButtonClassName,
    rotateIcon,
  } = props

  const product = useExperienceAdminDropdownFragment(productKey)
  const { manageProductItems, manageAppsAndMembersItems } =
    useExperienceAdminDropdownItems(props)

  if (!manageProductItems.length) return null

  return (
    <DiscoMoreActionsDropdown
      testid={`${testid}-${product.slug}-dropdown`}
      moreActionsButtonClassName={moreActionsButtonClassName}
      rotateIcon={rotateIcon}
    >
      {manageProductItems}
      {manageAppsAndMembersItems}
    </DiscoMoreActionsDropdown>
  )
}

export default ExperienceAdminDropdown

export function useExperienceAdminDropdownItems(
  props: Pick<ExperienceAdminDropdownProps, "testid" | "productKey" | "handleRefetch">
) {
  const { testid = "ExperienceAdminDropdown", productKey, handleRefetch } = props
  const settingsDrawer = useGlobalDrawer("experienceSettings")
  const certificatesDrawer = useGlobalDrawer("certificates")
  const history = useHistory()
  const activeOrganization = useActiveOrganization()
  const product = useExperienceAdminDropdownFragment(productKey)
  const permissions = usePermissions(product)
  const orgPermissions = usePermissions(activeOrganization)
  const itemsTestId = `${testid}.${product.slug}`
  const labels = useLabels()
  const PRODUCT_REPORTS = generatePath(ROUTE_NAMES.ADMIN.INSIGHTS.PRODUCT, {
    productSlug: product.slug,
  })
  const manageProductItems = [
    // Registration Page
    ...ArrayUtils.spreadIf(
      <DiscoDropdownItem
        key={`${itemsTestId}.RegistrationPage`}
        to={generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
          productSlug: product.slug,
        })}
        title={"Registration Page"}
        icon={<DiscoIcon icon={"ticket"} />}
        testid={`${itemsTestId}.RegistrationPage`}
      />,
      permissions.has("registration.manage")
    ),

    // Clone
    ...ArrayUtils.spreadIf(
      <CloneProductButton key={`${itemsTestId}.clone`} productId={product.id}>
        {(cloneButtonProps) => (
          <DiscoDropdownItem
            title={"Clone"}
            icon={<DiscoIcon icon={"iconsax.copy"} />}
            {...cloneButtonProps}
            testid={`${itemsTestId}.clone`}
          />
        )}
      </CloneProductButton>,
      activeOrganization?.viewerPermissions.has("products.create")
    ),

    // Reports
    ...ArrayUtils.spreadIf(
      <DiscoDropdownItem
        key={`${itemsTestId}.Reports`}
        to={PRODUCT_REPORTS}
        title={"Reports"}
        icon={<DiscoIcon icon={"chart-bar"} />}
        testid={`${itemsTestId}.Reports`}
      />,
      permissions.has("members.manage")
    ),

    // Certificates
    ...ArrayUtils.spreadIf(
      <DiscoDropdownItem
        key={`${itemsTestId}.Certificates`}
        onClick={() =>
          certificatesDrawer.open({ drawerCertificatesProductId: product.id })
        }
        title={"Certificates"}
        icon={<DiscoIcon icon={"achievement"} />}
        testid={`${itemsTestId}.Certificates`}
      />,
      permissions.has("certificate_templates.manage")
    ),

    // Settings
    ...ArrayUtils.spreadIf(
      <DiscoDropdownItem
        key={`${itemsTestId}.Settings`}
        onClick={() =>
          settingsDrawer.open({
            drawerExperienceId: product.id,
            experienceSettingsTab: "details",
          })
        }
        title={"Settings"}
        icon={<DiscoIcon icon={"settings"} />}
        testid={`${itemsTestId}.Settings`}
      />,
      permissions.has("experience_settings.manage")
    ),

    // Delete
    ...ArrayUtils.spreadIf(
      <ProductDeleteButton
        key={`${itemsTestId}.delete`}
        product={{
          id: product.id,
          type: product.type,
          name: product.name,
          slug: product.slug,
        }}
        onDelete={() => {
          if (handleRefetch) {
            handleRefetch()
          } else if (isProductLevelRoute(location.href, { productSlug: product.slug })) {
            history.push(ROUTE_NAMES.COMMUNITY.EXPERIENCES.UPCOMING)
          }
        }}
      >
        {(buttonProps) => (
          <DiscoDropdownItem
            {...buttonProps}
            title={"Delete"}
            icon={<DiscoIcon icon={"trash"} />}
            testid={`${itemsTestId}.delete`}
          />
        )}
      </ProductDeleteButton>,
      orgPermissions.has("products.archive")
    ),
  ]

  const manageAppsAndMembersItems = [
    ...ArrayUtils.spreadIf(
      <DiscoDivider key={`${itemsTestId}.divider`} />,
      permissions.has("apps.manage") || permissions.has("members.manage")
    ),

    ...ArrayUtils.spreadIf(
      <DiscoDropdownItem
        key={`${itemsTestId}.members`}
        icon={<DiscoIcon icon={"user"} />}
        to={generatePath(ROUTE_NAMES.PRODUCT.MEMBERS.ROOT, {
          productSlug: product.slug,
        })}
        title={labels.product_member.plural}
        testid={`${itemsTestId}.Members`}
      />,
      permissions.has("members.manage")
    ),

    ...ArrayUtils.spreadIf(
      <CreateInvitationButton
        key={`${itemsTestId}.create-invitation`}
        productId={product.id}
      >
        {(createInvitationButtonnProps) => (
          <DiscoDropdownItem
            key={`${itemsTestId}.send-invite`}
            title={"Send an invite"}
            icon={<DiscoIcon icon={"user-add"} />}
            {...createInvitationButtonnProps}
            testid={`${itemsTestId}.send-invite`}
          />
        )}
      </CreateInvitationButton>,
      permissions.has("invitations.manage")
    ),

    ...ArrayUtils.spreadIf(
      <ManageAppsButton key={`${itemsTestId}.manage-apps`} productId={product.id}>
        {(manageAppsButtonnProps) => (
          <DiscoDropdownItem
            key={`${itemsTestId}.manage-apps`}
            title={"Manage Apps"}
            icon={<DiscoIcon icon={"puzzle-piece"} />}
            {...manageAppsButtonnProps}
            testid={`${testid}-${product.slug}.manage-apps`}
          />
        )}
      </ManageAppsButton>,
      permissions.has("apps.manage")
    ),
  ]

  return { manageProductItems, manageAppsAndMembersItems }
}

function useExperienceAdminDropdownFragment(
  productKey: ExperienceAdminDropdownFragment$key
) {
  return useFragment<ExperienceAdminDropdownFragment$key>(
    graphql`
      fragment ExperienceAdminDropdownFragment on Product {
        id
        slug
        type
        name
        ...usePermissionsFragment
      }
    `,
    productKey
  )
}
