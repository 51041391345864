import { DiscoText } from "@disco-ui"

function DiscoTableEmptyCell() {
  return (
    <DiscoText variant={"body-sm"} color={"text.secondary"}>
      {"-"}
    </DiscoText>
  )
}

export default DiscoTableEmptyCell
