import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Modal, { ModalProps } from "@components/modal/Modal"
import DiscoButton, { DiscoButtonProps } from "@disco-ui/button/DiscoButton"
import DiscoIconButton from "@disco-ui/button/DiscoIconButton"
import DiscoCheckbox from "@disco-ui/checkbox/DiscoCheckbox"
import DiscoText from "@disco-ui/text/DiscoText"
import { useState } from "react"

export interface CheckToConfirmModalProps extends ModalProps {
  title: string
  description: React.ReactNode
  body?: React.ReactNode
  testid: string
  isConfirmation?: boolean
  customConfirmationButton?: React.ReactNode
  confirmationButtonProps?: DiscoButtonProps
  cancelButtonText?: string | null
  onCancel?: () => void
}

function DiscoCheckToConfirmModal({
  isOpen,
  onClose,
  title,
  description,
  body,
  testid,
  customConfirmationButton,
  confirmationButtonProps,
  parentSelector,
  modalContentLabel,
  /** Default is null */
  cancelButtonText = null,
  onCancel,
}: CheckToConfirmModalProps) {
  const classes = useStyles()
  const [checked, setChecked] = useState(false)

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      parentSelector={parentSelector}
      customClassName={classes.container}
      modalContentLabel={modalContentLabel}
    >
      <div className={classes.header}>
        <DiscoText variant={"body-lg-700"} data-testid={`${testid}.title`}>
          {title}
        </DiscoText>
        <DiscoIconButton size={"small"} onClick={onClose}>
          <CloseIcon />
        </DiscoIconButton>
      </div>
      <div className={classes.body}>
        <DiscoCheckbox
          checked={checked}
          label={
            <DiscoText align={"left"} data-testid={`${testid}.description`}>
              {description}
            </DiscoText>
          }
          testid={`${testid}.checkbox`}
          onChange={() => setChecked(!checked)}
          classes={{ root: classes.checkboxLabel }}
        />
        {body}
        <div className={classes.buttonsWrapper}>
          {cancelButtonText !== null && (
            <DiscoButton
              data-testid={`${testid}.cancel-button`}
              width={"100%"}
              color={"grey"}
              variant={"outlined"}
              onClick={onCancel || onClose}
            >
              {cancelButtonText}
            </DiscoButton>
          )}
          {customConfirmationButton ||
            (confirmationButtonProps && (
              <DiscoButton
                color={"primary"}
                width={"100%"}
                {...confirmationButtonProps}
                disabled={
                  !checked ||
                  confirmationButtonProps?.shouldDisplaySpinner ||
                  confirmationButtonProps?.disabled
                }
                data-testid={`${testid}.confirm-button`}
              >
                {confirmationButtonProps?.children}
              </DiscoButton>
            ))}
        </div>
      </div>
    </Modal>
  )
}

const useStyles = makeUseStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "480px",
      padding: "24px",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        padding: "16px",
        marginRight: "12px",
      },
      gap: theme.spacing(3),
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.palette.groovyDepths.insideCard,
      borderRadius: theme.measure.borderRadius.xl,
    },
    buttonsWrapper: {
      display: `flex`,
      gap: theme.spacing(1),
      justifyContent: "center",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyItems: "left",
      alignItems: "center",
      justifyContent: "space-between",
      gap: theme.spacing(2),
      width: "100%",
    },
    icon: {
      maxWidth: "24px",
      maxHeight: "24px",
    },
    body: {
      width: "100%",
      color: theme.palette.text.secondary,
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    checkboxLabel: {
      alignItems: "flex-start",
    },
  }
})

export default DiscoCheckToConfirmModal
