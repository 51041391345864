import DiscoLogo from "@/core/ui/images/disco-logo-light.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import DiscoIcon from "@disco-ui/icon/DiscoIcon"
import DiscoText from "@disco-ui/text/DiscoText"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DiscoPoweredByLogoProps extends TestIDProps {}

function DiscoPoweredByLogo({ testid = "DiscoPoweredByLogo" }: DiscoPoweredByLogoProps) {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <DiscoContainerButton
      className={classes.container}
      onClick={() => window.open("https://www.disco.co", "_blank")}
    >
      <div className={classes.details}>
        <DiscoText variant={"body-sm-700"} color={"common.white"} truncateText={1}>
          {"Powered by"}
        </DiscoText>
        <DiscoLogo data-testid={testid} className={classes.logo} />
      </div>
      <DiscoIcon icon={"external-link"} color={theme.palette.common.white} />
    </DiscoContainerButton>
  )
}

const useStyles = makeUseStyles((theme) => ({
  logo: {
    height: 24,
  },
  container: {
    background: theme.palette.gradient.black,
    padding: theme.spacing(1.75, 2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(1.5),
    color: theme.palette.common.white,
  },
  details: {
    display: "flex",
    gap: theme.spacing(1.25),
    alignItems: "center",
  },
}))
export default DiscoPoweredByLogo
