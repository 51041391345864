import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoInput, { DiscoInputProps } from "@disco-ui/input/DiscoInput"
import DiscoText from "@disco-ui/text/DiscoText"
import { InputAdornment, useTheme } from "@material-ui/core"
import React, { useState } from "react"

type Props = Omit<DiscoInputProps, "ref" | "onChange"> & {
  onChange: (value: string) => void
}

const DiscoLinkInput = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>(
  (props, ref) => {
    const { value, onChange, ...rest } = props
    const classes = useStyles()
    const [initialProtocol, initialDisplay] = parseUrl(value)
    const [displayValue, setDisplayValue] = useState(initialDisplay)
    const [protocol, setProtocol] = useState(initialProtocol || "https://")
    const theme = useTheme()

    return (
      <DiscoInput
        ref={ref}
        {...rest}
        startAdornment={
          <InputAdornment position={"start"} className={classes.protocol}>
            <DiscoText
              variant={"body-sm"}
              color={
                theme.palette.type === "dark" ? "groovy.onDark.300" : "groovy.neutral.400"
              }
            >
              {protocol}
            </DiscoText>
          </InputAdornment>
        }
        value={displayValue}
        onChange={handleChange}
      />
    )

    function parseUrl(val: unknown): [string, string] {
      if (typeof val !== "string") return ["", ""]
      const match = val.match(/^https?:\/\//)
      if (match) return [match[0], val.replace(match[0], "")]
      return ["", val]
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
      const [parsedProtocol, parsedVal] = parseUrl(e.target.value)
      if (parsedProtocol) setProtocol(parsedProtocol)
      setDisplayValue(parsedVal)
      onChange?.(parsedVal ? `${parsedProtocol || protocol}${parsedVal}` : "")
    }
  }
)

const useStyles = makeUseStyles((theme) => ({
  protocol: {
    height: "40px",
    maxHeight: "unset",
    borderRight: `1px solid ${theme.palette.constants.divider}`,
    paddingRight: theme.spacing(1),
  },
}))

export default DiscoLinkInput
