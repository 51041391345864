import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { Spacing } from "@assets/style/appMuiTheme"
import makeSpacingStyles from "@assets/style/util/makeSpacingStyles"
import styleIf from "@assets/style/util/styleIf"
import { Paper, Theme } from "@material-ui/core"
import classNames from "classnames"
import React from "react"

export type DiscoSectionProps = {
  testid?: string
  children: React.ReactNode
  className?: string
  id?: string
  tabIndex?: number
  onBlur?: React.FocusEventHandler<HTMLDivElement>
  border?: boolean
} & Partial<StyleProps> &
  Spacing

const DiscoSection = React.forwardRef<HTMLDivElement, DiscoSectionProps>(
  (
    {
      testid,
      children,
      className,
      radius = "big",
      id,
      groovyDepths,
      raiseOnHover,
      tabIndex,
      onBlur,
      border = false,
      ...spacing
    },
    ref
  ) => {
    const spacingStyles = useSpacingStyles({
      ...spacing,
      padding: spacing.padding ?? 3,
      margin: spacing.margin ?? 0,
    })
    const classes = useStyles({
      groovyDepths,
      radius,
      raiseOnHover,
      border,
    })
    return (
      <Paper
        ref={ref}
        id={id}
        classes={classes}
        elevation={0}
        data-testid={testid}
        className={classNames(spacingStyles.root, className)}
        tabIndex={tabIndex}
        onBlur={onBlur}
      >
        {children}
      </Paper>
    )
  }
)

type StyleProps = {
  groovyDepths?: Exclude<keyof Theme["palette"]["groovyDepths"], "raisedBoxShadow">
  radius: keyof Theme["measure"]["borderRadius"]
  raiseOnHover?: boolean
  border?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  root: ({ radius, groovyDepths, raiseOnHover, border }: StyleProps) => ({
    borderRadius: theme.measure.borderRadius[radius],
    boxShadow: groovyDepths ? theme.palette.groovyDepths[groovyDepths] : undefined,
    ...styleIf(raiseOnHover, {
      "&:hover": {
        boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
      },
    }),
    border: border ? theme.palette.constants.borderSmall : undefined,
  }),
}))

const useSpacingStyles = makeSpacingStyles()

export default DiscoSection
