import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoButton,
  DiscoDivider,
  DiscoFormControl,
  DiscoIcon,
  DiscoInput,
} from "@disco-ui"
import DiscoCalendar from "@disco-ui/date/DiscoCalendar"
import { Popover, useTheme } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/styles"
import DateUtils from "@utils/date/dateUtils"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { TestIDProps } from "@utils/typeUtils"
import classnames from "classnames"
import { format } from "date-fns"
import { ChangeEvent, MouseEvent, useEffect, useState } from "react"

interface DiscoDateTimeSelectProps extends TestIDProps {
  onChange: (detail: DateDetails) => void
  value: Date | null | undefined
  displayFormat?: DateFormatKey
  disabled?: boolean
  error?: boolean
  maxDate?: Date
  minDate?: Date
  className?: string
  classes?: Partial<ClassNameMap<"root" | "input">>
  allowNullDate?: boolean
}

export type DateFormatKey = "yyyy-MM-dd"

export type FormattedDateMap = Record<DateFormatKey, string>

/**
 * The callback value in the onChange event handler
 *
 * *value* is the raw Date returned by `react-day-picker` (nullable)
 *  and formatted is a map of formatted date strings
 */
export interface DateDetails {
  value: Date | null
  formatted: FormattedDateMap
}

function DiscoDatePicker(props: DiscoDateTimeSelectProps) {
  const {
    value,
    onChange,
    maxDate,
    minDate,
    disabled,
    error,
    displayFormat = DATE_FORMAT.DEFAULT,
    testid = "DiscoDatePicker",
    classes: propClasses,
    allowNullDate,
  } = props
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const classes = useStyles()
  const theme = useTheme()
  const [selectedDate, setSelectedDate] = useState(value || null)
  const [dateText, setDateText] = useState(DateUtils.formatDateInput(value))
  const [displayMonth, setDisplayMonth] = useState(value || undefined)

  useEffect(() => {
    setSelectedDate(value || null)
    setDateText(DateUtils.formatDateInput(value))
    setDisplayMonth(value || undefined)
  }, [value])

  return (
    <>
      <DiscoInput
        value={value ? format(value, displayFormat) : ""}
        onClick={disabled ? undefined : handleClick}
        readOnly
        error={error}
        disabled={disabled}
        classes={{
          root: classnames(classes.inputRoot, propClasses?.root),
          input: classnames(classes.input, propClasses?.input),
        }}
        fullWidth
        inputProps={{
          "data-testid": `${testid}.DiscoInput`,
          disabled,
        }}
        placeholder={"Select a date"}
        endAdornment={
          <DiscoIcon
            icon={"calendar"}
            color={disabled ? theme.palette.constants.icon : theme.palette.text.primary}
          />
        }
      />
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        data-testid={`${testid}.Popover`}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{ classes: { root: classes.popoverPaper } }}
      >
        <DiscoFormControl marginBottom={1.5}>
          <DiscoInput
            value={dateText}
            placeholder={"Select a date"}
            onChange={handleInputChange}
            onBlur={() => setDateText(DateUtils.formatDateInput(selectedDate))}
            className={classes.dateTextInput}
          />
        </DiscoFormControl>
        <DiscoCalendar
          mode={"single"}
          selected={selectedDate || undefined}
          onSelect={handleSelect}
          fromDate={minDate}
          toDate={maxDate}
          month={displayMonth}
          onMonthChange={setDisplayMonth}
        />
        <DiscoDivider marginTop={1.5} marginBottom={1.5} />
        <div className={classes.buttonsContainer}>
          <DiscoButton onClick={handleClose} color={"grey"} variant={"outlined"}>
            {"Cancel"}
          </DiscoButton>
          <DiscoButton
            onClick={handleSave}
            disabled={!selectedDate && !allowNullDate}
            data-testid={`DiscoDatePicker.submit-button`}
          >
            {"Save"}
          </DiscoButton>
        </div>
      </Popover>
    </>
  )

  function handleSelect(date: Date | undefined) {
    onChange({
      value: date || null,
      formatted: DateUtils.getFormattedDates(date),
    })
    setAnchorEl(null)
  }

  function handleSave() {
    onChange({
      value: selectedDate,
      formatted: DateUtils.getFormattedDates(selectedDate),
    })
    setAnchorEl(null)
  }

  function handleClick(e: MouseEvent<HTMLDivElement>) {
    setAnchorEl(e.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
    setDateText(DateUtils.formatDateInput(value))
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setDateText(e.target.value)
    const date = DateUtils.parseDate(e.target.value, minDate, maxDate)
    setSelectedDate(date)
    if (date) setDisplayMonth(date)
  }
}

const useStyles = makeUseStyles((theme) => ({
  inputRoot: {
    cursor: "pointer",
    minWidth: "188px",
  },
  input: {
    cursor: "pointer",
    "&:disabled": {
      cursor: "default",
    },
  },
  popoverPaper: {
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(2.5),
    boxShadow: theme.palette.groovyDepths.boxShadow,
    margin: 0,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
  },
  dateTextInput: {
    "& input": {
      ...theme.typography["body-sm"],
    },
  },
}))

export default DiscoDatePicker
