import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import { DiscoIcon, DiscoIconButton, DiscoText } from "@disco-ui"
import { Portal, Snackbar, SnackbarContent, SnackbarProps } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import React, { ReactNode, forwardRef } from "react"

interface DiscoFloatingBarContentProps extends TestIDProps {
  content: ReactNode
  icon: ReactNode
  buttons?: ReactNode
  onClose?: VoidFunction
  classes?: Partial<
    ClassNameMap<
      "action" | "root" | "message" | "icon" | "text" | "innerContent" | "contentButtons"
    >
  >
  belowModals?: boolean
}

type DiscoFloatingBarProps = Omit<SnackbarProps, "onClose"> &
  Omit<DiscoFloatingBarContentProps, "classes"> & {
    contentClasses?: DiscoFloatingBarContentProps["classes"]
  }

const DiscoFloatingBar = React.forwardRef<HTMLDivElement, DiscoFloatingBarProps>(
  (props, ref) => {
    const {
      content,
      icon,
      buttons,
      onClose,
      testid = "DiscoFloatingBar",
      contentClasses,
      belowModals,
      ...rest
    } = props

    const classes = useStyles({ belowModals })

    return (
      <Portal>
        <Snackbar
          data-testid={testid}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          classes={{
            root: classes.root,
          }}
          onClose={onClose}
          // Prevent the snackbar from closing when clicking off of it
          // They can still click the 'X' to close it
          ClickAwayListenerProps={{ onClickAway: () => null }}
          {...rest}
        >
          <DiscoFloatingBarContent
            ref={ref}
            content={content}
            icon={icon}
            buttons={buttons}
            onClose={onClose}
            testid={testid}
            classes={contentClasses}
          />
        </Snackbar>
      </Portal>
    )
  }
)

export const DiscoFloatingBarContent = forwardRef<
  HTMLDivElement,
  DiscoFloatingBarContentProps
>(
  (
    {
      icon,
      content,
      buttons,
      onClose,
      testid = "DiscoFloatingBar",
      classes: customClasses,
    },
    ref
  ) => {
    const classes = useContentStyles()
    return (
      <SnackbarContent
        ref={ref}
        classes={mergeClasses(
          {
            root: classes.root,
            action: classes.action,
            message: classes.message,
          },
          customClasses
        )}
        message={
          <>
            <div className={classNames(classes.icon, customClasses?.icon)}>{icon}</div>
            <div
              className={classNames(classes.innerContent, customClasses?.innerContent)}
            >
              {typeof content === "string" ? (
                <DiscoText
                  variant={"body-sm-600"}
                  color={"text.secondary"}
                  testid={`${testid}.text-content`}
                  className={classNames(classes.text, customClasses?.text)}
                  marginBottom={1.25}
                  marginTop={1.25}
                >
                  {content}
                </DiscoText>
              ) : (
                content
              )}
              <div
                className={classNames(
                  classes.contentButtons,
                  customClasses?.contentButtons
                )}
              >
                {buttons}
              </div>
            </div>
          </>
        }
        action={
          onClose && (
            // don't pass onClose directly to onClick, as its signature doesn't expect the click event to be passed as an arg
            <DiscoIconButton
              testid={`${testid}.close`}
              onClick={() => onClose()}
              width={"45px"}
              height={"45px"}
            >
              <DiscoIcon icon={"close"} />
            </DiscoIconButton>
          )
        }
      />
    )
  }
)

type StyleProps = {
  belowModals?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    left: "calc(50% + 150px)",
    zIndex: ({ belowModals }: StyleProps) =>
      belowModals ? theme.zIndex.floatingBar : theme.zIndex.modal,

    [theme.breakpoints.down("sm")]: {
      left: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      left: theme.spacing(3),
      right: theme.spacing(3),
      bottom: theme.spacing(3),
    },
  },
}))

const useContentStyles = makeUseStyles((theme) => ({
  root: {
    maxWidth: "970px",
    width: `calc(100vw - ${theme.measure.page.sideBarWidth}px - 64px)`,
    boxShadow: theme.palette.groovyDepths.insideCard,
    borderRadius: theme.measure.borderRadius.xl,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2.5, 3),
    zIndex: theme.zIndex.floatingBar,
    flexWrap: "nowrap",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 64px)",
      padding: theme.spacing(1.5),
    },
  },
  message: {
    margin: "auto",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    gap: theme.spacing(1.5),
    padding: 0,
    flex: "1",
  },
  action: {
    marginLeft: "unset",
    paddingLeft: theme.spacing(1.5),
  },
  innerContent: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: theme.spacing(0, 1.5),
  },
  icon: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contentButtons: {
    display: "flex",
    gap: theme.spacing(1.5),
    margin: "auto",
    "& > *": {
      width: "100%",
    },
  },
  text: {
    flex: "1 0 240px",
  },
}))

export default DiscoFloatingBar
