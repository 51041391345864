import ManageAppsModalForm from "@/experience/admin/dropdown/manage-apps/ManageAppsModalForm"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"

export interface ManageAppsButtonProps {
  children: OverridableDiscoButtonChildren
  /** needed since the button/modal is sometimes rendered outside active product context */
  productId: GlobalID
}

export default function ManageAppsButton({ children, productId }: ManageAppsButtonProps) {
  return (
    <OverridableDiscoButton
      modal={({ isOpen, onClose }) => {
        // Reset form state on each open
        if (!isOpen) return null
        return (
          <DiscoModal
            testid={"ManageAppsModal"}
            title={"Manage Apps"}
            subtitle={""}
            modalContentLabel={"Manage Apps"}
            isOpen
            onClose={onClose}
            body={<ManageAppsModalForm productId={productId} />}
          />
        )
      }}
    >
      {children}
    </OverridableDiscoButton>
  )
}
