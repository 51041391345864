import InfoIcon from "@/core/ui/iconsax/linear/custom-info.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { TextVariantWithModifiers } from "@assets/style/appMuiTheme"
import { DiscoIconButton, DiscoText } from "@disco-ui"
import { IconButtonProps } from "@material-ui/core"
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip"
import classNames from "classnames"
import React from "react"

type TooltipVariant = "primary" | "arrow"

export type DiscoTooltipProps = Omit<TooltipProps, "title" | "children"> & {
  title?: React.ReactNode
  content: React.ReactNode
  variant?: TooltipVariant
  buttonProps?: IconButtonProps
  children?: React.ReactElement
  iconButtonProps?: IconButtonProps
  textVariant?: TextVariantWithModifiers
  titleVariant?: TextVariantWithModifiers
  dataPrivate?: boolean
  disabled?: boolean
  backgroundColor?: string
}

function DiscoTooltip({
  children,
  content = "",
  placement,
  title,
  titleVariant,
  variant = "arrow",
  textVariant = "body-xs-600",
  buttonProps,
  dataPrivate,
  disabled,
  classes: muiClasses,
  backgroundColor,
  ...rest
}: DiscoTooltipProps) {
  const classes = useStyles({ backgroundColor })

  if (disabled || !content) return children || null

  return (
    <Tooltip
      title={
        <>
          {typeof title === "string" ? (
            <DiscoText
              testid={"DiscoTooltip.text"}
              variant={titleVariant || "body-md-600"}
              marginBottom={1}
              className={classNames({ "fs-mask": dataPrivate })}
            >
              {title}
            </DiscoText>
          ) : (
            title
          )}
          {typeof content === "string" ? (
            <DiscoText
              testid={"DiscoTooltip.text"}
              variant={textVariant}
              marginTop={1}
              marginBottom={1}
              className={classNames(classes.text, { "fs-mask": dataPrivate })}
            >
              {content}
            </DiscoText>
          ) : (
            content
          )}
        </>
      }
      classes={{
        ...muiClasses,
        tooltipArrow: classNames(classes.tooltip, muiClasses?.tooltipArrow),
        arrow: classNames(classes.tooltipArrow, muiClasses?.arrow),
      }}
      placement={placement || "top"}
      arrow={rest.arrow || variant === "arrow"}
      {...rest}
    >
      {children || (
        <DiscoIconButton
          {...buttonProps}
          classes={{
            root: classNames(classes.iconButton, buttonProps?.classes?.root),
          }}
        >
          <InfoIcon />
        </DiscoIconButton>
      )}
    </Tooltip>
  )
}

type StyleProps = {
  backgroundColor?: string
}

const useStyles = makeUseStyles((theme) => ({
  tooltip: ({ backgroundColor }: StyleProps) => ({
    backgroundColor:
      backgroundColor ||
      (theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.groovy.neutral[700]),
    // Override the DiscoText color but style the tooltip arrow separately
    "& p:not($tooltipArrow), & span:not($tooltipArrow)": {
      color: backgroundColor
        ? // Don't override DiscoText color when a custom bg color is set
          undefined
        : theme.palette.type === "dark"
        ? `${theme.palette.groovy.neutral[100]} !important`
        : `${theme.palette.common.white} !important`,
    },
    // Don't mess with the colours of buttons inside tooltips
    "& a span:not($tooltipArrow), & button span:not($tooltipArrow)": {
      color: "unset !important",
    },
    "& a": {
      ...theme.typography["body-xs"],
      ...theme.typography.modifiers.fontWeight["600"],
    },
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    padding: theme.spacing(1.25, 1.5, 1.25, 1.5),
  }),
  text: {
    ...theme.typography["body-xs"],
    ...theme.typography.modifiers.fontWeight["600"],
    color:
      theme.palette.type === "dark"
        ? theme.palette.groovy.neutral[100]
        : theme.palette.common.white,
  },
  tooltipArrow: ({ backgroundColor }: StyleProps) => ({
    color:
      backgroundColor ||
      (theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.groovy.neutral[700]),
  }),
  iconButton: {
    padding: 0,
    "& svg": {
      color: theme.palette.constants.icon,
    },
  },
}))

export default DiscoTooltip
