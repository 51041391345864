import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import useIsWebView from "@/product/util/hook/useIsWebView"
import Modal, { ModalProps } from "@components/modal/Modal"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import { DiscoDivider, DiscoIcon, DiscoText } from "@disco-ui"
import DiscoIconButton from "@disco-ui/button/DiscoIconButton"
import { useTheme } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { getWebViewHeader, WebViewHeader } from "@utils/webView/webViewUtils"
import classNames from "classnames"
import { createContext, ReactNode, useContext, useEffect, useState } from "react"

const WIDTH = "500px"
const HEIGHT = "auto"

export interface DiscoModalProps extends Exclude<ModalProps, "customClassName"> {
  title?: string | ReactNode
  subtitle?: string | ReactNode
  body?: ReactNode
  leftSideFooter?: ReactNode
  buttons?: ReactNode | true
  testid: string
  hideCloseIcon?: boolean
  classes?: Partial<
    ClassNameMap<
      | "container"
      | "wrapper"
      | "wrapperChild"
      | "body"
      | "title"
      | "headerRow"
      | "buttons"
      | "footer"
      | "leftSideFooter"
      | "closeButton"
    >
  >
  width?: string
  maxWidth?: string
  maxHeight?: string
  minWidth?: string
  height?: string
  minHeight?: string
  className?: string
  onBack?: () => void
  parentSelector?: () => HTMLElement
  hideFooter?: boolean
  fullWidthButtons?: boolean
  subtabs?: React.ReactNode
  isFullScreenInWebView?: boolean
}

function DiscoModal({
  isOpen,
  onClose,
  title: customTitle,
  subtitle,
  body,
  leftSideFooter,
  buttons,
  testid,
  hideCloseIcon = false,
  // prefer setting ideal width and height
  width: customWidth = WIDTH,
  height = HEIGHT,
  // avoid setting max/min width and height so they can remain responsive to content and screen size, but allow for overrides
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
  className,
  onBack,
  classes: customClasses,
  parentSelector,
  hideFooter = false,
  shouldCloseOnEsc = false,
  shouldCloseOnOverlayClick = false,
  fullWidthButtons = false,
  subtabs,
  isFullScreenInWebView,
  ...rest
}: DiscoModalProps) {
  const [buttonsRef, setButtonsRef] = useState<HTMLDivElement | null>(null)
  const [width, setWidth] = useState(customWidth)
  const [title, setTitle] = useState(customTitle)

  // keep context state in sync if props change
  useEffect(() => {
    setTitle(customTitle)
  }, [customTitle])
  useEffect(() => {
    setWidth(customWidth)
  }, [customWidth])

  const isWebView = useIsWebView()
  const webViewHeader = getWebViewHeader()

  const classes = useStyles({
    width,
    maxWidth,
    maxHeight,
    minWidth,
    height,
    minHeight,
    hasButtons: Boolean(buttons),
    fullWidthButtons,
    hasSubtabs: Boolean(subtabs),
    isWebView,
    webViewHeader,
    isFullScreenInWebView,
  })
  const theme = useTheme()

  return (
    <DiscoModalContext.Provider value={{ title, setTitle, buttonsRef, width, setWidth }}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        customClassName={classNames(
          classes.container,
          { width },
          className,
          customClasses?.container
        )}
        parentSelector={parentSelector}
        shouldCloseOnEsc={shouldCloseOnEsc}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        {...rest}
      >
        {/* Header Row */}
        <div className={classNames(classes.headerRow, customClasses?.headerRow)}>
          <div className={classes.lhsHeader}>
            {onBack && (
              <DiscoIconButton
                onClick={onBack}
                className={classes.backButton}
                width={40}
                height={40}
              >
                <DiscoIcon icon={"arrow-stem-left"} color={theme.palette.text.primary} />
              </DiscoIconButton>
            )}
            <div className={classes.lhsHeaderContent}>
              {/* Modal Title */}
              {title &&
                (typeof title === "string" ? (
                  <DiscoText
                    variant={"body-lg-600"}
                    data-testid={`${testid}.title`}
                    marginBottom={subtitle ? 0.5 : undefined}
                  >
                    {title}
                  </DiscoText>
                ) : (
                  <>{title}</>
                ))}
              {/* Modal Subtitle */}
              {subtitle &&
                (typeof subtitle === "string" ? (
                  <DiscoText
                    data-testid={`${testid}.subtitle`}
                    variant={"body-sm"}
                    color={"text.secondary"}
                  >
                    {subtitle}
                  </DiscoText>
                ) : (
                  <>{subtitle}</>
                ))}
            </div>
          </div>

          {/* Close Button */}
          {!hideCloseIcon && (
            <DiscoIconButton
              className={classNames(classes.closeButton, customClasses?.closeButton)}
              testid={`${testid}.close-button`}
              onClick={onClose}
              width={40}
              height={40}
            >
              <CloseIcon />
            </DiscoIconButton>
          )}
        </div>
        {/* Subtabs */}
        {subtabs && (
          <>
            <div className={classes.subtabs}>{subtabs}</div>
            <DiscoDivider marginTop={0} marginBottom={0} />
          </>
        )}
        {/* Modal Body */}
        {body && (
          <ScrollShadowContainer
            classes={{
              parentContainer: classNames(classes.wrapper, customClasses?.wrapper),
              scrollContainer: customClasses?.wrapperChild,
            }}
            shouldShowDivider
          >
            <div
              data-testid={`${testid}.body`}
              className={classNames(classes.body, customClasses?.body)}
            >
              {body}
            </div>
          </ScrollShadowContainer>
        )}

        {/* Modal Buttons */}
        {!hideFooter && (buttons || leftSideFooter) && (
          <div className={classNames(classes.footer, customClasses?.footer)}>
            {leftSideFooter && (
              <div className={customClasses?.leftSideFooter}>{leftSideFooter}</div>
            )}
            {buttons && (
              <div
                ref={(e) => setButtonsRef(e)}
                className={classNames(classes.buttons, customClasses?.buttons)}
                data-testid={`${testid}.buttons`}
              >
                {buttons}
              </div>
            )}
          </div>
        )}
      </Modal>
    </DiscoModalContext.Provider>
  )
}

type StyleProps = {
  width?: string
  maxWidth?: string
  maxHeight?: string
  minWidth?: string
  height?: string
  minHeight?: string
  hasButtons?: boolean
  fullWidthButtons: boolean
  hasSubtabs: boolean
  isWebView: boolean
  webViewHeader: WebViewHeader | null
  isFullScreenInWebView?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    borderRadius: theme.measure.borderRadius.xl,

    overflow: "hidden", // ensure anything outside the modal is hidden (ex: so content is constrained by border radius)

    // prefer setting ideal width and height
    width: ({ width }: StyleProps) => width || WIDTH,
    height: ({ height }: StyleProps) => height || HEIGHT,
    // avoid setting max/min width and height so they can remain responsive to content and screen size
    minWidth: ({ minWidth }: StyleProps) => minWidth || "auto",
    maxWidth: ({ maxWidth }: StyleProps) => maxWidth || "calc(100vw - 32px)",
    minHeight: ({ minHeight }: StyleProps) => minHeight || "auto",
    maxHeight: ({ maxHeight }: StyleProps) => maxHeight || "80vh",

    [theme.breakpoints.down("md")]: {
      marginTop: ({ webViewHeader, isFullScreenInWebView }: StyleProps) => {
        if (!webViewHeader) return undefined
        if (isFullScreenInWebView) return webViewHeader.insetTop / 3
        return webViewHeader.height / 3
      },
      maxHeight: ({ webViewHeader, maxHeight, isFullScreenInWebView }: StyleProps) => {
        if (!webViewHeader) return maxHeight || "80vh"
        if (isFullScreenInWebView)
          return `calc(${maxHeight || "80vh"} - ${webViewHeader.insetTop}px)`
        return `calc(${maxHeight || "80vh"} - ${webViewHeader.height}px)`
      },
    },

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  headerRow: ({ hasSubtabs }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: hasSubtabs ? theme.spacing(3, 3, 0) : theme.spacing(3),
    gap: theme.spacing(1.5),

    [theme.breakpoints.down("xs")]: {
      padding: hasSubtabs ? theme.spacing(1.5, 1.5, 0) : theme.spacing(1.5),
    },
  }),
  lhsHeader: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    gap: theme.spacing(1.5),
    minWidth: 0,
  },
  lhsHeaderContent: {
    width: "100%",
  },
  subtabs: {
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 1.5),
    },
  },
  closeButton: {
    alignSelf: "flex-start",
    "&:hover": {
      cursor: "pointer",
    },
  },
  backButton: {
    color: theme.palette.text.primary,
    alignSelf: "flex-start",
  },
  body: ({ hasSubtabs }: StyleProps) => ({
    padding: theme.spacing(hasSubtabs ? 2.5 : 0.5, 3, 3), // default to padding top .5 because many of our components (ex: DiscoInput) have a boxShadow that we want to account for

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(hasSubtabs ? 1 : 0.5, 1.5, 1.5),
    },
  }),
  footer: {
    width: "100%",
    padding: theme.spacing(3),
    background: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.5),
    },
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    marginLeft: "auto",
    width: ({ fullWidthButtons }: StyleProps) => (fullWidthButtons ? "100%" : "auto"),
  },
}))

export const DiscoModalContext = createContext<{
  buttonsRef?: HTMLDivElement | null
  width?: string
  setWidth?: React.Dispatch<React.SetStateAction<string>>
  title?: React.ReactNode | string
  setTitle?: React.Dispatch<React.SetStateAction<React.ReactNode | string>>
} | null>(null)

export function useModalContext() {
  return useContext(DiscoModalContext)
}

export default DiscoModal
