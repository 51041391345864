import useDebounce from "@utils/hook/useDebounce"
import { ValueOf } from "@utils/typeUtils"
import React, { useState } from "react"

type UseTableStateArgs<T> = {
  initialActivePage?: number
  initialToolbarState: T
}

type DiscoTableOrderByOption = {
  field: string
  direction?: string | null
}

export type DiscoTableFilterByOption = {
  title: string
  value: string | null
} | null

export namespace DiscoTableUtils {
  export interface ToolbarState {
    search?: string | null
    orderBy?: DiscoTableOrderByOption | null
    filterBy?: Record<string, DiscoTableFilterByOption> | null
  }

  export type ToolbarStateHandlers<T extends ToolbarState> = {
    handleSearchChange: (value: string) => void
    /** optionally provide a direction, or it will be toggled in the opposite direction when the same field is passed twice */
    handleOrderBy: (
      value: Omit<NonNullable<T["orderBy"]>, "direction"> & {
        direction?: NonNullable<T["orderBy"]>["direction"] | null
      }
    ) => void
    handleFilterBy: (value: {
      value: string
      option: ValueOf<T["filterBy"]> | null
    }) => void
    handleRemoveFilter: (field: string) => void
  }

  type TableState<T extends ToolbarState> = ToolbarStateHandlers<T> & {
    activePage: number
    setActivePage: React.Dispatch<React.SetStateAction<number>>
    toolbarState: T
    setToolbarState: React.Dispatch<React.SetStateAction<T>>
  }

  export function useTableState<T extends ToolbarState>(
    args: UseTableStateArgs<T>
  ): TableState<T> {
    const [activePage, setActivePage] = useState(args.initialActivePage ?? 0)
    const [toolbarState, setToolbarState] = useState<T>(args.initialToolbarState)
    const handleSearchChange = useDebounce(handleSearch, 500)

    const handleOrderBy: ToolbarStateHandlers<T>["handleOrderBy"] = (value) => {
      setToolbarState((prev) => {
        // use an explicit direction if provided, cycle a previously selected direction from ASC -> DESC -> remove order (NULL), or default to ASC
        const direction =
          value.direction ??
          (prev.orderBy?.field === value.field
            ? prev.orderBy?.direction === "ASC"
              ? "DESC"
              : prev.orderBy?.direction === "DESC"
              ? null
              : "ASC"
            : "ASC")

        return {
          ...prev,
          orderBy: direction ? { ...value, direction } : null,
        }
      })
    }

    const handleFilterBy: ToolbarStateHandlers<T>["handleFilterBy"] = ({
      value,
      option,
    }) => {
      setToolbarState((prev) => ({
        ...prev,
        filterBy: {
          ...prev.filterBy,
          [value]: option,
        },
      }))
    }

    const handleRemoveFilter: ToolbarStateHandlers<T>["handleRemoveFilter"] = (field) => {
      setToolbarState((prev) => {
        const filterBy = { ...prev.filterBy }
        delete filterBy[field]
        return {
          ...prev,
          filterBy,
        }
      })
    }

    return {
      activePage,
      setActivePage,
      toolbarState,
      setToolbarState,
      handleSearchChange,
      handleOrderBy,
      handleFilterBy,
      handleRemoveFilter,
    }

    function handleSearch(value: string) {
      setToolbarState((prev) => ({
        ...prev,
        search: value,
      }))
    }
  }
}
