import { BadgeKind } from "@/community/__generated__/CommunityBadgeFragment.graphql"
import { ObservableState } from "@/core/form/store/FormStore"
import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { BadgeInput } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import styleIf from "@assets/style/util/styleIf"
import { STATIC_IMAGE_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import Modal from "@components/modal/Modal"
import DiscoIconButton from "@disco-ui/button/DiscoIconButton"
import DiscoColorPicker from "@disco-ui/color/DiscoColorPicker"
import DiscoColorTabPicker from "@disco-ui/color/DiscoColorTabPicker"
import DiscoDivider from "@disco-ui/divider/DiscoDivider"
import DiscoEmojiPicker from "@disco-ui/emoji-picker/DiscoEmojiPicker"
import DiscoImageFormField from "@disco-ui/file/DiscoImageFormField"
import DiscoFormControl from "@disco-ui/form-control/DiscoFormControl"
import {
  DiscoIconPickerIcons,
  pickerIcons,
} from "@disco-ui/icon-picker/DiscoIconPickerIcons"
import { DiscoIconToIconsaxMap } from "@disco-ui/icon/iconMaps"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import DiscoText from "@disco-ui/text/DiscoText"
import { IconButton, useTheme } from "@material-ui/core"
import { ASPECT_RATIOS } from "@utils/image/imageConstants"
import { TestIDProps } from "@utils/typeUtils"
import { BaseEmoji } from "emoji-mart"
import { action } from "mobx"
import { observer } from "mobx-react-lite"
import React, { useState } from "react"

interface DiscoBadgePickerProps extends TestIDProps {
  badge: ObservableState<BadgeInput>
  children: React.FC<{
    onClick(
      e: React.MouseEvent<HTMLDivElement | HTMLButtonElement | HTMLLIElement, MouseEvent>
    ): void
  }>
}

function DiscoBadgePicker({
  children,
  badge,
  testid = "DiscoBadgePicker",
}: DiscoBadgePickerProps) {
  const [openPicker, setOpenPicker] = useState(false)
  const [showColorPicker, setShowColorPicker] = useState(false)
  const { kind } = badge
  const [pickerTab, setPickerTab] = useState(kind)
  const theme = useTheme()
  const classes = useStyles({ kind })

  return (
    <>
      {React.createElement(children, {
        onClick: () => setOpenPicker(true),
      })}

      <Modal
        isOpen={openPicker}
        onClose={closePicker}
        customClassName={classes.container}
        modalContentLabel={"Badge Picker"}
      >
        <div className={classes.header}>
          <DiscoTabs
            testid={`${testid}.DiscoBadgePicker`}
            routes={[
              {
                label: "Icon",
                onClick: action(() => {
                  setPickerTab("icon")
                  setShowColorPicker(false)
                }),
                active: pickerTab === "icon" && !showColorPicker,
                testid: "icon",
              },
              {
                label: "Emoji",
                onClick: action(() => {
                  setPickerTab("emoji")
                  setShowColorPicker(false)
                }),
                active: pickerTab === "emoji",
                testid: "emoji",
              },
              {
                label: "Color",
                onClick: action(() => {
                  setPickerTab("icon")
                  setShowColorPicker(true) // This indicates we want to show the color picker
                }),
                active: showColorPicker,
                testid: "color-tab",
              },
              {
                label: "Upload",
                onClick: action(() => {
                  setPickerTab("upload")
                  setShowColorPicker(false)
                }),
                active: pickerTab === "upload",
                testid: "upload",
              },
            ]}
          />
          <DiscoIconButton
            className={classes.closeIcon}
            onClick={closePicker}
            testid={"DiscoBadgePicker.close-button"}
          >
            <CloseIcon />
          </DiscoIconButton>
        </div>
        <DiscoDivider className={classes.divider} />

        {/* Icon Selection **/}
        {pickerTab === "icon" && !showColorPicker && (
          <>
            <DiscoFormControl disabled={badge?.kind !== "icon"}>
              <DiscoColorPicker
                value={badge?.color}
                fullWidth
                onChange={(v) => (badge!.color = v)}
                disabled={badge?.kind !== "icon"}
              />
            </DiscoFormControl>
            <DiscoText className={classes.title} variant={"body-xs-600-uppercase"}>
              {"ICON LIBRARY"}
            </DiscoText>
            <div className={classes.iconsContainer}>
              {Object.keys(pickerIcons).map((key) => {
                if (!DiscoIconToIconsaxMap[key as DiscoIconPickerIcons]) return null
                const icon = DiscoIconPickerIcons[key as DiscoIconPickerIcons].default
                return (
                  <IconButton
                    key={key}
                    className={classes.iconContainer}
                    onClick={() => {
                      resetPickerState()
                      handleSelectIcon(key as DiscoIconPickerIcons)
                    }}
                    title={pickerIcons[key as DiscoIconPickerIcons]}
                    data-testid={`${testid}.icon.${key}`}
                  >
                    {icon}
                  </IconButton>
                )
              })}
            </div>
          </>
        )}

        {/* Emoji Selection **/}
        {pickerTab === "emoji" && (
          <DiscoEmojiPicker
            onChange={(e) => {
              resetPickerState()
              handleSelectEmoji(e)
            }}
            onClose={closePicker}
          />
        )}

        {/* Custom Upload Selection **/}
        {pickerTab === "upload" && (
          <DiscoFormControl>
            <DiscoImageFormField
              message={"Drag and drop your image here"}
              value={badge?.mediaUrl}
              onChange={(r) => {
                resetPickerState()
                handleImageUpload(r)
              }}
              maxWidth={128}
              suggestedDimensions={{ width: 128, height: 128 }}
              accept={STATIC_IMAGE_FILE_TYPES}
              cropperProps={{
                stencilProps: { aspectRatio: ASPECT_RATIOS.SQUARE },
              }}
            />
          </DiscoFormControl>
        )}

        {/* If showColorPicker is true, show the color picker */}
        {showColorPicker && (
          <DiscoFormControl>
            <DiscoColorTabPicker
              value={badge?.color}
              onChange={action((v) => {
                resetPickerState()
                badge.color = v
                handleSolidColor()
              })}
            />
          </DiscoFormControl>
        )}
      </Modal>
    </>
  )

  function closePicker() {
    setOpenPicker(false)
  }

  function resetPickerState() {
    badge.emoji = null
    badge.icon = null
    badge.mediaUrl = null
  }

  function handleSelectEmoji(emoji: BaseEmoji | null) {
    badge.kind = "emoji"
    badge.emoji = emoji?.native
    badge.color = null
  }

  function handleSelectIcon(icon: DiscoIconPickerIcons) {
    badge.kind = "icon"
    badge.icon = icon
    badge.color = badge.color || theme.palette.background.paper
    closePicker()
  }

  function handleImageUpload(result: MediaResult | null) {
    badge.kind = "upload"
    badge.mediaUrl = result?.url || null
    badge.assetId = result?.id || null
    closePicker()
  }
  function handleSolidColor() {
    badge.color = badge.color || theme.palette.background.paper
    badge.kind = "icon"
    badge.icon = null
    badge.emoji = null
  }
}

type StyleProps = {
  kind: BadgeKind
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ kind }: StyleProps) => ({
    width: "390px",
    minHeight: "370px",
    boxShadow: theme.palette.groovyDepths.boxShadow,
    borderRadius: `${theme.measure.borderRadius.big} !important`,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2.5),
    ...styleIf(kind === "emoji", {
      height: "530px",
    }),
  }),
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  divider: {
    marginTop: "-3px",
  },
  title: {
    color: theme.palette.groovy.grey[400],
    fontSize: "11px",
    lineHeight: "13px",
    letterSpacing: "0.06px",
  },

  iconsContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  iconContainer: {
    padding: theme.spacing(0.625),
    cursor: "pointer",
    color: theme.palette.text.secondary,

    "&:hover": {
      backgroundColor: theme.palette.groovy.neutral[100],
      borderRadius: theme.measure.borderRadius.default,
    },
    "& svg": {
      width: "24px",
    },
  },
  closeIcon: {
    marginLeft: "auto",
    "&:hover": {
      cursor: "pointer",
    },
  },
}))

export default observer(DiscoBadgePicker)
