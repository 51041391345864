import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoButtonSkeleton } from "@disco-ui/button/DiscoButton"
import { Skeleton } from "@material-ui/lab"
import { ClassNameMap } from "@material-ui/styles"
import { hexToHsl } from "@utils/color/colorUtils"
import { TestIDProps } from "@utils/typeUtils"
import classnames from "classnames"
import { ReactNode } from "react"
import { Styles as JssStyles } from "react-jss"

export interface DiscoBannerProps extends TestIDProps {
  message: ReactNode
  buttons: ReactNode
  icon?: ReactNode
  variant: DiscoBannerVariant
  classes?: Partial<ClassNameMap<"banner" | "icon">>
}

type DiscoBannerVariant = "quiz" | "slack" | "copilot" | "no-border"

function DiscoBanner(props: DiscoBannerProps) {
  const {
    testid = "DiscoBanner",
    message,
    buttons,
    variant,
    icon,
    classes: propClasses,
  } = props

  const classes = useStyles({
    variant,
  })

  return (
    <div data-testid={testid} className={classes.container}>
      <div className={classnames(classes.banner, propClasses?.banner)}>
        {icon && (
          <div className={classnames(classes.icon, propClasses?.icon)}>{icon}</div>
        )}
        <div className={classes.message}>{message}</div>
        <div className={classes.buttons}>{buttons}</div>
      </div>
    </div>
  )
}

export function DiscoBannerSkeleton() {
  const classes = useStyles({ variant: "quiz" })
  return (
    <div className={classes.container}>
      <div className={classes.banner}>
        <div className={classes.message}>
          <Skeleton variant={"text"} />
          <Skeleton variant={"text"} />
        </div>
        <div className={classes.buttons}>
          <DiscoButtonSkeleton />
        </div>
      </div>
    </div>
  )
}

type StyleProps = {
  variant: DiscoBannerVariant
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(3),
  },
  banner: ({ variant }: StyleProps) => ({
    border: "double 1px transparent",
    borderRadius: theme.measure.borderRadius.big,
    backgroundOrigin: "border-box",
    backgroundClip: "padding-box, border-box",
    padding: theme.spacing(2.5),

    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),

    ...styleIf(variant === "quiz", {
      // this is a gradient outline
      // first gradient layer is the background color of the card
      // second gradient layer creates the outline, which goes from the primary color, to a rotated hue based on the difference between the primary color and the hue from original design
      backgroundImage: () => {
        const hsl = hexToHsl(theme.palette.primary.main)
        const rotatedHue = hsl
          .map((value, i) => (i === 0 ? (value - 228) % 360 : `${value}%`))
          .join(", ")

        return `linear-gradient(${theme.palette.groovy.neutral[100]}, ${theme.palette.groovy.neutral[100]}), linear-gradient(51.49deg, ${theme.palette.primary.main} 0.05%, hsl(${rotatedHue}) 100.08%)`
      },
    } as JssStyles),
    ...styleIf(variant === "slack", {
      backgroundImage: `linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.paper}), linear-gradient(51.49deg, #4A154B 0.05%, #ECB22E 100.08%)`,
    }),
    ...styleIf(variant === "copilot", {
      backgroundImage: `linear-gradient(#4467F61A 10%, #FFFFFF 10%, #4467F61A 10%),linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.paper}), linear-gradient(51.49deg, #4664FF 0.05%, #FF855F 100.08%)`,
      backgroundClip: "padding-box, padding-box, border-box",
    }),
    ...styleIf(variant === "no-border", {
      border: "none",
      backgroundColor: theme.palette.background.paper,
    }),
  }),
  message: {
    flex: "1 1 auto",
  },
  buttons: {
    flex: "1 0 auto",
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    flexWrap: "wrap",
  },
  icon: {
    width: 48,
    height: 48,
  },
}))

export default DiscoBanner
