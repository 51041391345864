import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoButton,
  DiscoDivider,
  DiscoFormControl,
  DiscoIcon,
  DiscoInput,
} from "@disco-ui"
import DiscoCalendar from "@disco-ui/date/DiscoCalendar"
import { DateDetails, DateFormatKey } from "@disco-ui/date/DiscoDatePicker"
import { Popover, useTheme } from "@material-ui/core"
import DateUtils from "@utils/date/dateUtils"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { TestIDProps } from "@utils/typeUtils"
import { format, isAfter, isValid } from "date-fns"
import { MouseEvent, useEffect, useState } from "react"
import { DateRange } from "react-day-picker"

interface DiscoDateRangePickerProps extends TestIDProps {
  onChange: (detail: DateRangeDetails) => void
  value: DateRange
  /** The format for the preview */
  displayFormat?: DateFormatKey
  disabled?: boolean
  error?: boolean
  maxDate?: Date
  minDate?: Date
}
export interface DateRangeDetails {
  from: DateDetails
  to: DateDetails
}

function DiscoDateRangePicker(props: DiscoDateRangePickerProps) {
  const {
    value,
    onChange,
    maxDate,
    minDate,
    disabled,
    error,
    displayFormat = DATE_FORMAT.DEFAULT,
    testid = "DiscoDateRangePicker",
  } = props
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const classes = useStyles()
  const theme = useTheme()

  const [range, setRange] = useState<DateRange>({
    from: value.from || undefined,
    to: value.to || undefined,
  })
  const [fromText, setFromText] = useState(DateUtils.formatDateInput(range.from))
  const [toText, setToText] = useState(DateUtils.formatDateInput(range.to))
  const [displayMonth, setDisplayMonth] = useState(range.from)

  useEffect(() => {
    setRange({
      from: value.from || undefined,
      to: value.to || undefined,
    })
    setFromText(DateUtils.formatDateInput(value.from))
    setToText(DateUtils.formatDateInput(value.to))
    setDisplayMonth(value.from)
  }, [value])

  return (
    <>
      <DiscoInput
        value={renderDatePreviewValue()}
        onClick={handleClick}
        readOnly
        error={error}
        disabled={disabled}
        className={classes.input}
        classes={{
          input: classes.input,
          root: classes.inputRoot,
        }}
        fullWidth
        inputProps={{
          "data-testid": `${testid}.DiscoInput`,
          disabled,
        }}
        placeholder={"Select a date"}
        endAdornment={
          <DiscoIcon
            icon={"calendar"}
            color={disabled ? theme.palette.constants.icon : theme.palette.text.primary}
          />
        }
      />
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        data-testid={"DiscoDateRangePicker.Popover"}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{ classes: { root: classes.popoverPaper } }}
      >
        <div className={classes.dateInputsContainer}>
          <DiscoFormControl marginBottom={1.5}>
            <DiscoInput
              value={fromText}
              placeholder={"From date"}
              onChange={(e) => handleInputChange("from", e.target.value)}
              onBlur={() => setFromText(DateUtils.formatDateInput(range.from))}
            />
          </DiscoFormControl>
          <DiscoFormControl marginBottom={1.5}>
            <DiscoInput
              value={toText}
              placeholder={"To date"}
              onChange={(e) => handleInputChange("to", e.target.value)}
              onBlur={() => setToText(DateUtils.formatDateInput(range.to))}
            />
          </DiscoFormControl>
        </div>
        <DiscoCalendar
          mode={"range"}
          selected={range}
          onSelect={handleSelect}
          fromDate={minDate}
          toDate={maxDate}
          month={displayMonth}
          onMonthChange={setDisplayMonth}
        />
        <DiscoDivider marginTop={1.5} marginBottom={1.5} />
        <div className={classes.buttonsContainer}>
          <DiscoButton onClick={handleClose} color={"grey"} variant={"outlined"}>
            {"Cancel"}
          </DiscoButton>
          <DiscoButton
            onClick={handleSave}
            // Enforce a range to be selected
            disabled={!range.from || !range.to}
            data-testid={`DiscoDateRangePicker.submit-button`}
          >
            {"Save"}
          </DiscoButton>
        </div>
      </Popover>
    </>
  )

  function handleSave() {
    const details: DateRangeDetails = {
      from: {
        value: range.from || null,
        formatted: DateUtils.getFormattedDates(range.from),
      },
      to: {
        value: range.to || null,
        formatted: DateUtils.getFormattedDates(range.to),
      },
    }

    onChange(details)
    setAnchorEl(null)
  }

  function handleSelect(dateRange: DateRange | undefined) {
    if (!dateRange) {
      setRange({
        from: undefined,
      })
      return
    }
    setRange(dateRange)
    setFromText(DateUtils.formatDateInput(dateRange.from))
    setToText(DateUtils.formatDateInput(dateRange.to))
  }

  /** Returns the formatted date range string */
  function renderDatePreviewValue() {
    if (!range.from) {
      return ""
    }

    if (!range.to) {
      return `${format(range.from, displayFormat)} - `
    }

    return `${format(range.from, displayFormat)} - ${format(range.to, displayFormat)}`
  }

  function handleClick(e: MouseEvent<HTMLDivElement>) {
    if (disabled) return
    setAnchorEl(e.currentTarget)
  }

  /** Close the popover and reset the range state */
  function handleClose() {
    setAnchorEl(null)
    setRange({
      from: value.from || undefined,
      to: value.to || undefined,
    })
    setFromText(DateUtils.formatDateInput(value.from))
    setToText(DateUtils.formatDateInput(value.to))
  }

  function handleInputChange(key: keyof DateRange, text: string) {
    if (key === "from") {
      setFromText(text)
    } else {
      setToText(text)
    }
    const date = DateUtils.parseDate(text, minDate, maxDate)
    const newRange = {
      ...range,
      [key]: date || undefined,
    }
    if (newRange.from) {
      if (key === "from" && !newRange.to) {
        // Check if the date text in the "to" input would now create a valid range
        const toDate = DateUtils.getDateObjectFromDateString(toText) || new Date(toText)
        if (isValid(toDate)) newRange.to = toDate
      }
      if (newRange.to && isAfter(newRange.from, newRange.to)) {
        newRange.to = undefined
      }
    }
    setRange(newRange)
    if (newRange[key]) setDisplayMonth(newRange[key])
  }
}

const useStyles = makeUseStyles((theme) => ({
  popoverPaper: {
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(2.5),
    boxShadow: theme.palette.groovyDepths.boxShadow,
    margin: 0,
  },
  inputRoot: {
    cursor: "pointer",
  },
  input: {
    cursor: "pointer",
    "&:disabled": {
      cursor: "default",
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
  },
  dateInputsContainer: {
    display: "flex",
    gap: theme.spacing(1.5),
    "& > *": {
      width: 0,
      flexGrow: 1,
    },
    "& input": {
      ...theme.typography["body-sm"],
    },
  },
}))

export default DiscoDateRangePicker
