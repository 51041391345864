import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import React from "react"

export interface DiscoDividerProps extends TestIDProps, Partial<StyleProps> {
  className?: string
  style?: React.CSSProperties
  /** The thickness of the line. Default is 1px */
  thickness?: number
  // Custom color
  color?: string
  vertical?: boolean
  centerText?: string
}

const DiscoDivider: React.FC<DiscoDividerProps> = ({
  className,
  style,
  color,
  thickness = 1,
  marginTop = 1,
  marginBottom = 1,
  vertical = false,
  testid = "DiscoDivider",
  centerText,
}) => {
  const classes = useStyles({
    marginTop,
    marginBottom,
    color,
    thickness,
    vertical,
    centerText,
  })
  return (
    <hr
      className={classNames(classes.hr, className)}
      style={style}
      data-testid={testid}
    />
  )
}

interface StyleProps {
  marginTop: number
  marginBottom: number
  color?: string
  vertical?: boolean
  thickness?: number
  centerText?: string
}

const useStyles = makeUseStyles((theme) => ({
  hr: (props: StyleProps) => ({
    marginTop: theme.spacing(props.marginTop),
    marginBottom: theme.spacing(props.marginBottom),
    backgroundColor: props.color || theme.palette.constants.divider,
    height: `${props.thickness}px`,
    border: "none",
    width: "100%",

    ...styleIf(props.vertical, {
      transform: "rotate(90deg)",
    }),
    ...styleIf(props.centerText, {
      position: "relative",
      overflow: "visible",
      "&::after": {
        position: "absolute",
        content: `'${props.centerText}'`,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: theme.spacing(0, 1),
        background: theme.palette.background.paper,
        ...theme.typography["body-sm"],
        color: theme.palette.text.secondary,
        fontWeight: 500,
      },
    }),
  }),
}))

export default DiscoDivider
