import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import styleIf from "@assets/style/util/styleIf"
import { InputBase, InputBaseProps, useTheme } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import React from "react"

export type DiscoInputProps = {
  minHeight?: string
  padding?: string
  size?: DiscoInputSize
  disableFocus?: boolean
  disableHover?: boolean
  ref?: React.Ref<HTMLInputElement | HTMLTextAreaElement>
} & InputBaseProps

type DiscoInputSize = "large" | "small"

const DiscoInput = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  DiscoInputProps
>(
  (
    {
      minHeight,
      padding,
      classes: customClasses,
      size = "small",
      value,
      disableFocus = false,
      disableHover = false,
      ...props
    },
    ref
  ) => {
    const classes = useStyles({
      multiline: props.multiline,
      minHeight,
      size,
      padding,
      disableFocus,
      disableHover,
    })
    return (
      <InputBase
        ref={ref}
        classes={mergeClasses(customClasses, classes)}
        onKeyDown={(e) => e.stopPropagation()}
        value={value === null ? "" : value}
        {...props}
      />
    )
  }
)

type StyleProps = Pick<
  DiscoInputProps,
  "multiline" | "minHeight" | "padding" | "size" | "disableFocus" | "disableHover"
>

const useStyles = makeUseStyles((theme) => ({
  root: ({
    multiline,
    minHeight,
    padding,
    size,
    disableFocus,
    disableHover,
  }: StyleProps) => ({
    color: theme.palette.text.primary,
    height: 40,
    ...styleIf(size === "large", {
      height: 48,
    }),
    padding: padding ?? "0 10px",
    ...styleIf(multiline, {
      height: "auto",
      minHeight: "48px",
      padding: padding ?? theme.spacing(2),
      alignItems: "start",
    }),
    ...styleIf(minHeight, { minHeight }),

    borderRadius: theme.measure.borderRadius.big,
    border: "1.5px solid transparent",

    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[500]
        : theme.palette.groovy.neutral[100],

    ...styleIf(!disableFocus, {
      "&.Mui-focused": {
        backgroundColor: theme.palette.background.paper,
        boxShadow:
          theme.palette.type === "dark"
            ? `0 0 0 1.5px ${theme.palette.primary.main}`
            : `0 0 0 1.5px ${theme.palette.primary.main}, 0 0 0 5px ${theme.palette.primary.light}`,
      },
    }),

    ...styleIf(!disableHover, {
      "&:hover:not(.Mui-focused)": {
        backgroundColor:
          theme.palette.type === "dark"
            ? theme.palette.groovy.onDark[400]
            : theme.palette.groovy.neutral[200],
      },
    }),

    "&.Mui-error:not(.MUI)": {
      border: `1.5px solid ${theme.palette.error.dark}`,
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      ...styleIf(!multiline, {
        height: "36px",
      }),
      ...styleIf(multiline, {
        minHeight: "36px",
      }),
      ...styleIf(minHeight, { minHeight }),
    },
  }),
  input: {
    height: "unset",
    ...theme.typography["body-sm"],
    flex: "1 0 60%",
  },
  adornedEnd: {
    "& svg": {
      height: "24px",
      width: "24px",
    },
  },
  adornedStart: {
    "& svg": {
      height: "24px",
      width: "24px",
    },
  },
}))

export default DiscoInput

type DiscoInputSkeletonProps = {
  height?: number
  flexGrow?: number
  style?: React.CSSProperties
}

export const DiscoInputSkeleton: React.FC<DiscoInputSkeletonProps> = ({
  height,
  flexGrow,
  style,
}) => {
  const theme = useTheme()
  return (
    <Skeleton
      variant={"rect"}
      style={{ borderRadius: theme.measure.borderRadius.big, flexGrow, ...style }}
      height={height || 40}
    />
  )
}
