import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import {
  Drawer,
  DrawerProps,
  MenuList,
  MenuListProps,
  Omit,
  useTheme,
} from "@material-ui/core"
import classNames from "classnames"
import React from "react"

export type DiscoSideBarProps = {
  testid?: string
  open?: boolean
  menuListProps?: Omit<MenuListProps, "classes">
  fullHeight?: boolean
  header?: React.ReactElement
  items?: React.ReactElement
  adminActions?: React.ReactElement
  children?: never
} & Omit<DrawerProps, "open" | "disableEnforceFocus">

function DiscoSideBar(props: DiscoSideBarProps) {
  const {
    testid,
    open = true,
    menuListProps,
    fullHeight = false,
    header,
    items,
    adminActions,
    variant = "persistent",
    classes: customClasses,
    ...rest
  } = props

  const classes = useStyles({ fullHeight, variant })
  const theme = useTheme()

  const menuItems = (
    <MenuList
      data-testid={testid}
      classes={{
        root: classNames(classes.discoSideBar),
      }}
      {...menuListProps}
    >
      {items}
    </MenuList>
  )

  return (
    <Drawer
      variant={variant}
      anchor={"left"}
      open={open}
      PaperProps={{
        elevation: 0,
      }}
      classes={{
        ...customClasses,
        root: classNames(classes.drawerRoot, customClasses?.root),
        paper: classNames(classes.sideBarPaper, customClasses?.paper),
      }}
      style={{
        zIndex: variant === "persistent" ? undefined : theme.zIndex.pageHeader,
      }}
      {...(variant === "temporary" ? { disableEnforceFocus: true } : {})}
      {...rest}
    >
      {header && <div className={classes.headerContainer}>{header}</div>}

      <div className={classes.scrollContainer}>{menuItems}</div>

      {adminActions && <div className={classes.adminActions}>{adminActions}</div>}
    </Drawer>
  )
}

interface StyleProps {
  fullHeight: boolean
  variant: DrawerProps["variant"]
}

const useStyles = makeUseStyles((theme) => ({
  drawerRoot: (props: StyleProps) => ({
    // Stick on scroll
    ...styleIf(props.variant === "persistent", {
      height: "100%",
    }),
  }),
  sideBarPaper: (props: StyleProps) => ({
    position: "static",
    zIndex: theme.zIndex.pageHeader,
    // Temporary drawer-style sidebar
    width: props.variant === "temporary" ? "80vw" : theme.measure.page.sideBarWidth,
    background: theme.palette.background.paper,
    borderRight: "none",
    maxWidth: theme.measure.page.sideBarWidth,
    height: "100%",
  }),
  discoSideBar: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 0,
  },
  adminActions: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginTop: "auto",
    // Makes top border flush with the side bar.
    borderTop: theme.palette.constants.borderSmall,
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    zIndex: theme.zIndex.overlay,
  },
  headerContainer: {
    padding: theme.spacing(2),
    borderBottom: theme.palette.constants.borderSmall,
    minHeight: theme.mixins.toolbar.minHeight,
  },
  scrollContainer: {
    height: "100%",
    overflow: "hidden auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
  },
}))

export default DiscoSideBar
