import makeUseStyles from "@assets/style/util/makeUseStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import CalendarCaption from "@disco-ui/date/custom/CalendarCaption"
import CalendarDay from "@disco-ui/date/custom/CalendarDay"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { DayPicker, DayPickerProps, Matcher } from "react-day-picker"
import defaultDayPickerStyles from "react-day-picker/dist/style.module.css"

type DiscoCalendarProps = DayPickerProps & {
  compact?: boolean
  occupiedDays?: Matcher | Matcher[]
} & TestIDProps

function DiscoCalendar({
  compact,
  testid = "DiscoCalendar",
  occupiedDays = [],
  ...dayPickerProps
}: DiscoCalendarProps) {
  const classes = useStyles({ compact })

  return (
    <DayPicker
      classNames={mergeClasses(defaultDayPickerStyles, classes)}
      fixedWeeks
      components={{
        Caption: (props) => (
          <CalendarCaption testid={testid} {...props} compact={compact} />
        ),
        Day: (props) => <CalendarDay testid={testid} {...props} />,
      }}
      modifiers={{ occupied: occupiedDays }}
      modifiersClassNames={{
        // Custom class names to allow us to target these days easily
        selected: classNames("disco-selected", classes.day_selected),
        range_start: classNames("disco-range-start", classes.day_range_start),
        range_end: classNames("disco-range-end", classes.day_range_end),
        occupied: classNames("disco-occupied", classes.day_occupied),
      }}
      {...dayPickerProps}
    />
  )
}

type StyleProps = {
  compact?: boolean
}

// See classnames used by react-day-picker here: https://react-day-picker.js.org/api/types/StyledElement
// The "div &" nesting is requried to increase specificity to override the default styles
const useStyles = makeUseStyles((theme) => ({
  head_cell: {
    "& span": {
      ...theme.typography["body-xs"],
      ...theme.typography.modifiers.fontWeight[600],
      textTransform: "none",
      color:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[300]
          : theme.palette.groovy.grey[400],
    },
  },
  root: (props: StyleProps) => ({
    "div &": {
      "--rdp-cell-size": props.compact ? "32px" : "48px",
      "--rdp-background-color": theme.palette.primary.light,
      "--rdp-background-color-dark": theme.palette.primary.main,
      "--rdp-accent-color": theme.palette.primary.main,
      margin: 0,
      fontFamily: theme.typography.fontFamily,
      ...theme.typography["body-md"],
    },
  }),
  day: {
    "div &": {
      ...theme.typography["body-sm"],
      ...theme.typography.modifiers.fontWeight[600],
      color: theme.palette.text.primary,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.grey[900],
      },
      display: "flex",
      overflow: "hidden",
      alignItems: "center",
      justifyContent: "center",
      margin: 0,
    },
  },
  day_selected: {
    "div &": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
  },
  day_occupied: {
    "&:after": {
      content: '""',
      display: "block",
      width: "4px",
      height: "4px",
      borderRadius: "50%",
      backgroundColor: () => {
        // If using the default theme
        if (theme.palette.primary.main === theme.palette.groovy.blue[400]) {
          return theme.palette.groovy.blue[300]
        }

        if (theme.palette.type === "dark") {
          return theme.palette.primary.light
        }

        return theme.palette.primary.dark
      },
      position: "absolute",
      bottom: "4px",
      left: "50%",
      transform: "translateX(-50%)",
    },
  },
  day_range_end: {
    "div &": {
      position: "relative",
      "&:before": {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: -1,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: "100%",
        borderRadius: "50% !important",
        height: "100%",
      },
      zIndex: 1,
      borderRadius: "0 !important",
      background: `linear-gradient(270deg, ${theme.palette.background.paper} 50%, ${
        theme.palette.type === "dark"
          ? theme.palette.primary.dark
          : theme.palette.primary.light
      } 60%)  !important`,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
  },
  day_range_middle: {
    "div &, div &:hover": {
      backgroundColor: `${
        theme.palette.type === "dark"
          ? theme.palette.primary.dark
          : theme.palette.primary.light
      } !important`,
      color: `${
        theme.palette.type === "dark" ? undefined : theme.palette.groovy.grey[700]
      } !important`,
    },
  },
  day_range_start: {
    "div &": {
      position: "relative",
      "&:before": {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: -1,
        borderRadius: "50% !important",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: "100%",
        height: "100%",
      },
      zIndex: 1,
      borderRadius: "0 !important",
      background: `linear-gradient(90deg, ${theme.palette.background.paper} 50%, ${
        theme.palette.type === "dark"
          ? theme.palette.primary.dark
          : theme.palette.primary.light
      } 60%) !important`,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
  },
  button: {
    "div &": {
      border: 0,
    },
  },
  day_today: {
    "div &": {
      ...theme.typography.modifiers.fontWeight[600],
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.type === "dark" ? theme.palette.groovy.onDark[700] : undefined,
      /** Don't highlight today's date when selected */
      "&$day_range_start, &$day_range_end, &$day_range_middle, &$day_selected": {
        border: 0,
      },
    },
  },
  caption_label: {
    "div &": {
      ...theme.typography["body-lg"],
      ...theme.typography.modifiers.fontWeight[600],
    },
  },
}))

export default DiscoCalendar
