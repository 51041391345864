import useCustomChartTheme from "@disco-ui/chart/useCustomChartTheme"
import { useTheme } from "@material-ui/core"
import { GlyphCircle } from "@visx/glyph"
import { Axis, GlyphSeries, Grid, LineSeries, Tooltip, XYChart } from "@visx/xychart"
import { RenderTooltipParams } from "@visx/xychart/lib/components/Tooltip"
import { extent, max } from "d3-array"
import { timeFormat, timeParse } from "d3-time-format"
import { memo, ReactNode } from "react"

type Props<D extends object> = {
  width: number
  height: number
  data: D[]
  xAccessor: (d: D) => string
  yAccessor: (d: D) => number
  yScaleDomain?: [number, number]
  renderTooltip?: (
    params: RenderTooltipParams<D>,
    xAccessor: (d: D) => string,
    yAccessor: (d: D) => number
  ) => ReactNode
}

function DiscoLineChart<D extends Record<string, string | number>>({
  width,
  height,
  data,
  xAccessor,
  yAccessor,
  yScaleDomain,
  renderTooltip,
}: Props<D>) {
  const theme = useTheme()
  const customTheme = useCustomChartTheme()

  // Max value in the y-axis
  const yDataMax = max(data.map(yAccessor)) || 0

  return (
    <XYChart
      width={width}
      height={height}
      xScale={{ type: "band" }}
      yScale={{
        type: "linear",
        domain:
          // To get rid of the decimal ticks when the range is relative small (< 5)
          // If the y max value is less than 10, give it a expicit domain of [0, 10]
          // Otherwise, use the d3.extent to get the [min, max] domain
          // ! Max value is 1000
          yScaleDomain || yDataMax < 10
            ? [0, 10]
            : (extent<number>(data.map(yAccessor)) as [number, number]),
      }}
      theme={customTheme}
    >
      <Axis
        orientation={"bottom"}
        tickFormat={(date: string) =>
          timeFormat("%b %d")(timeParse("%Y-%m-%d")(date) as Date)
        }
        // render less ticks on mobile (approximate number)
        numTicks={width <= 400 ? 2 : 7}
        // Control the x-axis label position relative to the axis
        tickLabelProps={() => ({
          y: 30,
        })}
      />

      <Axis
        orientation={"left"}
        // render less ticks on mobile (approximate number)
        numTicks={width <= 400 ? 2 : 5}
        // Control the y-axis label position relative to the axis
        tickLabelProps={() => ({
          x: -15,
        })}
      />
      <Grid columns={false} numTicks={2} />

      <LineSeries
        dataKey={"line"}
        data={data}
        yAccessor={yAccessor}
        xAccessor={xAccessor}
        strokeWidth={3}
      />
      <GlyphSeries
        dataKey={"glyph"}
        data={data}
        yAccessor={yAccessor}
        xAccessor={xAccessor}
        renderGlyph={({ x, y }) => (
          <GlyphCircle
            size={60}
            left={x}
            top={y}
            stroke={theme.palette.text.primary}
            strokeWidth={3}
            fill={theme.palette.background.default}
          />
        )}
      />
      {/** Render the tooltip if the renderTooltip function is provided */}
      {renderTooltip && (
        <Tooltip<D>
          snapTooltipToDatumX
          snapTooltipToDatumY
          showDatumGlyph
          unstyled
          applyPositionStyle
          showSeriesGlyphs
          renderGlyph={({ x, y }) => (
            <GlyphCircle
              size={85}
              left={x}
              top={y}
              stroke={theme.palette.primary.light}
              strokeWidth={3}
              strokeOpacity={0.7}
              fill={theme.palette.primary.main}
            />
          )}
          renderTooltip={(params) => renderTooltip(params, xAccessor, yAccessor)}
        />
      )}
    </XYChart>
  )
}

// Workaround for TS limitation that causes memoized components to lose their generic types
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087
export default memo(DiscoLineChart) as typeof DiscoLineChart
