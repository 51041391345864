import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  ButtonColorStyle,
  DiscoButtonProps,
  useDiscoButtonColorStyle,
} from "@disco-ui/button/DiscoButton"
import DiscoTooltip, { DiscoTooltipProps } from "@disco-ui/tooltip/DiscoTooltip"
import { Button, ButtonGroup } from "@material-ui/core"
import { ClassNameMap, CSSProperties } from "@material-ui/core/styles/withStyles"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import React from "react"

type ButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  content: React.ReactNode
  uniqueKey: string
  TooltipProps?: Pick<DiscoTooltipProps, "content" | "disableHoverListener"> & {
    dataPrivate?: boolean
  }
  testid?: string
  customClass?: string
  selected?: boolean
}

interface DiscoButtonsGroupProps
  extends TestIDProps,
    Pick<DiscoButtonProps, "color" | "disabled"> {
  buttons: ButtonProps[]
  showTooltipsWhileDisabled?: boolean
  height?: CSSProperties["height"]
  variant?: "text" | "outlined" | "contained"
  noPadding?: boolean
  classes?: ClassNameMap<"root">
}

function DiscoButtonsGroup({
  testid = "DiscoButtonsGroup",
  buttons,
  color = "primary",
  variant = "contained",
  disabled,
  showTooltipsWhileDisabled = false,
  // DiscoButton default height
  height = "40px",
  noPadding = false,
  classes: customClasses,
}: DiscoButtonsGroupProps) {
  const discoColor = useDiscoButtonColorStyle(color)
  const classes = useStyles({
    colorStyle: discoColor,
    showTooltipsWhileDisabled,
    variant,
    height,
    noPadding,
  })
  return (
    <ButtonGroup
      classes={{
        root: customClasses?.root,
        grouped: classes.grouped,
        groupedTextHorizontal: classes.groupedTextHorizontal,
        groupedContainedHorizontal: classes.groupedContainedHorizontal,
      }}
      disabled={disabled}
      variant={variant}
      color={"inherit"}
      disableElevation
      data-testid={testid}
    >
      {buttons.map((button) => {
        /**
         * wrapping the button with tooltip breaks the ButtonGroup styles.
         * Instead, wrap the content with a div that occupies full height/width of button
         */
        return (
          <Button
            key={button.uniqueKey}
            data-testid={button.testid || button.uniqueKey}
            classes={{
              root: classNames(classes.root, button.customClass),
            }}
            onClick={button.onClick}
          >
            <DiscoTooltip
              // Always render the tooltip, but only show it if props are passed
              open={button.TooltipProps === undefined ? false : undefined}
              content={button.TooltipProps?.content || ""}
              disableHoverListener={button.TooltipProps?.disableHoverListener}
              dataPrivate={button.TooltipProps?.dataPrivate}
            >
              <div className={classes.contentWrapper}>{button.content}</div>
            </DiscoTooltip>
          </Button>
        )
      })}
    </ButtonGroup>
  )
}
interface StyleProps {
  colorStyle: ButtonColorStyle
  showTooltipsWhileDisabled: boolean
  variant: "text" | "outlined" | "contained"
  height?: CSSProperties["height"]
  noPadding?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  grouped: ({ height }: StyleProps) => ({
    borderRadius: theme.measure.borderRadius.medium,
    height,
    minWidth: "unset",
  }),
  groupedTextHorizontal: {
    borderRightColor: `${theme.palette.constants.divider} !important`,
  },
  groupedContainedHorizontal: ({ colorStyle }: StyleProps) => ({
    borderRightColor: `${colorStyle.border || "inherit"} !important`,
  }),
  root: ({ colorStyle, showTooltipsWhileDisabled, variant }: StyleProps) => ({
    borderWidth: "1.5px !important",
    color: variant === "outlined" ? theme.palette.text.primary : colorStyle.color,
    border: `1.5px solid ${
      variant === "outlined" ? theme.palette.groovy.neutral[300] : colorStyle.border
    }`,
    borderColor:
      variant === "outlined" ? theme.palette.groovy.neutral[300] : colorStyle.border,
    backgroundColor: variant === "outlined" ? "transparent" : colorStyle.backgroundColor,
    padding: 0,
    "&:hover:not(:disabled, .Mui-disabled)": {
      color: variant === "outlined" ? theme.palette.text.primary : colorStyle.hover.color,
      border: `1.5px solid ${colorStyle.hover.border}`,
      position: "relative",
      // raise so the hovered button border is always on top of any sibbling borders
      zIndex: theme.zIndex.raise1,
    },
    "&.Mui-disabled": {
      cursor: "not-allowed",
      pointerEvents: showTooltipsWhileDisabled ? "initial" : "none",
      userSelect: "none",
      opacity: 0.4,
    },
    // Disable the border radius for the first and last button
    "&:first-child $contentWrapper > button": {
      borderTopLeftRadius: theme.measure.borderRadius.medium,
      borderBottomLeftRadius: theme.measure.borderRadius.medium,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "&:last-child $contentWrapper > button": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: theme.measure.borderRadius.medium,
      borderBottomRightRadius: theme.measure.borderRadius.medium,
    },
  }),
  contentWrapper: ({ noPadding }: StyleProps) => ({
    padding: noPadding ? 0 : theme.spacing(1),
    height: "100%",
    display: "flex",
    alignItems: "center",
  }),
}))

export default DiscoButtonsGroup
