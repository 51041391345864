import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import {
  DiscoIcon,
  DiscoIconKinds,
  DiscoIconKindsReactNodeUnion,
  DiscoLink,
  DiscoLinkProps,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import { MenuItem, TooltipProps } from "@material-ui/core"
import classNames from "classnames"
import React, { forwardRef } from "react"

export interface DiscoDropdownItemProps {
  testid?: string
  icon?: DiscoIconKindsReactNodeUnion | React.ReactElement | null
  title?: string | React.ReactElement | null
  subtitle?: string | React.ReactElement | null
  to?: DiscoLinkProps["to"]
  onClick?: React.MouseEventHandler
  onMouseEnter?: React.MouseEventHandler
  selected?: boolean
  className?: string
  rightButton?: React.ReactNode
  target?: string
  children?: React.ReactNode
  tooltip?: React.ReactNode
  tooltipPlacement?: TooltipProps["placement"]
  disabled?: boolean
  component?: React.ElementType
  externalLink?: string
  truncateText?: number
  color?: React.CSSProperties["color"]
  hoverColor?: React.CSSProperties["color"]
  backgroundColor?: React.CSSProperties["backgroundColor"]
  hoverBackgroundColor?: React.CSSProperties["backgroundColor"]
  classes?: {
    icon?: string
    textContainer?: string
  }
}

const DiscoDropdownItem = forwardRef<HTMLLIElement, DiscoDropdownItemProps>(
  (props, ref) => {
    const {
      testid = "DiscoDropdownItem",
      icon,
      title,
      subtitle,
      to,
      onClick,
      onMouseEnter,
      selected,
      className,
      children,
      rightButton,
      target = "_self",
      tooltip,
      tooltipPlacement = "top",
      disabled,
      component,
      truncateText,
      classes: customClasses,
      externalLink = "",
      color,
      hoverColor,
      backgroundColor,
      hoverBackgroundColor,
    } = props

    const classes = useStyles({
      color,
      hoverColor,
      backgroundColor,
      hoverBackgroundColor,
    })

    const content = children || (
      <div className={classes.content}>
        <div className={classes.lhs}>
          {icon && (
            <span className={classNames(classes.icon, customClasses?.icon)}>
              {typeof icon === "string" ? (
                <DiscoIcon icon={icon as DiscoIconKinds} />
              ) : (
                icon
              )}
            </span>
          )}
          <div
            className={classNames(classes.textContainer, customClasses?.textContainer)}
          >
            {typeof title === "string" ? (
              <DiscoText
                variant={subtitle ? "body-md-600" : "body-sm"}
                testid={`${testid}.title`}
                truncateText={truncateText}
              >
                {title}
              </DiscoText>
            ) : (
              title
            )}
            {subtitle && typeof subtitle === "string" ? (
              <DiscoText
                variant={"body-xs-500"}
                color={"text.secondary"}
                className={classes.subtitle}
              >
                {subtitle}
              </DiscoText>
            ) : (
              subtitle
            )}
          </div>
        </div>

        {rightButton && <div className={classes.rightButton}>{rightButton}</div>}
      </div>
    )

    return (
      <DiscoTooltip disabled={!tooltip} content={tooltip} placement={tooltipPlacement}>
        <div
          className={classNames(classes.listItemContainer, {
            [classes.disabled]: disabled,
          })}
        >
          <MenuItem
            ref={ref}
            data-testid={testid}
            className={classNames(classes.listItem, className)}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            selected={selected}
            component={to || externalLink ? DiscoLink : component ?? "li"}
            to={to}
            target={target}
            href={externalLink}
            disabled={disabled}
          >
            {content}
          </MenuItem>
        </div>
      </DiscoTooltip>
    )
  }
)

type StyleProps = {
  color?: React.CSSProperties["color"]
  hoverColor?: React.CSSProperties["color"]
  backgroundColor?: React.CSSProperties["backgroundColor"]
  hoverBackgroundColor?: React.CSSProperties["backgroundColor"]
}

const useStyles = makeUseStyles((theme) => ({
  listItemContainer: {
    width: "100%",
  },
  listItem: ({
    color,
    hoverColor,
    backgroundColor,
    hoverBackgroundColor,
  }: StyleProps) => ({
    width: "100%",
    padding: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: backgroundColor ? `${backgroundColor} !important` : undefined,

    "&:hover": {
      /** Override the hover style if the item is wrapped in a OverridableDiscoButton */
      backgroundColor: hoverBackgroundColor
        ? `${hoverBackgroundColor} !important`
        : theme.palette.type === "dark"
        ? `${theme.palette.groovy.onDark[500]} !important`
        : `${theme.palette.groovy.neutral[200]} !important`,
    },
    "&.Mui-selected:hover": {
      backgroundColor: hoverBackgroundColor ?? theme.palette.groovy.neutral[100],

      "& p": {
        color: hoverColor ?? color ?? theme.palette.groovy.neutral[700],
      },
    },
  }),
  content: ({ color, hoverColor }: StyleProps) => ({
    width: "100%",
    minHeight: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 0,
    "& > a": {
      width: "100%",
    },
    "& p": {
      color:
        color ??
        (theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[200]
          : theme.palette.groovy.neutral[500]),
    },
    "&:hover": {
      "& p": {
        color: hoverColor ?? color ?? theme.palette.groovy.neutral[700],
      },
      "& span > svg": {
        color: hoverColor ?? color ?? theme.palette.groovy.neutral[700],
      },
    },

    "& span > svg": {
      color:
        hoverColor ??
        color ??
        (theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[200]
          : theme.palette.groovy.neutral[500]),
    },
  }),
  disabled: {
    cursor: "not-allowed",
  },
  icon: ({ color }: StyleProps) => ({
    margin: theme.spacing(0, 0, -0.5, 0),
    "& svg": {
      width: 24,
      height: 24,

      ...styleIf(color, {
        color,
      }),
    },
  }),
  lhs: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    width: "100%",
    minWidth: 0,
  },
  subtitle: {
    // enable line wrap
    whiteSpace: "normal",
  },
  rightButton: {
    display: "inline-flex",
    alignItems: "center",
  },
  textContainer: {
    width: "100%",
  },
}))

export default DiscoDropdownItem
