/**
 * @generated SignedSource<<56a9fcfe99f717a1b838a854fb99b9ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductAppKind = "content" | "link" | "curriculum" | "events" | "members" | "resources" | "slack" | "collection" | "product_link" | "organization_events" | "organization_members" | "chat_channel" | "posts" | "nav_folder" | "%future added value";
export type ProductAppStatus = "active" | "inactive" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductAppListItemChildFragment$data = {
  readonly id: string;
  readonly kind: ProductAppKind;
  readonly status: ProductAppStatus;
  readonly customAppTitle: string | null;
  readonly customAppUrl: string | null;
  readonly badge: {
    readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
  };
  readonly product: {
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  } | null;
  readonly feed: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly collection: {
    readonly id: string;
  } | null;
  readonly contentUsage: {
    readonly id: string;
  } | null;
  readonly chatChannel: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"RemoveAppButtonFragment" | "ToggleAppStatusButtonFragment" | "ContentAppSettingsButtonFragment" | "LinkAppSettingsButtonFragment" | "FeedAppSettingsButtonFragment" | "GenericAppSettingsButtonFragment" | "ChatChannelSettingsModalButtonFragment" | "ChatChannelSideBarItemFragment" | "DeleteChatChannelAppButtonFragment" | "MembersAppSettingsButtonFragment" | "NavFolderSettingsButtonFragment" | "DeleteNavFolderButtonFragment">;
  readonly " $fragmentType": "ProductAppListItemChildFragment";
};
export type ProductAppListItemChildFragment$key = {
  readonly " $data"?: ProductAppListItemChildFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductAppListItemChildFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductAppListItemChildFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BadgeFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "usePermissionsFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoveAppButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ToggleAppStatusButtonFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Feed",
      "kind": "LinkedField",
      "name": "feed",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Collection",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentUsage",
      "kind": "LinkedField",
      "name": "contentUsage",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatChannel",
      "kind": "LinkedField",
      "name": "chatChannel",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentAppSettingsButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LinkAppSettingsButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedAppSettingsButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GenericAppSettingsButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatChannelSettingsModalButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatChannelSideBarItemFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteChatChannelAppButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MembersAppSettingsButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavFolderSettingsButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteNavFolderButtonFragment"
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};
})();

(node as any).hash = "46c89ff7915b53a9bd5f34d7ea13bd23";

export default node;
