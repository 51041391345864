/**
 * @example 4 => 3
 * @example 3 => 3
 * @example 0 => 1
 * @example 3.5 => 3
 * @example 4.5 => 3
 */
export function roundDownToNearestOdd(num: number): number {
  const n = Math.floor(num)
  return Math.max(n % 2 === 0 ? n - 1 : n, 1)
}
