import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import CountBadge, { CountBadgeProps } from "@components/square-count-badge/CountBadge"
import NotificationBadge, {
  NotificationBadgeProps,
} from "@components/square-count-badge/NotificationBadge"
import { DiscoIconKinds, DiscoLink, DiscoLinkProps, DiscoTextButton } from "@disco-ui"
import { darken, useTheme } from "@material-ui/core"
import classNames from "classnames"
import { JssStyle } from "jss"
import { ReactNode, forwardRef } from "react"

export interface DiscoTabProps
  extends Pick<DiscoLinkProps, "children">,
    Pick<
      React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
      "onMouseOver" | "onMouseLeave"
    > {
  testid: string
  to?: DiscoLinkProps["to"]
  externalLink?: string
  onClick?: () => void
  active?: boolean
  className?: string
  variant?: "default" | "blue-background" | "grey-track"
  size?: "small" | "default"
  notificationConfig?: NotificationBadgeProps["config"]
  rightContent?: ReactNode
  leftIcon?: React.ReactElement | DiscoIconKinds
  notifications?: number
  count?: number
  disabled?: boolean
  activeColor?: string
  countBadgeProps?: Partial<CountBadgeProps>
}

const DiscoTab = forwardRef<HTMLDivElement, DiscoTabProps>(
  (
    {
      testid,
      children,
      to,
      externalLink,
      onClick,
      active = false,
      className,
      notificationConfig,
      variant = "default",
      size = "default",
      rightContent,
      leftIcon,
      notifications,
      count,
      disabled,
      activeColor,
      countBadgeProps,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles({
      active,
      variant,
      size,
      activeColor,
    })
    const theme = useTheme()

    return (
      <div
        ref={ref}
        data-testid={testid}
        className={classNames(classes.tabContainer, className)}
        {...rest}
      >
        {renderContent()}
        {rightContent}
      </div>
    )

    function renderContent() {
      if (to || externalLink) {
        return (
          <DiscoLink
            className={classes.text}
            to={to || externalLink}
            target={externalLink ? "_blank" : undefined}
          >
            {children}
            {renderCountBadge()}
          </DiscoLink>
        )
      }

      if (typeof children === "string") {
        return (
          <DiscoTextButton
            disabled={disabled}
            className={classes.text}
            leftIcon={leftIcon}
            iconSize={variant === "grey-track" ? 24 : 20}
            onClick={onClick}
            variant={variant === "grey-track" ? "primary" : "currentColor"}
          >
            {children}
            {renderCountBadge()}
          </DiscoTextButton>
        )
      }

      return children
    }

    function renderCountBadge() {
      if (notificationConfig)
        return (
          <NotificationBadge
            className={classes.countBadge}
            testid={testid}
            config={notificationConfig}
            aria-label={"Unread notifications"}
            {...countBadgeProps}
          />
        )

      if (notifications)
        return (
          <CountBadge
            className={classes.countBadge}
            testid={testid}
            count={notifications}
            {...countBadgeProps}
          />
        )
      return (
        count !== undefined && (
          <CountBadge
            className={classes.countBadge}
            testid={testid}
            count={count}
            maxCount={-1}
            size={20}
            color={active ? theme.palette.primary.main : theme.palette.text.primary}
            backgroundColor={
              active
                ? theme.palette.type === "dark"
                  ? darken(theme.palette.primary.dark, 0.5)
                  : theme.palette.primary.light
                : theme.palette.groovy.neutral[100]
            }
            {...countBadgeProps}
          />
        )
      )
    }
  }
)

type StyleProps = {
  active: boolean
  variant: DiscoTabProps["variant"]
  size: DiscoTabProps["size"]
  activeColor?: string
}

const useStyles = makeUseStyles((theme) => ({
  tabContainer: ({ active, variant, activeColor }: StyleProps) =>
    ({
      margin: theme.spacing(0, 1),
      alignItems: "center",
      display: "flex",
      // ACTIVE styles
      position: "relative",
      ...styleIf(variant === "default", {
        whiteSpace: "nowrap",

        "&:after": active
          ? {
              content: "''",
              position: "absolute",
              bottom: "0px",
              left: 0,
              width: "100%",
              height: "4px",
              borderRadius: "4px 4px 0 0",
              backgroundColor: activeColor || theme.palette.primary.main,
            }
          : { content: "none" },
      }),
      ...styleIf(variant === "blue-background" && active, {
        backgroundColor: "#EAF1FF",
        borderRadius: theme.measure.borderRadius.big,
      }),
      ...styleIf(variant === "grey-track", {
        ...styleIf(active, {
          backgroundColor:
            theme.palette.type === "light"
              ? theme.palette.common.white
              : theme.palette.background.paper,
        }),
        margin: 0,
        borderRadius: theme.measure.borderRadius.medium,
        boxShadow: active ? theme.palette.groovyDepths.insideCard : "none",
      }),
    } as JssStyle),
  text: ({ variant, active, activeColor, size }: StyleProps) => {
    return {
      display: "flex",
      alignItems: "center",
      minWidth: "unset",
      flexGrow: 1,
      justifyContent: "center",
      "&, & > span, &:visited": {
        ...theme.typography["body-sm"],
        ...theme.typography.modifiers.fontWeight[600],
        ...styleIf(variant === "grey-track", {
          padding: theme.spacing(0.75, size === "small" ? 0.75 : 1.5),
          ...theme.typography.modifiers.fontWeight[500],
          color:
            theme.palette.type === "light"
              ? active
                ? theme.palette.text.primary
                : theme.palette.groovy.grey[400]
              : active
              ? theme.palette.common.white
              : theme.palette.groovy.neutral[600],
        }),
        ...styleIf(variant === "blue-background", {
          padding: theme.spacing(1.5),
        }),
        ...styleIf(variant === "default", {
          "&:not(span)": {
            padding: theme.spacing(1.5, 0),
          },
          // Extra :not(:hover) is to increase specificity over base DiscoLink
          "&:not(:hover)": {
            color: active
              ? activeColor || theme.palette.primary.main
              : theme.palette.text.secondary,
          },
          "&:hover": {
            color: active
              ? activeColor || theme.palette.primary.main
              : theme.palette.text.secondary,
          },
        }),
      },
    }
  },
  countBadge: ({ variant }: StyleProps) => ({
    // Add negative vertical margin so it doesn't make the tab taller when present
    margin: theme.spacing(-0.5, 0, -0.5, variant === "grey-track" ? 0.5 : 1),
  }),
}))

export default DiscoTab
