import AddIcon from "@/core/ui/iconsax/linear/add.svg"
import MinusIcon from "@/core/ui/iconsax/linear/minus.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import DiscoText from "@disco-ui/text/DiscoText"
import { IconButton } from "@material-ui/core"
import classNames from "classnames"

interface CounterProps {
  testid: string
  value: number
  onChange: (value: number) => void
  isDisabled?: boolean
  min?: number
  max?: number
  step?: number
  className?: string
}

function Counter({
  testid,
  value,
  onChange,
  min = 0,
  max,
  step = 1,
  isDisabled,
  className,
}: CounterProps) {
  const isDecrementButtonDisabled = isDisabled || value === min
  const isIncrementButtonDisabled = isDisabled || value === max
  const classes = useStyles()

  return (
    <div className={classNames(classes.root, className)}>
      <IconButton
        data-testid={`${testid}.decrement-button`}
        classes={{
          root: classes.iconButton,
        }}
        onClick={decrementValue}
        disabled={isDecrementButtonDisabled}
        size={"small"}
      >
        <MinusIcon width={24} height={24} />
      </IconButton>

      <DiscoText variant={"body-sm"} data-testid={`${testid}.value`}>
        {value}
      </DiscoText>

      <IconButton
        data-testid={`${testid}.decrement-button`}
        classes={{
          root: classes.iconButton,
        }}
        onClick={incrementValue}
        disabled={isIncrementButtonDisabled}
        size={"small"}
      >
        <AddIcon width={24} height={24} />
      </IconButton>
    </div>
  )

  function decrementValue() {
    onChange(value - step)
  }

  function incrementValue() {
    onChange(value + step)
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "grid",
    alignItems: "center",
    justifyItems: "center",
    gridTemplateColumns: "40px 1fr 40px",
    gap: "16px",
    height: "48px",
    border: `2px solid ${theme.palette.groovy.neutral[100]}`,
    borderRadius: theme.measure.borderRadius.default,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  iconButton: {
    borderRadius: theme.measure.borderRadius.default,
    color: theme.palette.text.primary,
  },
}))

export default Counter
