import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import MediaLibraryButton from "@/media/add/MediaLibraryButton"
import { DEFAULT_DARK_THEME } from "@assets/style/appMuiTheme"
import styleIf from "@assets/style/util/styleIf"
import FileDropzone, {
  FileDropzoneProps,
  IMAGE_FILE_TYPES,
} from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import DiscoButton from "@disco-ui/button/DiscoButton"
import { useTheme } from "@material-ui/core"
import { CSSProperties } from "@material-ui/core/styles/withStyles"
import classnames from "classnames"
import { Accept } from "react-dropzone"

interface Props
  extends Partial<
      Pick<FileDropzoneProps, "cropperProps" | "message" | "suggestedDimensions">
    >,
    StyleProps {
  testid?: string
  value: string | null | undefined
  onChange(result: MediaResult | null): void
  accept?: Accept
  disableRemove?: boolean
  alterAspectRatio?: boolean
  squareAspectRatio?: boolean
  maxContainerWidth?: number | null
  darkBackground?: boolean
  classes?: {
    container?: string
    emptyState?: string
  }
  disabled?: boolean
  showIcons?: boolean
}

/**
 * When an image is uploaded inside a form, this component
 * provides an empty state drag/drop area and, once an image is uploaded,
 * a preview of the image with Remove/Change buttons
 */
function DiscoImageFormField(props: Props) {
  const {
    value,
    onChange,
    cropperProps,
    suggestedDimensions,
    message,
    accept = IMAGE_FILE_TYPES,
    maxWidth,
    maxHeight,
    dropzoneMaxWidth,
    maxContainerWidth,
    testid,
    disableRemove = false,
    alterAspectRatio = true,
    squareAspectRatio = false,
    disabled,
    showIcons = false,
    darkBackground = false,
  } = props

  const classes = useStyles({
    maxWidth,
    maxHeight,
    dropzoneMaxWidth,
    alterAspectRatio,
    squareAspectRatio,
    maxContainerWidth,
    darkBackground,
    showIcons,
  })
  const theme = useTheme()

  if (!value) {
    return (
      <FileDropzone
        testid={testid}
        dropzoneOptions={{ accept }}
        onUpload={onChange}
        onMediaSelect={onChange}
        suggestedDimensions={suggestedDimensions}
        message={message}
        cropperProps={cropperProps}
        className={classnames(classes.dropzone, props.classes?.emptyState)}
        allowedFileTypes={["image"]}
        disabled={disabled}
      />
    )
  }

  return (
    <div
      data-testid={testid ? `${testid}.wrapper` : ""}
      className={props.classes?.container}
    >
      <div className={classes.imageContainer}>
        <img
          src={value}
          alt={"uploaded"}
          className={classes.image}
          data-testid={testid ? `${testid}.preview` : ""}
        />
        <div className={classes.buttonsContainer}>
          {!disableRemove &&
            (showIcons ? (
              <DiscoButton
                testid={`${testid}.remove`}
                color={"grey"}
                variant={"outlined"}
                width={"40px"}
                onClick={() => onChange(null)}
                leftIcon={"trash"}
                customButtonColor={{
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.primary.contrastText,
                  border: theme.palette.groovy.neutral[200],
                  hover: {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.primary.contrastText,
                  },
                }}
              />
            ) : (
              <DiscoButton
                testid={`${testid}.remove`}
                color={"grey"}
                variant={"outlined"}
                onClick={() => onChange(null)}
              >
                {"Remove"}
              </DiscoButton>
            ))}
          <MediaLibraryButton
            className={classes.changeButton}
            onUpload={onChange}
            onMediaSelect={onChange}
            cropperProps={cropperProps}
            suggestedDimensions={suggestedDimensions}
            dropzoneOptions={{ accept: IMAGE_FILE_TYPES }}
            allowedFileTypes={["image"]}
            PopoverProps={{
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }}
            disabled={disabled}
          >
            {(btnprops) =>
              showIcons ? (
                <DiscoButton
                  {...btnprops}
                  className={classes.changeButton}
                  testid={`${testid}.change`}
                  variant={"outlined"}
                  leftIcon={"pencil"}
                  width={"40px"}
                  customButtonColor={{
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.primary.contrastText,
                    border: theme.palette.groovy.neutral[200],
                    hover: {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ) : (
                <DiscoButton
                  {...btnprops}
                  className={classes.changeButton}
                  testid={`${testid}.change`}
                >
                  {"Change"}
                </DiscoButton>
              )
            }
          </MediaLibraryButton>
        </div>
      </div>
    </div>
  )
}

export default DiscoImageFormField

interface StyleProps {
  maxWidth?: CSSProperties["maxWidth"]
  maxHeight?: CSSProperties["maxHeight"]
  dropzoneMaxWidth?: number
  alterAspectRatio?: boolean
  squareAspectRatio?: boolean
  maxContainerWidth?: number | null
  darkBackground?: boolean
  showIcons?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  imageContainer: (props: StyleProps) => ({
    ...styleIf(props.maxContainerWidth, {
      display: "flex",
      justifyContent: "center",
      width: props.maxContainerWidth || "100%",
      height: "auto",
      backgroundColor: props.darkBackground
        ? DEFAULT_DARK_THEME.backgroundColor
        : theme.palette.groovy.neutral[100],
      padding: theme.spacing(2.5),
      borderRadius: theme.spacing(1.5),
      border: `1px solid ${theme.palette.groovy.neutral[200]}`,
    }),
    ...styleIf(props.showIcons, {
      position: "relative",
      "&:hover $image": {
        opacity: 0.5,
      },
      "&:hover $buttonsContainer": {
        display: "flex",
      },
    }),
  }),

  dropzone: (props: StyleProps) => ({
    maxWidth: props.dropzoneMaxWidth || "100%",
  }),
  image: (props: StyleProps) => ({
    maxWidth: props.maxWidth || "100%",
    maxHeight: props.maxHeight || "200px",
    width: "auto",
    height: "auto",
    borderRadius: theme.spacing(1.5),
    border: props.maxContainerWidth
      ? "none"
      : `1px solid ${theme.palette.groovy.neutral[200]}`,
    ...styleIf(props.squareAspectRatio, {
      width: props.maxWidth,
      height: props.maxWidth,
    }),
    ...styleIf(!props.alterAspectRatio, {
      objectFit: "contain",
    }),
  }),
  buttonsContainer: (props: StyleProps) => ({
    display: "flex",
    ...styleIf(props.showIcons, {
      display: "none",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }),
    gap: theme.spacing(1),
  }),
  changeButton: {
    height: "40px",
  },
}))
