import AddIcon from "@/core/ui/iconsax/linear/add.svg"
import AddCircleIcon from "@/core/ui/iconsax/linear/add-circle.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { DiscoButtonProps, DiscoIconButton, DiscoTooltip } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { ReactNode, useState } from "react"

type Props = TestIDProps &
  Pick<DiscoButtonProps, "onClick"> & {
    tooltip?: ReactNode
    marginTop?: number
    marginBottom?: number
    marginLeft?: number
    marginRight?: number
    showOnHover?: boolean
    showDividerOnHover?: boolean
    borderWidth?: string
    disabled?: boolean
    buttonRef?: React.MutableRefObject<HTMLButtonElement | null>
    color?: string
    variant?: "default" | "vertical"
  }

export default function DiscoDividerButton(props: Props) {
  const defaultMargin = props.variant === "vertical" ? 0.5 : 1
  const {
    onClick,
    tooltip,
    testid = "DiscoDividerButton",
    marginTop = defaultMargin,
    marginBottom = defaultMargin,
    marginLeft = 0,
    marginRight = 0,
    showDividerOnHover = false,
    borderWidth = "2px",
    disabled = false,
    buttonRef,
    color,
    variant = "default",
  } = props
  const showOnHover = props.showOnHover || showDividerOnHover
  const [isHovered, setIsHovered] = useState(false)
  const classes = useStyles({
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    borderWidth,
    color,
    variant,
  })
  const appTheme = useTheme()
  const showOnHoverClasses = useShowOnHoverStyles()

  return (
    <div
      className={classNames(classes.row, showOnHover && showOnHoverClasses.hoverable, {
        [classes.buttonHovered]: !disabled && isHovered,
      })}
      data-testid={`${testid}.divider`}
    >
      {renderPartialBorder()}
      {(!showOnHover || !disabled) && (
        <DiscoTooltip content={tooltip} disabled={!isHovered}>
          <DiscoIconButton
            ref={(e) => (buttonRef ? (buttonRef.current = e) : null)}
            testid={testid}
            className={classNames(
              classes.iconButton,
              showOnHover && showOnHoverClasses.showable
            )}
            color={
              color ||
              (appTheme.palette.type === "dark"
                ? appTheme.palette.groovy.onDark[300]
                : appTheme.palette.constants.divider)
            }
            onClick={onClick}
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            disabled={disabled}
          >
            {isHovered ? (
              <AddIcon className={classes.hoveredIcon} />
            ) : (
              <AddCircleIcon className={classes.icon} />
            )}
          </DiscoIconButton>
        </DiscoTooltip>
      )}
      {renderPartialBorder()}
      {showOnHover && !showDividerOnHover && (
        <div
          className={classNames(
            classes.border,
            showOnHoverClasses.hidable,
            classes.fullBorder
          )}
        />
      )}
    </div>
  )

  function renderPartialBorder() {
    if (showDividerOnHover && disabled) return null
    return (
      <div
        className={classNames(classes.border, {
          [classes.highlightedBorder]: !disabled && isHovered,
          [showOnHoverClasses.showable]: showDividerOnHover,
        })}
      />
    )
  }
}

type StyleProps = {
  marginTop: number
  marginBottom: number
  marginLeft: number
  marginRight: number
  borderWidth: string
  color?: string
  variant: "default" | "vertical"
}

const useStyles = makeUseStyles((theme) => ({
  row: ({
    borderWidth,
    color = theme.palette.constants.divider,
    variant,
    ...props
  }: StyleProps) => ({
    position: "relative",
    height: variant === "vertical" ? "44px" : "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(
      props.marginTop,
      props.marginRight,
      props.marginBottom,
      props.marginLeft
    ),
    ...styleIf(variant === "vertical", {
      "&::before, &::after": {
        content: "''",
        borderLeft: `${borderWidth} solid ${color}`,
        borderRadius: "2px",
        height: "8px",
        position: "absolute",
      },
      "&::before": {
        top: 0,
      },
      "&::after": {
        bottom: 0,
      },
    }),
  }),
  buttonHovered: ({ variant }: StyleProps) => ({
    ...styleIf(variant === "vertical", {
      "&::before, &::after": {
        borderColor: theme.palette.primary.main,
      },
    }),
  }),
  iconButton: {
    padding: theme.spacing(0, 0.5),
    "&:hover": {
      backgroundColor: "transparent",
      "& svg": {
        fill: theme.palette.primary.main,
        "& path": {
          color: theme.palette.common.white,
        },
      },
    },
  },
  border: ({
    borderWidth,
    color = theme.palette.constants.divider,
    variant,
  }: StyleProps) => ({
    borderBottom: variant === "vertical" ? "none" : `${borderWidth} solid ${color}`,
    width: "100%",
    borderRadius: "2px",
  }),
  highlightedBorder: ({ borderWidth, variant }: StyleProps) => ({
    borderBottom:
      variant === "vertical"
        ? "none"
        : `${borderWidth} solid ${theme.palette.primary.main}`,
    borderRadius: "2px",
    width: "100%",
  }),
  fullBorder: {
    position: "absolute",
  },
  icon: {
    width: "100%",
    minWidth: "24px",
    margin: theme.spacing(0),
  },
  hoveredIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    padding: theme.spacing(0.5),
    "& svg": {
      "& path": {
        color: theme.palette.primary.contrastText,
      },
    },
  },
}))
