import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import usePermissions from "@utils/hook/usePermissions"

export function useTranscriptionPermissions() {
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()
  const permissions = usePermissions(activeProduct || activeOrganization)

  return {
    canManage: permissions.has("asset_transcriptions.manage"),
    canView: permissions.has("asset_transcriptions.read"),
  }
}

export default useTranscriptionPermissions
