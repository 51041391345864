import useSearchHighlight from "@/apps/util/hooks/useSearchHighlight"
import { typographyStyles } from "@assets/style/util/makeTypographyStyles"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useTruncateStyles from "@assets/style/util/useTruncateStyles"
import { DiscoTextProps, GroovyTextColorKind } from "@disco-ui/text/DiscoText"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { get } from "lodash"

type DiscoSearchTextProps = TestIDProps &
  Pick<DiscoTextProps, "variant" | "color" | "truncateText"> & {
    text: string
    className?: string
  }

function DiscoSearchText({
  testid = "DiscoSearchText",
  text,
  className,
  variant = "body-md",
  color,
  truncateText,
  ...rest
}: DiscoSearchTextProps) {
  const classes = useStyles({ variant, color })
  const truncateClasses = useTruncateStyles({
    width: "100%",
    numberOfLines: truncateText,
  })
  const { highlight } = useSearchHighlight()

  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: highlight(text) }}
      data-testid={testid}
      {...rest}
      className={classNames(classes.text, className, {
        [truncateClasses.truncateMultipleLines]: truncateText && truncateText > 1,
        [truncateClasses.truncateLine]: truncateText && truncateText === 1,
      })}
    />
  )
}

type StyleProps = {
  variant: DiscoTextProps["variant"]
  color?: GroovyTextColorKind
}

const useStyles = makeUseStyles((theme) => ({
  text: ({ variant, color }: StyleProps) => {
    const colorValue = color
      ? get(theme.palette, color, theme.palette.text.primary)
      : null

    return {
      ...typographyStyles(theme)[variant!],
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      whiteSpace: "pre",
      color: colorValue || theme.palette.text.primary,

      "& > span": {
        ...typographyStyles(theme)[variant!],
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        whiteSpace: "pre",
        color: colorValue || theme.palette.text.primary,
      },
    }
  },
}))

export default DiscoSearchText
