import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoLink from "@disco-ui/link/DiscoLink"
import { Theme, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { LocationDescriptorObject, LocationState } from "history"
import { MouseEventHandler } from "react"

export interface DiscoCarouselItemProps extends TestIDProps {
  children: React.ReactNode
  groovyDepths?: Exclude<keyof Theme["palette"]["groovyDepths"], "raisedBoxShadow">
  to?: LocationDescriptorObject<LocationState> | string
  onClick?: MouseEventHandler
  className?: string
  raiseOnHover?: boolean
}

export const DiscoCarouselItem = ({
  children,
  to,
  onClick,
  groovyDepths = "insideCard",
  className,
  raiseOnHover = true,
  testid = "DiscoCarouselItem",
}: DiscoCarouselItemProps) => {
  const classes = useStyles({ groovyDepths, raiseOnHover })
  return to ?? onClick ? (
    <DiscoLink
      to={to}
      onClick={onClick}
      className={classNames(classes.container, className)}
      data-testid={testid}
    >
      {children}
    </DiscoLink>
  ) : (
    <div className={classNames(classes.container, className)} data-testid={testid}>
      {children}
    </div>
  )
}

type StyleProps = Pick<DiscoCarouselItemProps, "groovyDepths" | "raiseOnHover">

export function DiscoCarouselItemSkeleton() {
  const theme = useTheme()
  return (
    <Skeleton
      width={"100%"}
      height={"100%"}
      variant={"rect"}
      component={"div"}
      style={{ borderRadius: theme.measure.borderRadius.big }}
    />
  )
}

const useStyles = makeUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,

    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: theme.spacing(3),
    height: "100%",

    textAlign: "left",

    borderRadius: theme.measure.borderRadius.big,
    boxShadow: ({ groovyDepths }: StyleProps) =>
      groovyDepths ? theme.palette.groovyDepths[groovyDepths] : undefined,
    border: theme.palette.constants.borderSmall,
    // set the margin so that the boxShadow doesn't get cut off
    marginTop: ({ groovyDepths, raiseOnHover }: StyleProps) =>
      groovyDepths === "boxShadow" || raiseOnHover
        ? theme.spacing(1)
        : groovyDepths === "insideCard"
        ? theme.spacing(0.5)
        : undefined,
    marginBottom: ({ groovyDepths, raiseOnHover }: StyleProps) =>
      groovyDepths === "boxShadow" || raiseOnHover
        ? theme.spacing(2)
        : groovyDepths === "insideCard"
        ? theme.spacing(0.5)
        : undefined,
    "&:hover": {
      boxShadow: ({ raiseOnHover }: StyleProps) =>
        raiseOnHover ? theme.palette.groovyDepths.boxShadow : undefined,
    },
  },
}))
