import { CuePoint } from "@disco-ui/video/DiscoVideo"
import TranscriptReader from "@disco-ui/video/interactive-player/tabs/transcript-reader/TranscriptReader"
import { InteractivePlayerTranscriptTabContentFragment$key } from "@disco-ui/video/interactive-player/tabs/__generated__/InteractivePlayerTranscriptTabContentFragment.graphql"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

interface Props extends TestIDProps {
  assetTranscriptionKey: InteractivePlayerTranscriptTabContentFragment$key | null
  onTimeChange: (time: number) => void
  activeCuePoint: CuePoint | undefined
}

function InteractivePlayerTranscriptTabContent(props: Props) {
  const { assetTranscriptionKey, activeCuePoint, onTimeChange } = props

  const transcription = useFragment<InteractivePlayerTranscriptTabContentFragment$key>(
    graphql`
      fragment InteractivePlayerTranscriptTabContentFragment on AssetTranscription {
        ...TranscriptReaderFragment
      }
    `,
    assetTranscriptionKey
  )

  return (
    <TranscriptReader
      assetTranscriptionKey={transcription}
      activeCuePoint={activeCuePoint}
      onTimeChange={onTimeChange}
    />
  )
}

export default InteractivePlayerTranscriptTabContent
