import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import {
  DiscoDropdown,
  DiscoDropdownProps,
  DiscoIconButton,
  DiscoIconButtonProps,
  DiscoIconButtonSkeleton,
  DiscoTooltip,
  DiscoTooltipProps,
} from "@disco-ui"
import classNames from "classnames"
import React, { useCallback } from "react"
import MoreIcon from "../../core/ui/iconsax/bold/custom-more.svg"

interface DiscoMoreActionsDropdownProps {
  testid: string
  moreActionsButtonClassName?: string
  dropdownContainerClassName?: string
  top?: number
  rotateIcon?: boolean
  width?: number
  height?: number
  keepMounted?: boolean
  menuButtonProps?: Omit<DiscoIconButtonProps, "onClick" | "className" | "children">
  menuButtonTooltip?: string
  menuButtonTooltipProps?: Omit<DiscoTooltipProps, "content" | "children">
  mountChildrenOnOpen?: boolean
  dropdownProps?: Partial<Omit<DiscoDropdownProps, "ref">>
}

const DiscoMoreActionsDropdown: React.FC<DiscoMoreActionsDropdownProps> = ({
  testid,
  children,
  moreActionsButtonClassName,
  dropdownContainerClassName,
  top,
  rotateIcon,
  width,
  height,
  keepMounted,
  menuButtonProps,
  menuButtonTooltip,
  menuButtonTooltipProps,
  mountChildrenOnOpen = false,
  dropdownProps,
}) => {
  const classes = useStyles({ width, height })

  return (
    <DiscoDropdown
      {...dropdownProps}
      menuClasses={{ paper: dropdownContainerClassName }}
      vertical={top}
      testid={testid}
      keepMounted={keepMounted}
      mountChildrenOnOpen={mountChildrenOnOpen}
      menuButton={useCallback(
        ({ onClick, className }) => {
          const icon = (
            <DiscoIconButton
              testid={`${testid}.MoreActionsButton`}
              className={classNames(
                classes.moreActionsButton,
                moreActionsButtonClassName,
                className
              )}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onClick(e)
              }}
              size={"small"}
              {...menuButtonProps}
            >
              <MoreIcon
                className={classNames(classes.moreIcon, {
                  [classes.rotateIcon]: rotateIcon,
                })}
              />
            </DiscoIconButton>
          )
          return (
            <DiscoTooltip
              disabled={!menuButtonTooltip}
              content={menuButtonTooltip || "More actions"}
              {...menuButtonTooltipProps}
            >
              {icon}
            </DiscoTooltip>
          )
        },
        [
          menuButtonTooltip,
          moreActionsButtonClassName,
          menuButtonTooltipProps,
          menuButtonProps,
          rotateIcon,
          testid,
          classes.rotateIcon,
          classes.moreActionsButton,
          classes.moreIcon,
        ]
      )}
    >
      {children}
    </DiscoDropdown>
  )
}

type StyleProps = {
  width?: number
  height?: number
}

const useStyles = makeUseStyles({
  rotateIcon: {
    transform: "rotate(90deg)",
  },
  moreActionsButton: ({ width, height }: StyleProps) => ({
    width: width ?? "30px",
    height: height ?? "30px",
  }),
  moreIcon: {
    width: "16px !important",
    height: "16px !important",
  },
})

export default DiscoMoreActionsDropdown

export function DiscoMoreActionsDropdownSkeleton() {
  return <DiscoIconButtonSkeleton width={30} height={30} />
}
