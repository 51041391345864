import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import { CircularProgress, CircularProgressProps, Theme } from "@material-ui/core"
import { CSSProperties } from "@material-ui/core/styles/withStyles"

export interface DiscoSpinnerProps
  extends Omit<CircularProgressProps, "color" | "size" | "classes"> {
  customSpinnerClasses?: CircularProgressProps["classes"]
  fullWidth?: boolean
  alignLeft?: boolean
}

const SpinnerSizes = {
  xs: "16px",
  sm: "20px",
  md: "40px",
  lg: "60px",
  xl: "120px",
}

const DEFAULT_SIZE = "md"

function DiscoSpinner({
  color,
  size = DEFAULT_SIZE,
  customSpinnerClasses,
  absoluteCenter,
  fullWidth = true,
  alignLeft,
  ...props
}: DiscoSpinnerProps & DiscoSpinnerStyleProps) {
  const classes = useStyles({ color, size, absoluteCenter, fullWidth, alignLeft })
  return (
    <div className={classes.discoSpinnerContainer}>
      <CircularProgress
        classes={mergeClasses({ root: classes.root }, customSpinnerClasses)}
        style={{
          width: SpinnerSizes[size],
          height: SpinnerSizes[size],
        }}
        // When spinner only displays for a moment the shrinking animation makes it invisible
        disableShrink
        {...props}
      />
    </div>
  )
}

interface DiscoSpinnerStyleProps {
  color?: CSSProperties["color"]
  size?: keyof typeof SpinnerSizes
  absoluteCenter?: boolean
  fullWidth?: boolean
  alignLeft?: boolean
}

const useStyles = makeUseStyles((theme: Theme) => ({
  discoSpinnerContainer: (props: DiscoSpinnerStyleProps) =>
    props.absoluteCenter
      ? {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }
      : props.alignLeft
      ? {}
      : {
          display: "flex",
          width: props.fullWidth ? "100%" : "unset",
        },
  root: (props: DiscoSpinnerStyleProps) => ({
    color: props.color || theme.palette.primary.main,
    position: "relative",
    margin: "auto",
  }),
}))

export default DiscoSpinner
