import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { TextField, TextFieldProps } from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import styleIf from "../../core/ui/style/util/styleIf"
import useTruncateStyles from "../../core/ui/style/util/useTruncateStyles"

export type DiscoTextFieldProps = Omit<TextFieldProps, "classes" | "variant"> & {
  disableMultilineBorder?: boolean
  variant?: TextFieldProps["variant"]
}

/** Reserved keys that we want the KeyDown event to propagate on */
const RESERVED_KEYS = [
  /** submit form / create option in a multi-select */
  "Enter",
  /** remove option in a multi-select */
  "Backspace",
  /** navigate between select options in a dropdown */
  "ArrowUp",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
]

const DiscoTextField: React.FC<DiscoTextFieldProps> = (props) => {
  const { InputProps, InputLabelProps, onKeyDown, variant = "filled", ...rest } = props
  const textFieldClasses = useTextFieldStyles()
  const inputClasses = useInputStyles(props)
  const labelClasses = useLabelClasses()

  // Truncate long label text
  const labelTruncateClasses = useTruncateStyles({
    width: "calc(100% - 24px)",
  })

  return (
    <TextField
      variant={variant as any}
      classes={textFieldClasses}
      InputProps={{
        classes: inputClasses,
        disableUnderline: true,
        ...InputProps,
      }}
      InputLabelProps={{
        classes: {
          ...labelClasses,
          root: classNames(labelClasses.root, labelTruncateClasses.truncateLine),
        },
        ...InputLabelProps,
      }}
      onKeyDown={(e) => {
        if (!RESERVED_KEYS.includes(e.key)) {
          // Prevent the KeyDown event from propagating, this fixes a weird MUI issue
          // where pressing certain key would focus the text field instead of inputting the key
          e.stopPropagation()
        }
        if (onKeyDown) {
          onKeyDown(e)
        }
      }}
      {...rest}
    />
  )
}

type StyleProps = Pick<
  DiscoTextFieldProps,
  "multiline" | "label" | "disableMultilineBorder"
>

const useTextFieldStyles = makeUseStyles({
  root: {
    width: "100%",
  },
})

const useLabelClasses = makeUseStyles({
  root: {
    top: "-8px",
  },
})

const useInputStyles = makeUseStyles((theme) => ({
  root: (props: StyleProps) => ({
    width: "100%",
    border: "2px solid transparent",
    ...styleIf(!props.multiline, {
      height: "40px",
    }),
    ...styleIf(props.multiline && !props.disableMultilineBorder, {
      // Remove extra top padding for multiline w/o label
      ...styleIf(!props.label, {
        paddingTop: "12px",
      }),
    }),
    overflow: "hidden",
    color: theme.palette.text.primary,
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[500]
        : theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.big,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&.Mui-focused": {
      backgroundColor: theme.palette.background.paper,
      boxShadow:
        theme.palette.type === "dark"
          ? `0 0 0 2px ${theme.palette.primary.main}`
          : `0 0 0 2px ${theme.palette.primary.main}, 0 0 0 5px ${theme.palette.primary.light}`,
    },
    "&:hover:not(.Mui-focused)": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[400]
          : theme.palette.groovy.neutral[200],
      boxShadow: `0 0 0 1px ${theme.palette.groovy.grey[400]}`,
    },
  }),
  focused: {
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: "none",
  },
  error: {
    border: `2px solid ${theme.palette.error.main}`,
  },
}))

export default DiscoTextField
