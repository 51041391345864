import DiscoTextButton from "@disco-ui/button/DiscoTextButton"
import DiscoText from "@disco-ui/text/DiscoText"
import DiscoTooltip from "@disco-ui/tooltip/DiscoTooltip"

interface Props {
  usePlace: string
  macros: string[]
}

function DiscoMacrosTooltip(props: Props) {
  const { macros, usePlace } = props

  return (
    <DiscoTooltip
      interactive
      content={
        <div>
          <DiscoText component={"p"} variant={"body-xs-700"} marginBottom={1}>
            {`You can use macros in ${usePlace} and they will be replaced with the appropriate value:`}
          </DiscoText>
          {macros.map((macro) => (
            <DiscoText key={macro} component={"p"} variant={"body-xs"}>
              {`• {{ ${macro} }}`}
            </DiscoText>
          ))}
        </div>
      }
    >
      <DiscoTextButton leftIcon={"magic-wand"}>{"Macros"}</DiscoTextButton>
    </DiscoTooltip>
  )
}

export default DiscoMacrosTooltip
