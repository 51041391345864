import { ActiveAppModalProvider } from "@/apps/util/activeAppModalContext"
import { ArrayElement } from "@/core/context/NotificationsContext"
import { ManageAppsButtonProps } from "@/experience/admin/dropdown/manage-apps/ManageAppsButton"
import ProductAppListItem from "@/experience/admin/dropdown/manage-apps/ProductAppListItem"
import {
  ManageAppsModalFormQuery,
  ManageAppsModalFormQuery$data,
} from "@/experience/admin/dropdown/manage-apps/__generated__/ManageAppsModalFormQuery.graphql"
import useRenderProductAppSettingsButtons from "@/experience/admin/dropdown/manage-apps/useRenderProductAppSettingsButtons"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { DiscoSideBarItemSkeleton, DiscoText } from "@disco-ui"
import { range } from "@utils/array/arrayUtils"
import { graphql, useLazyLoadQuery } from "react-relay"

type App = ArrayElement<
  NonNullable<
    NonNullable<
      ManageAppsModalFormQuery$data["product"] & { __typename: "Product" }
    >["productApps"]
  >["edges"]
>["node"]

function ManageAppsModalForm({ productId }: Pick<ManageAppsButtonProps, "productId">) {
  const testid = "ManageAppsModalForm"

  const { product } = useLazyLoadQuery<ManageAppsModalFormQuery>(
    graphql`
      query ManageAppsModalFormQuery($id: ID!) {
        product: node(id: $id) {
          ... on Product {
            __typename
            id
            productApps {
              edges {
                node {
                  id
                  kind
                  customAppTitle
                  feed {
                    id
                    name
                  }
                  collection {
                    id
                  }
                  contentUsage {
                    id
                  }
                  ...ProductAppListItemFragment
                  ...useRenderProductAppSettingsButtonsFragment @relay(mask: false)
                }
              }
            }
          }
        }
      }
    `,
    {
      id: productId, // can't rely on activeProduct.id since this is sometimes rendered outside active product context
    }
  )

  const renderSettingsButtons = useRenderProductAppSettingsButtons(testid)

  if (!Relay.isNodeType(product, "Product")) return null

  const productApps = Relay.connectionToArray(product?.productApps || [])

  return (
    <Form>
      <ActiveAppModalProvider>
        <div>
          <DiscoText
            variant={"body-sm"}
            color={"groovy.neutral.400"}
            marginLeft={1}
            marginBottom={1}
          >
            {"Dashboard"}
          </DiscoText>
          {productApps.map((app) => (
            <ProductAppListItem
              key={app.id}
              testid={getTestId(app)}
              appKey={app}
              disableNavigation
              settingsButton={renderSettingsButtons(app)}
            />
          ))}
        </div>
      </ActiveAppModalProvider>
    </Form>
  )

  function getTestId(app: App) {
    switch (app.kind) {
      case "posts":
        return `${testid}.${app.feed?.name}`
      case "curriculum":
        return `${testid}.Curriculum`
      case "events":
        return `${testid}.Events`
      case "resources":
        return `${testid}.Resources`
      case "slack":
        return `${testid}.Slack`
      case "nav_folder":
      case "content":
      case "link":
      case "collection":
      case "chat_channel":
        return `${testid}.${app.customAppTitle}`
      default:
        return testid
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  appListSkeleton: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
}))

function ManageAppsModalFormSkeleton() {
  const classes = useStyles()

  return (
    <Form>
      <div>
        <DiscoText
          variant={"body-sm"}
          color={"groovy.neutral.400"}
          marginLeft={1}
          marginBottom={1}
        >
          {"Dashboard"}
        </DiscoText>
        <div className={classes.appListSkeleton}>
          {range(5).map((app) => (
            <DiscoSideBarItemSkeleton key={app} />
          ))}
        </div>
      </div>
    </Form>
  )
}

export default Relay.withSkeleton({
  component: ManageAppsModalForm,
  skeleton: ManageAppsModalFormSkeleton,
})
