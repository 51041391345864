import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoText, DiscoTextSkeleton } from "@disco-ui"
import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioProps,
  useTheme,
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import classNames from "classnames"
import React from "react"

export type DiscoRadioProps = Omit<
  FormControlLabelProps,
  "control" | "onChange" | "classes"
> & {
  classes?: RadioProps["classes"] & {
    childrenContainer?: string
    root?: string
    label?: string
    sublabel?: string
    icon?: string
  }
  controlClasses?: FormControlLabelProps["classes"]
  sublabel?: React.ReactNode
  children?: React.ReactNode
  testid?: string
  marginBottom?: string | number
  variant?: "default" | "simple"
  uncheckedIcon?: React.ReactNode
  checkedIcon?: React.ReactNode
}

function DiscoRadio({
  classes: propsClasses = {},
  controlClasses,
  label,
  sublabel,
  children,
  checked,
  testid,
  marginBottom,
  variant = "default",
  uncheckedIcon,
  checkedIcon,
  ...rest
}: DiscoRadioProps) {
  const {
    icon: iconClass,
    childrenContainer: childrenContainerClass,
    ...classes
  } = propsClasses
  const defaultClasses = useStyles({
    checked: checked ?? false,
    variant,
    disabled: rest.disabled ?? false,
    marginBottom,
  })

  return (
    <FormControlLabel
      {...rest}
      classes={{
        ...controlClasses,
        root: classNames(defaultClasses.controlRoot, controlClasses?.root),
        label: classNames(defaultClasses.label, controlClasses?.label),
      }}
      control={
        <Radio
          classes={{
            ...classes,
            root: classNames(defaultClasses.radioRoot, classes.root),
          }}
          disableRipple
          color={"default"}
          data-testid={testid}
          checkedIcon={
            checkedIcon || (
              <span
                data-testid={"DiscoRadio.checked"}
                className={classNames(
                  defaultClasses.radioIcon,
                  defaultClasses.radioCheckedIcon,
                  iconClass
                )}
              />
            )
          }
          icon={
            uncheckedIcon || (
              <span className={classNames(defaultClasses.radioIcon, iconClass)} />
            )
          }
        />
      }
      label={
        <>
          {typeof label === "string" ? (
            <DiscoText
              marginLeft={1}
              marginTop={variant === "default" ? 0.75 : 0}
              variant={
                variant === "default"
                  ? "body-md-600"
                  : sublabel
                  ? "body-sm-600"
                  : "body-sm"
              }
              color={
                variant === "default" || sublabel ? "text.primary" : "text.secondary"
              }
              className={classNames(defaultClasses.innerLabel, classes.label)}
            >
              {label}
            </DiscoText>
          ) : (
            <span className={classNames(defaultClasses.innerLabel, classes.label)}>
              {label}
            </span>
          )}
          {sublabel &&
            (typeof sublabel === "string" ? (
              <DiscoText
                marginLeft={1}
                variant={"body-sm"}
                color={"text.secondary"}
                className={classes.sublabel}
              >
                {sublabel}
              </DiscoText>
            ) : (
              <span className={classes.sublabel}>{sublabel}</span>
            ))}
          {/* Children are rendered when checked */}
          {children && (
            <div
              className={classNames(
                defaultClasses.childrenContainer,
                childrenContainerClass
              )}
            >
              {children}
            </div>
          )}
        </>
      }
    />
  )
}

export const DiscoRadioSkeleton: React.FC = () => {
  const theme = useTheme()
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Skeleton variant={"circle"} width={20} height={20} />
      <DiscoTextSkeleton width={200} style={{ marginLeft: theme.spacing(1) }} />
    </div>
  )
}

type StyleProps = {
  checked: boolean
  variant: "default" | "simple"
  disabled: boolean
  marginBottom?: string | number
}

const useStyles = makeUseStyles((theme) => ({
  controlRoot: ({ variant, marginBottom }: StyleProps) => ({
    padding: theme.spacing(2),
    ...styleIf(variant === "simple", {
      padding: 0,
    }),
    margin: 0,
    "&:not(:last-child)": {
      margin: theme.spacing(0, 0, 1),
      marginBottom: marginBottom ?? undefined,
    },
  }),
  label: ({ variant }: StyleProps) => ({
    display: "grid",
    gridTemplateRows: "38px 1fr",
    ...styleIf(variant === "simple", {
      gridTemplateRows: "1fr",
    }),
    width: "100%",
    height: "100%",
  }),
  innerLabel: ({ disabled }: StyleProps) => ({
    ...styleIf(disabled, {
      color: theme.palette.groovy.grey[400],
    }),
  }),
  radioRoot: ({ variant }: StyleProps) => ({
    alignSelf: "flex-start",
    ...styleIf(variant === "simple", {
      padding: theme.spacing(0.75),
      marginRight: theme.spacing(0.375),
    }),
  }),
  radioIcon: ({ disabled }: StyleProps) => ({
    borderRadius: "50%",
    width: 20,
    height: 20,
    border: `2px solid ${theme.palette.constants.stroke}`,
    transition: "background-color 0.2s ease-in-out",
    ...styleIf(disabled, {
      backgroundColor: theme.palette.groovy.grey[100],
    }),
  }),
  radioCheckedIcon: ({ disabled }: StyleProps) => ({
    backgroundColor: disabled
      ? theme.palette.groovy.neutral[300]
      : theme.palette.primary.main,
    borderColor: "transparent",
    borderWidth: 0,
    position: "relative",
    "&:after": {
      position: "absolute",
      top: "50%",
      left: "50%",
      content: "''",
      width: 6,
      height: 6,
      transform: "translate(-50%, -50%)",
      borderRadius: "50%",
      backgroundColor: "white",
    },
  }),
  childrenContainer: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))

export default DiscoRadio
