import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { DiscoTagSkeleton } from "@disco-ui/tag/DiscoTag"

export type DiscoTableFilterTagsProps = {
  children: React.ReactNode
  isVisible: boolean
  marginBottom?: number
}

function DiscoTableFilterTags({
  children,
  isVisible,
  marginBottom = 1,
}: DiscoTableFilterTagsProps) {
  const classes = useStyles({ marginBottom })

  if (!isVisible) return null
  return (
    <div className={classes.filterTags}>
      <DiscoText variant={"body-sm"} color={"text.secondary"}>
        {"Filter by: "}
      </DiscoText>
      {children}
    </div>
  )
}

type StyleProps = {
  marginBottom: number
}

const useStyles = makeUseStyles((theme) => ({
  filterTags: ({ marginBottom }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(marginBottom),
  }),
}))

export function DiscoTableFilterTagsSkeleton() {
  const classes = useStyles({ marginBottom: 3 })

  return (
    <div className={classes.filterTags}>
      <DiscoText variant={"body-sm"} color={"text.secondary"}>
        {"Filter by: "}
      </DiscoText>
      <DiscoTagSkeleton />
    </div>
  )
}

export default DiscoTableFilterTags
