import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import DiscoIcon, { DiscoIconKinds } from "@disco-ui/icon/DiscoIcon"
import DiscoText from "@disco-ui/text/DiscoText"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { Theme } from "@material-ui/core/styles"
import { TestIDProps } from "@utils/typeUtils"
import { useEffect, useRef, useState } from "react"

interface Props extends TestIDProps {
  mobileIcon: DiscoIconKinds
  parts: [string, string | React.ReactElement]
  /**
   * Specify which parts should be truncated.
   * Dynamic/user generated strings should be truncated, but if we know a part is a short static string, it's better that it's not.
   * Typically at least one part should be truncated
   * @example if parts={["Product Settings", product.name]}, truncatedParts={{0: false, 1: true}}
   * @example if parts={[automation.name, product.name]}, truncatedParts={{0: true, 1: true}}
   * @default {0: false, 1: true}
   */
  truncatedParts?: { [partsIndex in 0 | 1]: boolean }
}

function DiscoDrawerHeaderBreadcrumbTitle(props: Props) {
  const {
    mobileIcon,
    parts,
    testid = "DiscoDrawerHeaderBreadcrumbTitle",
    truncatedParts = { 0: false, 1: true },
  } = props

  const isXsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))
  const theme = useTheme()

  // Check if the text is truncated in the tag
  const textRef = useRef<HTMLSpanElement>(null)
  const [isTruncated, setIsTruncated] = useState(false)
  useEffect(() => {
    if (!textRef.current) return
    setIsTruncated(textRef.current.offsetWidth < textRef.current.scrollWidth)
  }, [])
  const classes = useStyles({ isTruncated })

  return (
    <div className={classes.title}>
      <div className={classes.wrapper}>
        {isXsScreen ? (
          <DiscoIcon
            icon={mobileIcon}
            color={theme.palette.text.primary}
            className={classes.icon}
          />
        ) : (
          <div className={classes.name}>
            <DiscoText
              ref={textRef}
              variant={"body-lg-600"}
              {...(truncatedParts?.[0] === false ? {} : { truncateText: 1 })}
            >
              {parts[0]}
            </DiscoText>
            {!isTruncated && (
              <DiscoText variant={"body-lg-600"} color={"groovy.grey.400"}>
                {"/"}
              </DiscoText>
            )}
          </div>
        )}

        <DiscoText
          variant={"body-lg-600"}
          color={isXsScreen ? "text.primary" : "groovy.grey.400"}
          {...(truncatedParts?.[1] === false ? {} : { truncateText: 1 })}
          testid={`${testid}.title`}
        >
          {parts[1]}
        </DiscoText>
      </div>
    </div>
  )
}

type StyleProps = {
  isTruncated: boolean
}

const useStyles = makeUseStyles((theme) => ({
  wrapper: ({ isTruncated }: StyleProps) => ({
    width: "100%",

    ...styleIf(!isTruncated, {
      display: "flex",
      gap: theme.spacing(1),
    }),
  }),
  name: {
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    overflow: "hidden",
  },
  title: {
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  icon: {
    flex: "0 0 auto",
  },
}))

export default DiscoDrawerHeaderBreadcrumbTitle
