import ChevronIcon from "@/core/ui/iconsax/linear/arrow-up-1.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoIconButton, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { format } from "date-fns"
import { CaptionProps, useNavigation } from "react-day-picker"

type Props = CaptionProps & { compact?: boolean } & TestIDProps

function CalendarCaption(props: Props) {
  const { displayMonth, compact, testid } = props
  const { goToMonth, nextMonth, previousMonth } = useNavigation()
  const classes = useStyles({ compact })
  const theme = useTheme()

  return (
    <>
      <div className={classes.captionRoot}>
        <DiscoIconButton
          testid={`${testid}.CalendarCaption.previous-button`}
          size={"small"}
          disabled={!previousMonth}
          // set color when disabled
          color={previousMonth ? undefined : theme.palette.groovy.grey[400]}
          onClick={(e) => {
            e.stopPropagation()
            if (previousMonth) goToMonth(previousMonth)
          }}
        >
          <ChevronIcon className={classes.previousIcon} />
        </DiscoIconButton>
        <DiscoText
          testid={`${testid}.CalendarCaption.title`}
          variant={compact ? "body-sm-600" : "body-lg-600"}
        >
          {format(displayMonth, "MMM yyy")}
        </DiscoText>
        <DiscoIconButton
          testid={`${testid}.CalendarCaption.next-button`}
          size={"small"}
          disabled={!nextMonth}
          // set color when disabled
          color={nextMonth ? undefined : theme.palette.groovy.grey[400]}
          onClick={(e) => {
            e.stopPropagation()
            if (nextMonth) goToMonth(nextMonth)
          }}
        >
          <ChevronIcon className={classes.nextIcon} />
        </DiscoIconButton>
      </div>
    </>
  )
}

type StyleProps = {
  compact?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  captionRoot: (props: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ...styleIf(props.compact, {
      paddingBottom: theme.spacing(1),
    }),
  }),
  previousIcon: {
    transform: "rotate(-90deg)",
  },
  nextIcon: {
    transform: "rotate(90deg)",
  },
}))

export default CalendarCaption
