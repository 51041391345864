import makeUseStyles from "@assets/style/util/makeUseStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import DiscoTable, { DiscoTableProps } from "@disco-ui/table/DiscoTable"
import DiscoText from "@disco-ui/text/DiscoText"
import { Table, TableBody, TableCell, TableHead } from "@material-ui/core"

type Props = Pick<
  DiscoTableProps,
  "header" | "rows" | "customClasses" | "testid" | "emptyState"
>

export default function DiscoStaticTable(props: Props) {
  const { header, rows, customClasses, testid = "DiscoStaticTable", emptyState } = props
  const classes = useStyles()

  return (
    <Table
      classes={mergeClasses({ root: classes.root }, customClasses)}
      data-testid={testid}
    >
      {header && header.length > 0 && (
        <TableHead className={classes.headerRow}>
          <DiscoTable.Row>
            {header.map(({ value, key, ...cellProps }) => (
              <TableCell key={typeof value === "string" ? value : key} {...cellProps}>
                {typeof value === "string" ? (
                  <DiscoText variant={"body-xs-600"} noWrap>
                    {value}
                  </DiscoText>
                ) : (
                  value
                )}
              </TableCell>
            ))}
          </DiscoTable.Row>
        </TableHead>
      )}
      <TableBody>
        {rows.length > 0 ? (
          rows
        ) : (
          <DiscoTable.Row hover={false}>
            <TableCell colSpan={9999} align={"center"}>
              {emptyState || <DiscoText>{"No results"}</DiscoText>}
            </TableCell>
          </DiscoTable.Row>
        )}
      </TableBody>
    </Table>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "table",
    overflowY: "auto",
    border: "none",
    /** table row spacing */
    borderCollapse: "separate",
    borderSpacing: `0 ${theme.spacing(0.5)}px`,

    "& td, thead, tr, tfoot": {
      borderStyle: "hidden",
    },
    "& td, th": {
      padding: theme.spacing(1),
    },
  },
  headerRow: {
    ...theme.typography["body-xs"],
    ...theme.typography.modifiers.fontWeight[600],
    "& .MuiTableCell-head": {
      "&:nth-child(1)": {
        [theme.breakpoints.down("xs")]: {
          paddingLeft: 0,
        },
      },
    },
    "& th": {
      /** header border radius */
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.neutral[100],
      "&:first-child": {
        borderTopLeftRadius: theme.measure.borderRadius.default,
        borderBottomLeftRadius: theme.measure.borderRadius.default,
      },
      "&:last-child": {
        borderTopRightRadius: theme.measure.borderRadius.default,
        borderBottomRightRadius: theme.measure.borderRadius.default,
      },
    },
  },
}))
