import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { DrawerContext } from "@/core/context/DrawerContext"
import { useHistoryStack } from "@/core/history/historyStack"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import useIsAdminViewingAsMember from "@/product/util/hook/useIsAdminViewingAsMember"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoDrawerHeaderAnchor } from "@disco-ui/drawer/DiscoDrawerHeader"
import { Drawer, DrawerProps, useTheme } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import useDisclosure from "@utils/hook/useDisclosure"
import { getWebViewHeader, WebViewHeader } from "@utils/webView/webViewUtils"
import classNames from "classnames"
import React, { Suspense, useRef, useState } from "react"
import { generatePath, useHistory } from "react-router-dom"

type DiscoDrawerSize = "xs" | "small" | "medium" | "large" | "xl"

export interface DiscoDrawerProps extends DrawerProps {
  children: React.ReactNode
  open: boolean
  anchor?: "right" | "left"
  fullScreen?: boolean
  size?: DiscoDrawerSize
  maxSize?: DiscoDrawerSize | null
  shouldCloseOnEsc?: boolean
  onClose?: () => void
  containerClasses?: Partial<ClassNameMap<"drawerContainer" | "paperContainer">>
  testid?: string
  drawerAnchorKey?: string
  zIndex?: React.CSSProperties["zIndex"]
}

function DiscoDrawer(props: DiscoDrawerProps) {
  const {
    children,
    open,
    anchor = "right",
    size = "xl",
    maxSize,
    shouldCloseOnEsc = false,
    onClose,
    fullScreen: _fullScreen,
    containerClasses,
    testid,
    drawerAnchorKey,
    zIndex,
    ...rest
  } = props

  const isWebView = useIsWebView()
  const webViewHeader = getWebViewHeader()
  const isAdminViewingAs = useIsAdminViewingAsMember()
  const classes = useStyles({
    isAdminViewingAs,
    isWebView,
    drawerSize: size,
    drawerMaxSize: maxSize || null,
    webViewHeader,
  })

  const theme = useTheme()
  const activeProduct = useActiveProduct()

  // LEGACY - stop using when all path drawers are global
  const closeDrawer = useCloseDrawer(
    activeProduct
      ? generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
          productSlug: activeProduct.slug,
        })
      : generatePath(ROUTE_NAMES.COMMUNITY.HOME.ROOT)
  )
  const drawerRef = useRef<HTMLDivElement>(null)
  const [isDrawerScrolled, setIsDrawerScrolled] = useState(
    drawerRef.current?.scrollTop === 0
  )
  const [headerEl, setHeaderEl] = useState<HTMLDivElement | null>(null)
  const [hideHeaderActions, setHideHeaderActions] = useState(false)

  const {
    isOpen: isDrawerSidebarOpen,
    onOpen: openDrawerSidebar,
    onClose: closeDrawerSidebar,
  } = useDisclosure()

  const [fullScreen, setFullScreen] = useState(_fullScreen ?? false)

  return (
    <Drawer
      open={open}
      anchor={anchor}
      onClick={(e) => e.stopPropagation()}
      className={classes.drawer}
      style={{
        zIndex: zIndex || theme.zIndex.drawer,
      }}
      disableEnforceFocus
      onClose={onClose || closeDrawer}
      classes={{ paper: classes.paper }}
      disableEscapeKeyDown={!shouldCloseOnEsc}
      data-testid={"DiscoDrawer.container"}
      {...rest}
    >
      <DrawerContext.Provider
        value={{
          isInDrawer: true,
          closeDrawer: onClose || closeDrawer,
          fullScreen,
          setFullScreen,
          headerEl,
          hideHeaderActions,
          setHideHeaderActions,
          isDrawerSidebarOpen,
          openDrawerSidebar,
          closeDrawerSidebar,
        }}
      >
        <div className={classNames(classes.header, { fullScreen })}>
          <DiscoDrawerHeaderAnchor
            key={drawerAnchorKey}
            setHeader={setHeaderEl}
            isDrawerScrolled={isDrawerScrolled}
          />
        </div>
        <div
          ref={drawerRef}
          className={classNames(
            classes.drawerContainer,
            containerClasses?.drawerContainer,
            {
              fullScreen,
            }
          )}
          data-testid={testid}
          onScroll={onScroll}
        >
          <Suspense fallback={null}>{children}</Suspense>
        </div>
      </DrawerContext.Provider>
    </Drawer>
  )

  function onScroll() {
    if (drawerRef.current) {
      setIsDrawerScrolled(Boolean(drawerRef.current.scrollTop))
    }
  }
}

/** LEGACY: stop using when all path drawers are global */
export function useCloseDrawer(defaultPath: string) {
  const history = useHistory()
  const historyStack = useHistoryStack()

  return (opts?: { hash?: GlobalID }) => {
    // Loop through history stack to find a non-drawer route
    for (let i = historyStack.length - 1; i >= 0; i--) {
      const path = historyStack[i]
      if (!path.includes("/drawer")) {
        history.push({
          pathname: path,
          hash: opts?.hash,
          search: "",
        })
        break
      }

      if (i === 0) history.push(defaultPath)
    }
  }
}

type StyleProps = {
  isAdminViewingAs: boolean
  isWebView: boolean
  drawerSize: DiscoDrawerSize
  drawerMaxSize: DiscoDrawerSize | null
  webViewHeader: WebViewHeader | null
}

const useStyles = makeUseStyles((theme) => ({
  paper: {
    margin: ({ isAdminViewingAs }: StyleProps) =>
      isAdminViewingAs ? theme.spacing(12.5, 2.5, 2.5, 2.5) : theme.spacing(2.5),

    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.background.paper,
    height: ({ isAdminViewingAs }: StyleProps) =>
      isAdminViewingAs
        ? `calc(100% - ${theme.spacing(15)}px)`
        : `calc(100% - ${theme.spacing(5)}px)`,
    // Remove scrollbar
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",

    [theme.breakpoints.down("sm")]: {
      margin: ({ isAdminViewingAs, isWebView }: StyleProps) => {
        if (isAdminViewingAs && !isWebView) {
          return theme.spacing(10.5, 0.5, 0.5, 0.5)
        }
        return theme.spacing(0.5)
      },
      marginTop: ({ webViewHeader }: StyleProps) => {
        if (!webViewHeader) return undefined
        return webViewHeader.insetTop
      },
      height: ({ isAdminViewingAs, isWebView, webViewHeader }: StyleProps) => {
        if (webViewHeader) {
          return `calc(100% - ${webViewHeader.insetTop + theme.spacing(1.5)}px)`
        }

        if (isAdminViewingAs && !isWebView) {
          return `calc(100% - ${theme.spacing(11)}px)`
        }

        return `calc(100% - ${theme.spacing(1.5)}px)`
      },
      width: "calc(100vw - 10px)",
    },
  },
  drawer: {
    zIndex: 0,
  },
  header: {
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.raise3,
    transition: "width 0.3s ease-in-out",
    maxWidth: "calc(100vw - 40px)",
    width: ({ drawerSize }: StyleProps) => theme.measure.drawerMaxWidth[drawerSize],
    flexGrow: 1,

    "&.fullScreen": {
      [theme.breakpoints.up("md")]: {
        width: ({ drawerMaxSize }: StyleProps) =>
          drawerMaxSize
            ? theme.measure.drawerMaxWidth[drawerMaxSize]
            : "calc(100vw - 40px)",
      },
    },

    [theme.breakpoints.down("sm")]: {
      maxWidth: "calc(100vw - 10px) !important",
      width: "calc(100vw - 10px) !important",
    },
  },
  drawerContainer: {
    overflowY: "auto",
    height: "100%",
    transition: "width 0.3s ease-in-out",
    padding: theme.spacing(4.25, 5),
    maxWidth: "calc(100vw - 40px)",
    width: ({ drawerSize }: StyleProps) => theme.measure.drawerMaxWidth[drawerSize],
    flexGrow: 1,

    "&.fullScreen": {
      [theme.breakpoints.up("md")]: {
        width: ({ drawerMaxSize }: StyleProps) =>
          drawerMaxSize
            ? theme.measure.drawerMaxWidth[drawerMaxSize]
            : "calc(100vw - 40px)",
      },
    },
    [theme.breakpoints.only("md")]: {
      padding: theme.spacing(4.25, 3),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4.25, 2),
      maxWidth: "calc(100vw - 10px) !important",
      width: "calc(100vw - 10px) !important",
    },
  },
}))

export const DiscoDrawerSkeleton: React.FC<DiscoDrawerProps> = (props) => {
  const {
    open = true,
    anchor = "right",
    fullScreen = false,
    children,
    size = "large",
    maxSize,
  } = props
  const isAdminViewingAs = useIsAdminViewingAsMember()
  const isWebView = useIsWebView()
  const webViewHeader = getWebViewHeader()
  const classes = useStyles({
    isWebView,
    isAdminViewingAs,
    drawerSize: size,
    drawerMaxSize: maxSize || null,
    webViewHeader,
  })
  const theme = useTheme()
  return (
    <Drawer
      open={open}
      anchor={anchor}
      onClick={(e) => e.stopPropagation()}
      className={classes.drawer}
      style={{
        zIndex: theme.zIndex.drawer,
      }}
      disableEnforceFocus
      classes={{ paper: classes.paper }}
    >
      <div
        className={classNames(classes.drawerContainer, { fullScreen })}
        data-testid={"DiscoDrawerSkeleton.container"}
      >
        {children}
      </div>
    </Drawer>
  )
}

export default Relay.withSkeleton({
  component: DiscoDrawer,
  skeleton: DiscoDrawerSkeleton,
})
