import {
  jssPreset,
  StylesProvider as MuiStylesProvider,
  Theme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles"
import { create as createJss } from "jss"
import { JssProvider, ThemeProvider as JssThemeProvider } from "react-jss"

export interface DiscoThemeProviderProps {
  theme: Theme
}

/** Get or create a <meta id={id}> tag at the bottom of the <head> */
function getOrCreateInsertionPoint(id: string) {
  let el = document.getElementById(id)
  if (el) return el
  el = document.createElement("meta")
  el.id = id
  document.head.appendChild(el)
  return el
}

/**
 * Make sure our custom JSS applies the same preset plugins as Mui JSS.
 * Provide custom insertionPoint elements so that MUI styles are always
 * higher in the DOM than our custom JSS styles. This way, our custom JSS
 * overrides MUI defaults even when the CSS selector specificity is the same.
 */
function createJssWithInsertionPoint(id: string) {
  // @ts-expect-error - jss package type slightly different from MUI. But works.
  return createJss({
    ...jssPreset(),
    insertionPoint: getOrCreateInsertionPoint(id),
  })
}

/** This MUI call MUST come before JSS so that JSS styles come after MUI in the DOM */
const muiJss = createJssWithInsertionPoint("mui-insertion-point")
const jss = createJssWithInsertionPoint("jss-insertion-point")

const DiscoThemeProvider: React.FC<DiscoThemeProviderProps> = (props) => {
  const { theme, children } = props

  return (
    // @ts-expect-error - mui JSS type is subtly different from jss. But works...
    <MuiStylesProvider jss={muiJss}>
      <JssProvider jss={jss}>
        <JssThemeProvider theme={theme}>
          <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </JssThemeProvider>
      </JssProvider>
    </MuiStylesProvider>
  )
}

export default DiscoThemeProvider
