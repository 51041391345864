import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { TextVariantWithModifiers } from "@assets/style/appMuiTheme"
import makeTypographyStyles from "@assets/style/util/makeTypographyStyles"
import { Link as TextButton } from "@material-ui/core"
import classNames from "classnames"
import { LocationDescriptorObject, LocationState } from "history"
import React, { forwardRef } from "react"
import { Link, LinkProps } from "react-router-dom"

type DiscoLinkTextDecoration = "none" | "underline" | "line-through"

export interface DiscoLinkProps extends Omit<LinkProps, "to"> {
  /** A string or location state object. Currently, it doesn't support the function syntax for the location object */
  to?: string | LocationDescriptorObject<LocationState>
  children?: React.ReactNode | null
  className?: string
  color?: string
  textDecoration?: DiscoLinkTextDecoration
  textVariant?: TextVariantWithModifiers
}

const DiscoLink = forwardRef<HTMLAnchorElement, DiscoLinkProps>((props, ref) => {
  const {
    to,
    children,
    color,
    textDecoration = "none",
    className,
    onClick,
    textVariant = "body-md",
    ...rest
  } = props
  const typographyClasses = useTypographyStyles()

  const classes = useStyles({ color, textDecoration })
  const path = typeof to === "string" ? to : to?.pathname || ""

  // Check if the link is an external link
  // External links are always absolute and we don't modify the path for custom domain
  if (/^(https?|slack):\/\//.test(path))
    return (
      <a
        ref={ref}
        className={classNames(classes.link, typographyClasses[textVariant], className)}
        href={path}
        {...rest}
      >
        {children}
      </a>
    )

  if (to) {
    return (
      <Link
        {...rest}
        ref={ref}
        className={classNames(classes.link, typographyClasses[textVariant], className)}
        to={to}
        onClick={onClick}
      >
        {children}
      </Link>
    )
  }
  return (
    <TextButton
      {...rest}
      ref={ref}
      className={classNames(classes.link, typographyClasses[textVariant], className)}
      onClick={onClick}
    >
      {children}
    </TextButton>
  )
})

const useTypographyStyles = makeTypographyStyles()

type StyleProps = {
  color?: string
  textDecoration?: DiscoLinkTextDecoration
}

const useStyles = makeUseStyles((theme) => ({
  link: ({ color, textDecoration }: StyleProps) => ({
    color: color || theme.palette.primary.main,
    textDecoration,

    "&:visited": {
      color: color || theme.palette.primary.main,
    },

    "&:hover": {
      cursor: "pointer",
    },
  }),
}))

export default DiscoLink
