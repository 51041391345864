import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { darken, lighten } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { BaseEmoji, Picker } from "emoji-mart"
interface DiscoEmojiPickerProps {
  onChange: (emoji: BaseEmoji | null) => void
  onClose: () => void
}

function DiscoEmojiPicker({ onChange, onClose }: DiscoEmojiPickerProps) {
  const classes = useStyles()

  const isMobile = useIsMobile()

  return (
    <div className={classes.emojiPicker}>
      <Picker
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={!isMobile}
        native
        theme={"auto"}
        title={""}
        sheetSize={20}
        emoji={"grinning"}
        onSelect={(emoji) => {
          onChange(emoji as BaseEmoji)
          onClose()
        }}
      />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  // Defining the background and active tab colors
  emojiPicker: {
    "& .emoji-mart": {
      // for background of emoji picker
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.divider,
    },
    "& .emoji-mart-bar": {
      borderColor: `${theme.palette.divider} !important`,
    },
    "& .emoji-mart-category-label span": {
      // for intermediary category labels: eg: Frequently used, Smileys & People, etc
      backgroundColor: `${theme.palette.background.paper} !important`,
      color: `${theme.palette.text.secondary} !important`,
    },
    "& .emoji-mart-anchor-selected": {
      "& .emoji-mart-anchor-bar": {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      "& .emoji-mart-anchor-icon svg": {
        fill: theme.palette.primary.main,
      },
    },
    "& .emoji-mart-search input": {
      backgroundColor: `${theme.palette.background.paper} !important`,
      borderColor: `${theme.palette.divider} !important`,
      color: `${theme.palette.text.primary} !important`,
    },
    "& .emoji-mart-search-icon svg": {
      fill: `${theme.palette.text.primary} !important`,
    },

    "& .emoji-mart-skin-swatches": {
      backgroundColor: `${theme.palette.background.paper} !important`, // Update skin tone picker background color
    },
    "& .emoji-mart-preview-data": {
      color: theme.palette.text.secondary,
    },
    "& .emoji-mart-category .emoji-mart-emoji:hover::before": {
      backgroundColor:
        theme.palette.type === "dark"
          ? `${darken(theme.palette.primary.dark, 0.8)} !important`
          : `${lighten(theme.palette.primary.light, 0.5)} !important`,
    },
    "& .emoji-mart-category-list": {
      cursor: "default",
    },
    "& .emoji-mart-emoji": {
      cursor: "pointer",
      "& > span": {
        cursor: "pointer !important",
      },
    },
  },
}))

export default DiscoEmojiPicker
