import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import Modal from "@components/modal/Modal"
import { DiscoIconButton } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import DiscoVideo from "@disco-ui/video/DiscoVideo"

export interface DiscoMediaModalProps {
  isOpen: boolean
  onClose: () => void
  isImage?: boolean
  isVideo?: boolean
  testid?: string
  src: string
}

function DiscoMediaModal({
  isOpen,
  onClose,
  isImage = true,
  isVideo = false,
  testid,
  src,
}: DiscoMediaModalProps) {
  const classes = useStyles()
  return (
    <Modal
      isOpen={isOpen}
      modalContentLabel={"Media Attachment"}
      onClose={onClose}
      customClassName={classes.modal}
    >
      <DiscoIconButton
        size={"small"}
        className={classes.closeIcon}
        testid={`${testid}.close-button`}
        onClick={onClose}
      >
        <CloseIcon />
      </DiscoIconButton>
      {isVideo && <DiscoVideo src={src} />}
      {isImage && <DiscoImage src={src} alt={"Attachment"} className={classes.image} />}
    </Modal>
  )
}

const useStyles = makeUseStyles((theme) => ({
  modal: {
    maxWidth: "fit-content",
    maxHeight: "100%",
    padding: theme.spacing(2),
  },
  closeIcon: {
    position: "fixed",
    top: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      cursor: "pointer",
      boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    },
    margin: theme.spacing(3, 3, 0, 0),
  },
  image: {
    maxWidth: "65vw",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100vw",
    },
  },
}))

export default DiscoMediaModal
