import { AssetSubtitlesModalProvider } from "@/admin/asset/tracks/AssetSubtitlesModalContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import { useFormStore } from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import DiscoButton from "@disco-ui/button/DiscoButton"
import DiscoIconButton from "@disco-ui/button/DiscoIconButton"
import DiscoDivider from "@disco-ui/divider/DiscoDivider"
import DiscoIcon from "@disco-ui/icon/DiscoIcon"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import DiscoVideo, { CuePoint, DiscoVideoProps } from "@disco-ui/video/DiscoVideo"
import useTranscriptionPermissions from "@disco-ui/video/interactive-player/hooks/useTranscriptionPermissions"
import InteractivePlayerTranscriptionStatus from "@disco-ui/video/interactive-player/InteractivePlayerTranscriptionStatus"
import InteractivePlayerOverviewTabContent from "@disco-ui/video/interactive-player/tabs/InteractivePlayerOverviewTabContent"
import InteractivePlayerSubtitlesTabContent from "@disco-ui/video/interactive-player/tabs/InteractivePlayerSubtitlesTabContent"
import InteractivePlayerTranscriptTabContent from "@disco-ui/video/interactive-player/tabs/InteractivePlayerTranscriptTabContent"
import {
  DiscoInteractivePlayerFormMutation,
  UpdateAssetTranscriptionInput,
} from "@disco-ui/video/interactive-player/__generated__/DiscoInteractivePlayerFormMutation.graphql"
import { DiscoInteractivePlayerQuery } from "@disco-ui/video/interactive-player/__generated__/DiscoInteractivePlayerQuery.graphql"
import { Collapse } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import MuxPlayerElement from "@mux/mux-player"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useCallback, useEffect, useRef, useState } from "react"
import { graphql } from "react-relay"

interface DiscoInteractivePlayerProps
  extends TestIDProps,
    Omit<DiscoVideoProps, "assetKey"> {
  assetId: string
  showSubtitlesTab?: boolean
  defaultTabOpen?: boolean
}

type DiscoInteractivePlayerTab = "overview" | "transcript" | "subtitles"
export type DiscoInteractivePlayerMode = "edit" | "view"

function DiscoInteractivePlayer(props: DiscoInteractivePlayerProps) {
  const {
    assetId,
    showSubtitlesTab = false,
    defaultTabOpen = true,
    testid = "DiscoInteractivePlayer",
    ...rest
  } = props
  const { videoTranscription } = useFeatureFlags()
  const membersLabel = useLabel("organization_member")
  const modal = useUnsavedChangesModalContext()

  const classes = useStyles()

  const muxPlayerRef = useRef<MuxPlayerElement>(null)
  const [activeCuePoint, setActiveCuePoint] = useState<CuePoint | undefined>(undefined)
  const [mode, setMode] = useState<DiscoInteractivePlayerMode>("view")
  const isEditing = mode === "edit"
  const [seed, setSeed] = useState(1)
  const [isTabsOpen, setIsTabsOpen] = useState(defaultTabOpen)

  const [{ node }, refetch] = Relay.useRefetchableQuery<DiscoInteractivePlayerQuery>(
    graphql`
      query DiscoInteractivePlayerQuery($id: ID!) {
        node(id: $id) {
          __typename
          ... on Asset {
            id
            ...DiscoVideoFragment
            ...InteractivePlayerTranscriptionStatusFragment
            ...InteractivePlayerSubtitlesTabContentFragment
            transcription {
              id
              status
              summary
              chapters {
                startTime
                timecode
                title
              }
              ...InteractivePlayerOverviewTabContentFragment
              ...InteractivePlayerTranscriptTabContentFragment
            }
          }
        }
      }
    `,
    {
      id: assetId,
    },
    {
      fetchPolicy: "store-and-network",
    }
  )

  const asset = Relay.narrowNodeType(node, "Asset")

  const form = useFormStore<
    DiscoInteractivePlayerFormMutation,
    UpdateAssetTranscriptionInput
  >(
    graphql`
      mutation DiscoInteractivePlayerFormMutation(
        $input: UpdateAssetTranscriptionInput!
      ) {
        response: updateAssetTranscription(input: $input) {
          node {
            id
            summary
            chapters {
              startTime
              timecode
              title
            }
            ...InteractivePlayerOverviewTabContentFragment
            ...InteractivePlayerTranscriptTabContentFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      assetTranscriptionId: asset?.transcription?.id || "",
      summary: asset?.transcription?.summary || "",
      chaptersText: asset?.transcription?.chapters
        ? asset.transcription?.chapters.map((c) => `${c.timecode} ${c.title}`).join("\n")
        : "",
      status: asset?.transcription?.status || null,
    }
  )

  const { setUnsavedChanges } = useUnsavedChangesModalContext()
  useEffect(() => {
    setUnsavedChanges(form.isChanged)
  }, [form.isChanged, setUnsavedChanges])

  useEffect(() => {
    if (asset?.transcription?.id) {
      form.state.assetTranscriptionId = asset.transcription.id
      form.state.summary = asset.transcription.summary
      form.state.chaptersText = asset?.transcription?.chapters
        ? asset.transcription?.chapters.map((c) => `${c.timecode} ${c.title}`).join("\n")
        : ""
      form.state.status = asset?.transcription?.status
      form.resetInitialState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset?.transcription?.id])

  const [tab, setTab] = useState<DiscoInteractivePlayerTab>(
    videoTranscription && asset?.transcription ? "overview" : "subtitles"
  )

  const hasAIAssets = Boolean(asset?.transcription)
  const isMobile = useIsMobile()
  const { canManage } = useTranscriptionPermissions()
  const canEditTranscription = canManage && hasAIAssets
  const canEditOverview = canEditTranscription && tab === "overview"

  const handleCurrentTimeChange = useCallback((time: number) => {
    const muxPlayer = muxPlayerRef.current
    if (!muxPlayer || muxPlayer.currentTime === time) return
    // Play the video if it's not playing
    if (muxPlayer.paused) {
      muxPlayer.play()
    }

    muxPlayer.currentTime = time
  }, [])

  if (!asset) return null

  return (
    <div className={classes.root}>
      <DiscoVideo
        // Seed is used to force the video to re-render when the asset changes
        key={seed}
        {...rest}
        ref={muxPlayerRef}
        assetKey={asset}
        className={classes.interactiveVideo}
        onCuePointChange={setActiveCuePoint}
        testid={testid}
      />
      <div className={classes.subContent}>
        <InteractivePlayerTranscriptionStatus
          assetKey={asset}
          mode={mode}
          onTranscriptionJobStart={refetchAsset}
        />

        {(showSubtitlesTab || (hasAIAssets && videoTranscription)) && (
          <Form id={testid} testid={testid} onSubmit={handleSubmit}>
            <div className={classes.tabsContainer}>
              <DiscoTabs
                testid={`link.tabs`}
                tabClassname={classes.tab}
                className={classes.tabs}
                size={isMobile ? "small" : "default"}
                routes={[
                  ...ArrayUtils.spreadIf(
                    [
                      {
                        testid: "overview",
                        onClick: () => handleSelectTab("overview"),
                        active: tab === "overview",
                        label: "Overview",
                      },
                      {
                        testid: "transcript",
                        onClick: () => handleSelectTab("transcript"),
                        active: tab === "transcript",
                        label: "Transcript",
                      },
                    ],
                    videoTranscription && hasAIAssets
                  ),
                  ...ArrayUtils.spreadIf(
                    {
                      testid: "subtitles",
                      onClick: () => handleSelectTab("subtitles"),
                      active: tab === "subtitles",
                      label: "Subtitles",
                    },
                    showSubtitlesTab
                  ),
                ]}
              />

              <div className={classes.tabsControls}>
                {canEditOverview &&
                  (isEditing ? (
                    <>
                      <DiscoButton
                        variant={"outlined"}
                        color={"grey"}
                        onClick={handleCancel}
                      >
                        {"Cancel"}
                      </DiscoButton>
                      <Form.SubmitButton
                        form={form}
                        id={testid}
                        testid={`${testid}.submit-button`}
                        className={classes.submitButton}
                        disabled={!form.isChanged}
                      >
                        {"Save Changes"}
                      </Form.SubmitButton>
                    </>
                  ) : (
                    <DiscoIconButton
                      onClick={() => setMode("edit")}
                      svgStyles={{
                        height: 20,
                        width: 20,
                      }}
                      tooltip={"Edit"}
                      tooltipPlacement={"bottom"}
                    >
                      <DiscoIcon icon={"edit"} />
                    </DiscoIconButton>
                  ))}
                {canEditTranscription && (
                  <DiscoIconButton
                    onClick={handleVisibilityChange}
                    svgStyles={{
                      height: 20,
                      width: 20,
                    }}
                    tooltip={
                      form.state.status === "draft"
                        ? `Hidden from ${membersLabel.plural}`
                        : `Visible to ${membersLabel.plural}`
                    }
                    tooltipPlacement={"bottom"}
                  >
                    <DiscoIcon
                      icon={form.state.status === "published" ? "eye" : "eye-off"}
                    />
                  </DiscoIconButton>
                )}

                <DiscoIconButton
                  onClick={() => setIsTabsOpen((prev) => !prev)}
                  svgStyles={{
                    height: 20,
                    width: 20,
                  }}
                  tooltip={isTabsOpen ? "Hide" : "Show"}
                  tooltipPlacement={"bottom"}
                >
                  <DiscoIcon icon={isTabsOpen ? "chevron" : "chevron-down"} />
                </DiscoIconButton>
              </div>
            </div>

            <Collapse in={isTabsOpen}>
              <DiscoDivider thickness={1} marginTop={0} marginBottom={0} />
              <div className={classes.tabContent} id={"tab-content-scroll-container"}>
                {hasAIAssets && (
                  <>
                    {tab === "overview" && (
                      <InteractivePlayerOverviewTabContent
                        assetTranscriptionKey={asset.transcription}
                        onTimeChange={handleCurrentTimeChange}
                        form={form}
                        mode={mode}
                      />
                    )}
                    {tab === "transcript" && (
                      <InteractivePlayerTranscriptTabContent
                        assetTranscriptionKey={asset.transcription}
                        onTimeChange={handleCurrentTimeChange}
                        activeCuePoint={activeCuePoint}
                      />
                    )}
                  </>
                )}
                {showSubtitlesTab && tab === "subtitles" && (
                  <AssetSubtitlesModalProvider>
                    <InteractivePlayerSubtitlesTabContent assetKey={asset} />
                  </AssetSubtitlesModalProvider>
                )}
              </div>
            </Collapse>
          </Form>
        )}
      </div>
    </div>
  )

  async function handleSubmit() {
    if (!form.state.assetTranscriptionId) return

    const { didSave, response } = await form.submit({
      assetTranscriptionId: form.state.assetTranscriptionId,
      chaptersText: form.state.chaptersText,
      summary: form.state.summary,
    })

    if (!didSave || !response?.node) {
      return
    }

    resetPlayer()
    setMode("view")

    displaySuccessToast({
      message: "Changes saved successfully",
    })
  }

  async function refetchAsset() {
    await refetch({ id: assetId })
  }

  async function handleVisibilityChange() {
    if (!form.state.assetTranscriptionId) return

    form.state.status = form.state.status === "published" ? "draft" : "published"

    const { didSave, response } = await form.submit({
      assetTranscriptionId: form.state.assetTranscriptionId,
      status: form.state.status,
    })

    if (!didSave || !response?.node) {
      return
    }

    displaySuccessToast({
      message: "Changes saved successfully",
    })
  }

  function handleCancel() {
    form.reset()
    setMode("view")
  }

  /** Reset the player to apply the chapters change */
  function resetPlayer() {
    setSeed(Math.random())
  }

  function handleSelectTab(t: DiscoInteractivePlayerTab) {
    modal.handleLeave({
      onLeave: () => {
        setTab(t)

        // Exit edit mode if we are not on the overview tab
        if (t !== "overview") {
          setMode("view")
        }
        // If the tab is collapsed, open it
        setIsTabsOpen(true)
      },
    })
  }
}

function DiscoInteractivePlayerSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.skeleton}>
      <Skeleton variant={"rect"} height={400} width={"100%"} />
      <Skeleton variant={"rect"} height={50} width={"100%"} />
      <Skeleton variant={"rect"} height={200} width={"100%"} />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  tab: {
    "&:after": {
      bottom: "0px",
    },
  },
  tabs: {
    padding: theme.spacing(0, 2),
  },
  root: {
    // If we have sub content, unset the video bottom border radius
    "&:not(:has($subContent:empty)) $interactiveVideo": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  interactiveVideo: {},
  subContent: {
    "&:not(:empty)": {
      border: `1px solid ${theme.palette.divider}`,
    },
    borderTop: "none",
    borderBottomLeftRadius: theme.measure.borderRadius.big,
    borderBottomRightRadius: theme.measure.borderRadius.big,
  },
  tabsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  submitButton: {
    marginRight: theme.spacing(1.5),
  },
  tabContent: {
    padding: theme.spacing(2.5),
  },
  tabsControls: {
    margin: theme.spacing(0, 0, 0, 2.5),
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
  },
  graidentSpinner: {
    background: theme.palette.aiGradient.bluePurple03,
  },
  skeleton: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}))

export default Relay.withSkeleton({
  component: observer(DiscoInteractivePlayer),
  skeleton: DiscoInteractivePlayerSkeleton,
})
