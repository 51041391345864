import Format from "@/core/format/format"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoIcon, DiscoText, DiscoTextProps, DiscoTooltip } from "@disco-ui"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import classnames from "classnames"
import { ReactNode } from "react"

type COLOR_VARIANTS = "warning" | "grey"
export type DiscoStatisticsTagVariant = "round" | "square"

type DiscoStatisticsTagProps = {
  number?: number
  allTimeNumber?: number
  text?: string
  defaultVariant?: COLOR_VARIANTS
  currency?: string
  testid?: string
  textVariant?: DiscoTextProps["variant"]
  marginTop?: number
  percentage?: boolean
  tooltipContent?: string | ReactNode
  classes?: Partial<ClassNameMap<"root">>
  tagVariant?: DiscoStatisticsTagVariant
}

function DiscoStatisticsTag(props: DiscoStatisticsTagProps) {
  const {
    tagVariant = "square",
    number,
    allTimeNumber,
    text,
    defaultVariant = "grey",
    currency,
    testid = "DiscoStatisticsTag",
    textVariant,
    marginTop = 2,
    percentage,
    tooltipContent,
    classes: propClasses,
  } = props
  const isNumber = typeof number === "number"
  const showArrow = isNumber && number !== 0
  const classes = useStyles({ number: number ?? 0, defaultVariant, tagVariant })
  const formattedNumber =
    isNumber && currency && !percentage
      ? Format.asCurrency(number, { currency, notation: "compact" })
      : number
  const showTooltip = Boolean(tooltipContent)
  const dividend = number ? Math.abs(number) : 0

  return (
    <DiscoTooltip disabled={!showTooltip} content={tooltipContent}>
      <DiscoText
        component={"div"}
        className={classnames(classes.tag, propClasses?.root)}
        data-testid={testid}
        variant={textVariant}
        marginTop={marginTop}
      >
        {showArrow && (
          <DiscoIcon
            icon={"arrow-stem-up"}
            rotate={number < 0 ? "180" : undefined}
            width={18}
            height={18}
          />
        )}
        {percentage && dividend && allTimeNumber
          ? `${Math.floor((dividend / allTimeNumber) * 100)}%`
          : `${formattedNumber ?? ""} ${text || ""}`}
      </DiscoText>
    </DiscoTooltip>
  )
}

type StyleProps = {
  number: number
  defaultVariant?: COLOR_VARIANTS
  tagVariant?: DiscoStatisticsTagVariant
}

const useStyles = makeUseStyles((theme) => ({
  tag: ({ number, defaultVariant, tagVariant }: StyleProps) => {
    const discoColor =
      number > 0
        ? "green"
        : number < 0
        ? "red"
        : defaultVariant === "warning"
        ? "orange"
        : "grey"
    return {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
      padding: theme.spacing(0.5, 1),
      borderRadius:
        tagVariant === "round"
          ? theme.measure.borderRadius.xl
          : theme.measure.borderRadius.default,
      backgroundColor: theme.palette.groovy[discoColor][100],
      color: theme.palette.groovy[discoColor][600],
      "& > svg": {
        "& path:first-child": {
          color: theme.palette.groovy[discoColor][600],
        },
        "& path:nth-child(2)": {
          color: theme.palette.groovy[discoColor][600],
        },
      },
    }
  },
}))

export default DiscoStatisticsTag
