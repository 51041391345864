import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoSpinner } from "@disco-ui"
import useEffectOnUpdate from "@utils/hook/useEffectOnUpdate"
import classNames from "classnames"
import { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"

interface Props {
  onScrolledIntoView: () => void
  isLoading?: boolean
  skeleton?: JSX.Element
  className?: string
}

function DiscoScrolledIntoView({
  onScrolledIntoView,
  isLoading = false,
  skeleton,
  className,
}: Props) {
  const classes = useStyles()
  const { ref, inView } = useInView()

  // Track a separate loading state that we can set to false after a delay. This avoids
  // a bug where isLoading becomes false before inView updates to true, which would
  // re-trigger onScrolledIntoView instantly.
  const [delayedIsLoading, setDelayedIsLoading] = useState(false)
  useEffectOnUpdate(() => {
    if (isLoading) {
      setDelayedIsLoading(true)
      return
    }
    const timeout = setTimeout(() => setDelayedIsLoading(false), 100)
    return () => clearTimeout(timeout)
  }, [isLoading])

  useEffect(() => {
    if (!inView || delayedIsLoading) return
    onScrolledIntoView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, delayedIsLoading])

  return (
    <div ref={ref} className={classNames(classes.scrollIntoView, className)}>
      {/* Always show the skeleton if provided, for a smoother scroll experience */}
      {skeleton ||
        (isLoading ? (
          <div className={classes.defaultContainer}>
            <DiscoSpinner />
          </div>
        ) : null)}
    </div>
  )
}

const useStyles = makeUseStyles({
  defaultContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
  },
  scrollIntoView: {
    // Fixes bug in Chrome where the div could never scroll into view within
    // some container styles
    paddingTop: "1px",
  },
})

export default DiscoScrolledIntoView
