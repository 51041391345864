import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useDrawerContext } from "@/core/context/DrawerContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useStartViewAsMember } from "@/core/route/util/routeUtils"
import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import ReduceIcon from "@/core/ui/iconsax/linear/custom-collapse.svg"
import ExpandIcon from "@/core/ui/iconsax/linear/custom-expand.svg"
import ViewAsIconButton from "@/product/course/view-as/ViewAsIconButton"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoIconButton, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import DiscoDivider from "@disco-ui/divider/DiscoDivider"
import DiscoQueryParamTabs, { QueryParamTab } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { Skeleton } from "@material-ui/lab"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import React from "react"
import ReactDOM from "react-dom"

interface Props extends TestIDProps {
  overlineTitle?: string | React.ReactElement
  title?: string | React.ReactElement
  subtitle?: string | React.ReactElement
  onClose?: () => void
  headerActions?: React.ReactNode
  onBack?: () => void
  className?: string
  disableExpand?: boolean
  hideClose?: boolean
  children?: React.ReactNode
  customSubTabs?: React.ReactNode
  showViewAsMemberButton?: boolean
  queryParamTabs?: QueryParamTab<Record<string, string>>[] | null
  allowTabNavigationWithUnsavedChanges?: boolean
  classes?: {
    header?: string
  }
  customDivider?: React.ReactNode
  shouldDisplayBackSpinner?: boolean
}

function DiscoDrawerHeader({
  overlineTitle,
  title,
  subtitle,
  onClose,
  headerActions,
  onBack,
  testid = "DiscoDrawerHeader",
  className,
  showViewAsMemberButton = false,
  disableExpand = false,
  hideClose = false,
  queryParamTabs,
  customSubTabs,
  allowTabNavigationWithUnsavedChanges = true,
  children,
  classes: customClasses,
  customDivider,
  shouldDisplayBackSpinner,
}: Props) {
  const classes = useStyles({ hasSubtabs: Boolean(customSubTabs || queryParamTabs) })
  const isMobile = useIsMobile()
  const { fullScreen, setFullScreen, hideHeaderActions } = useDrawerContext()
  const startViewAsMember = useStartViewAsMember()
  const activeProduct = useActiveProduct()
  const activeOrganization = useActiveOrganization()
  const isAdmin =
    activeOrganization?.viewerIsOwnerOrAdmin || activeProduct?.viewerIsManagerOrInstructor
  const membersLabel = useLabel("product_member")
  const hideHeader = !title && !subtitle && !overlineTitle

  const subTabs = queryParamTabs ? (
    <DiscoQueryParamTabs
      testid={`${testid}Subtabs`}
      tabs={queryParamTabs}
      className={classes.subTabs}
      size={isMobile ? "small" : "default"}
      allowTabNavigationWithUnsavedChanges={allowTabNavigationWithUnsavedChanges}
    />
  ) : (
    customSubTabs
  )

  const actions = (
    <div className={classes.headerActions}>
      {headerActions}

      {/* View As Learner Button */}
      {showViewAsMemberButton && isAdmin && (
        <ViewAsIconButton
          tooltipContent={`View As ${membersLabel.singular}`}
          className={classes.actionButton}
          onClick={startViewAsMember}
          testid={testid}
        />
      )}

      {/* Expand */}
      {!isMobile && setFullScreen && !disableExpand && (
        <DiscoIconButton
          className={classes.actionButton}
          onClick={handleExpand}
          testid={`${testid}.expand-button`}
        >
          {fullScreen ? <ReduceIcon /> : <ExpandIcon />}
        </DiscoIconButton>
      )}

      {/* Close */}
      {!hideClose && (
        <DiscoIconButton
          className={classes.closeButton}
          onClick={onClose}
          size={"small"}
          testid={`${testid}.close-button`}
        >
          <CloseIcon />
        </DiscoIconButton>
      )}
    </div>
  )

  return (
    <DiscoDrawerHeaderPortal>
      <div
        data-testid={`${testid}.container`}
        className={classNames(classes.headerContainer, className)}
      >
        {!hideHeader && (
          <div className={classNames(classes.header, customClasses?.header)}>
            {/* Back Button */}
            {onBack && (
              <DiscoIconButton
                testid={`${testid}.back-button`}
                onClick={onBack}
                className={classes.backButton}
                shouldDisplaySpinner={shouldDisplayBackSpinner}
              >
                <DiscoIcon icon={"arrow-stem-left"} />
              </DiscoIconButton>
            )}

            {/* Title */}
            <div className={classes.titleContainer}>
              <div className={classes.fullHeaderContainer}>
                {overlineTitle && (
                  <div className={classes.overlineTitleContainer}>
                    {/* Overline */}
                    {typeof overlineTitle === "string" ? (
                      <DiscoText
                        variant={"body-xs-600-uppercase"}
                        color={"primary.main"}
                        testid={`${testid}.overline-title`}
                        truncateText={2}
                      >
                        {overlineTitle}
                      </DiscoText>
                    ) : (
                      overlineTitle
                    )}
                    {isMobile && !hideHeaderActions && actions}
                  </div>
                )}

                {/* Title */}
                {typeof title === "string" ? (
                  <DiscoText variant={"body-lg-600"} testid={`${testid}.title`}>
                    {title}
                  </DiscoText>
                ) : (
                  title
                )}

                {/* Subtitle */}
                {typeof subtitle === "string" ? (
                  <DiscoText
                    variant={"body-sm"}
                    color={"text.secondary"}
                    testid={`${testid}.subtitle`}
                    truncateText={2}
                  >
                    {subtitle}
                  </DiscoText>
                ) : (
                  subtitle
                )}
              </div>
            </div>

            {/* CTAs */}
            {!hideHeaderActions && !(overlineTitle && isMobile) && actions}
          </div>
        )}

        {children}
        {subTabs && <div>{subTabs}</div>}
      </div>
      <div className={"drawer-header-divider-container"}>
        {customDivider ?? <DiscoDivider thickness={2} marginTop={0} marginBottom={0} />}
      </div>
    </DiscoDrawerHeaderPortal>
  )

  function handleExpand() {
    if (setFullScreen) setFullScreen(!fullScreen)
  }
}

interface StyleProps {
  hasSubtabs?: boolean | null
}

const useStyles = makeUseStyles((theme) => ({
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    background: theme.palette.background.paper,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: ({ hasSubtabs }: StyleProps) =>
      hasSubtabs ? theme.spacing(1.5) : theme.spacing(2.5),
    gap: theme.spacing(1.5),
  },
  fullHeaderContainer: {
    width: "100%",
  },
  subTabs: {
    textAlign: "center",
    overflow: "auto",
    ...theme.typography["body-sm"],
    ...theme.typography.modifiers.fontWeight[600],
    transform: "translateY(1.5px)",

    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    [theme.breakpoints.down("xs")]: {
      "& a": {
        fontSize: "13px",
      },
    },
  },
  titleContainer: {
    flexGrow: 1,
    display: "flex",
    gap: theme.spacing(1),
    // needed to constrain the content of the titleContainer and prevent it from overflowing
    minWidth: 0,
  },
  headerActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(-1, -1.5),
    },
  },
  overlineTitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  actionButton: {
    borderRadius: theme.measure.borderRadius.medium,
  },
  closeButton: {
    padding: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.medium,
  },
  skeletonContainer: {
    gap: theme.spacing(2),
  },
  isScrolled: {
    boxShadow: "0px 1px 1px rgb(46 64 88 / 3%), 0px 5px 24px rgb(46 64 88 / 5%)",
  },
  backButton: {
    marginBottom: theme.spacing(1),
    "& svg": {
      color: theme.palette.groovy.neutral[700],
    },
  },
  headerAnchor: {
    // Hide a skeleton that is still rendered in the portal when the
    // drawer header has stopped suspending and is already rendered
    "& > $headerContainer:nth-of-type(3), & > .drawer-header-divider-container:nth-of-type(4)":
      {
        // Not using display:none as it would not allow us to focus elements on header on load
        height: 0,
        padding: 0,
        overflow: "hidden",
      },
  },
}))

export const DiscoDrawerHeaderSkeleton: React.FC = (props) => {
  const classes = useStyles({ hasSubtabs: Boolean(props.children) })

  return (
    <div className={classes.header}>
      <div className={classes.titleContainer}>
        <DiscoTextSkeleton variant={"body-lg-600"} width={250} />
      </div>

      <div className={classNames(classes.headerActions, classes.skeletonContainer)}>
        <Skeleton width={24} height={24} />
        <Skeleton width={24} height={24} />
      </div>
    </div>
  )
}

export default DiscoDrawerHeader

interface DiscoDrawerHeaderPortalProps {
  setHeader?: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
  children?: React.ReactNode
  isDrawerScrolled?: boolean
}

export function DiscoDrawerHeaderAnchor(props: DiscoDrawerHeaderPortalProps) {
  const { isDrawerScrolled, setHeader } = props
  const classes = useStyles({ hasSubtabs: null })

  return (
    <div
      ref={setHeader}
      className={classNames(classes.headerAnchor, {
        [classes.isScrolled]: isDrawerScrolled,
      })}
    />
  )
}

export function DiscoDrawerHeaderPortal(props: DiscoDrawerHeaderPortalProps) {
  const { children } = props
  const { headerEl } = useDrawerContext()

  if (!headerEl) return null
  return ReactDOM.createPortal(children, headerEl)
}
