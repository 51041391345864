import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoLink from "@disco-ui/link/DiscoLink"
import DiscoText, { DiscoTextProps } from "@disco-ui/text/DiscoText"
import { URL_REGEX } from "@utils/string/stringConstants"
import { ReactNode } from "react"

type Props = Omit<DiscoTextProps, "children"> & {
  children?: string | null
}

export function DiscoTextWithLinks({ children, ...rest }: Props) {
  const classes = useStyles()

  // Parse the text for URLs and wrap each with a DiscoLink
  const nodes: ReactNode[] = []
  if (children) {
    const matches = children.matchAll(URL_REGEX)
    let prevEnd = 0
    for (const match of matches) {
      // Have to assert index because of a bug in typescript
      // https://github.com/microsoft/TypeScript/issues/36788
      if (match.index! > prevEnd) {
        nodes.push(children.slice(prevEnd, match.index))
      }
      nodes.push(
        <DiscoLink
          key={match.index}
          to={match[0]}
          target={"_blank"}
          rel={"noreferrer noopener"}
          className={classes.link}
        >
          {match[0]}
        </DiscoLink>
      )
      prevEnd = match.index! + match[0].length
    }
    if (prevEnd < children.length) {
      nodes.push(children.slice(prevEnd, children.length))
    }
  }

  return <DiscoText {...rest}>{nodes}</DiscoText>
}

const useStyles = makeUseStyles({
  link: {
    fontSize: "inherit",
    lineHeight: "inherit",
  },
})
