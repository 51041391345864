/**
 * @generated SignedSource<<7b886999b09f351c3f56de5578e418c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InteractivePlayerTranscriptTabContentFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TranscriptReaderFragment">;
  readonly " $fragmentType": "InteractivePlayerTranscriptTabContentFragment";
};
export type InteractivePlayerTranscriptTabContentFragment$key = {
  readonly " $data"?: InteractivePlayerTranscriptTabContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InteractivePlayerTranscriptTabContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InteractivePlayerTranscriptTabContentFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TranscriptReaderFragment"
    }
  ],
  "type": "AssetTranscription",
  "abstractKey": null
};

(node as any).hash = "4411eb2073d5d778c7f70df9d216e27b";

export default node;
