import { useAssetSubtitlesModal } from "@/admin/asset/tracks/AssetSubtitlesModalContext"
import AssetTracksList from "@/admin/asset/tracks/AssetTracksList"
import CreateAssetTrackForm from "@/admin/asset/tracks/CreateAssetTrackForm"
import UpdateAssetTrackForm from "@/admin/asset/tracks/UpdateAssetTrackForm"
import DiscoModal from "@disco-ui/modal/DiscoModal"
import { InteractivePlayerSubtitlesTabContentFragment$key } from "@disco-ui/video/interactive-player/tabs/__generated__/InteractivePlayerSubtitlesTabContentFragment.graphql"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  assetKey: InteractivePlayerSubtitlesTabContentFragment$key
}

function InteractivePlayerSubtitlesTabContent(props: Props) {
  const { assetKey } = props
  const { modalState, closeModal } = useAssetSubtitlesModal()

  const asset = useFragment<InteractivePlayerSubtitlesTabContentFragment$key>(
    graphql`
      fragment InteractivePlayerSubtitlesTabContentFragment on Asset {
        id
      }
    `,
    assetKey
  )

  return (
    <>
      <AssetTracksList assetId={asset.id} />
      {modalState?.view && (
        <DiscoModal
          isOpen={modalState !== null}
          testid={"AssetTracksModal"}
          modalContentLabel={getModalName()}
          title={getModalName()}
          minHeight={"unset"}
          body={
            modalState!.view === "create" ? (
              <CreateAssetTrackForm assetId={asset.id} onClose={closeModal} />
            ) : modalState!.view === "update" ? (
              <UpdateAssetTrackForm onClose={closeModal} />
            ) : null
          }
          onClose={closeModal}
          buttons
          width={"720px"}
          maxWidth={"90vw"}
        />
      )}
    </>
  )

  function getModalName() {
    if (modalState?.view === "create") {
      return "Add Subtitle"
    } else if (modalState?.view === "update") {
      return "Update Subtitle"
    }
    return ""
  }
}

export default InteractivePlayerSubtitlesTabContent
