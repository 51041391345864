import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoText from "@disco-ui/text/DiscoText"
import DiscoTooltip from "@disco-ui/tooltip/DiscoTooltip"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import React from "react"

type Data = {
  label: string
  frequency: number
  percentage: number
}

const HORIZONTAL_BAR_CHART_MAX_WIDTH = 550
interface DiscoHorizontalBarChartProps<T extends Data> extends TestIDProps {
  data: T[]
  classes?: Partial<ClassNameMap<"chart">>
  renderLabel?: (data: T) => React.ReactNode
  renderTooltipContent?: (data: T) => React.ReactNode
}

function DiscoSimpleHorizontalBarChart<T extends Data>({
  testid = "DiscoHorizontalBarChart",
  data: datum,
  classes: customClasses,
  renderLabel,
  renderTooltipContent,
}: DiscoHorizontalBarChartProps<T>) {
  const classes = useStyles()
  return (
    <div data-testid={testid} className={classNames(classes.chart, customClasses?.chart)}>
      {datum.map((data, index) => (
        <div key={data.label}>
          <div data-testid={`${testid}.${index}`} className={classes.chartHeader}>
            {renderLabel?.(data) ?? (
              <DiscoText testid={`${testid}.${index}.label`} variant={"body-sm"}>
                {index}
              </DiscoText>
            )}
            <DiscoText
              testid={`${testid}.${index}.percentage`}
              variant={"body-sm"}
            >{`${data.percentage}%`}</DiscoText>
          </div>
          <DiscoTooltip content={renderTooltipContent?.(data) ?? ""}>
            <div>
              <DiscoSimpleHorizontalBarChart.Bar percentage={data.percentage} />
            </div>
          </DiscoTooltip>
        </div>
      ))}
    </div>
  )
}
const useStyles = makeUseStyles((theme) => ({
  chart: {
    width: "100%",
    maxWidth: HORIZONTAL_BAR_CHART_MAX_WIDTH,
    display: "grid",
    rowGap: theme.spacing(2.5),
  },
  chartHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
}))

interface BarProps {
  percentage: number
}
const Bar = React.forwardRef<HTMLDivElement, BarProps & TestIDProps>(
  ({ testid = "DiscoSimpleHorizontalBar", percentage }, ref) => {
    const classes = useBarStyles({ percentage })
    return (
      <div ref={ref} data-testid={testid} className={classes.container}>
        <div className={classes.bar} />
      </div>
    )
  }
)
const useBarStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(100, 1fr)",
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.default,
  },
  bar: {
    height: "32px",
    // if percentage is 0, hide the bar
    gridColumn: ({ percentage }: BarProps) =>
      percentage ? `span ${Math.round(percentage)}` : "-1",
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.measure.borderRadius.default,
  },
}))
DiscoSimpleHorizontalBarChart.Bar = Bar
export default DiscoSimpleHorizontalBarChart
