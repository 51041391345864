import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import Format from "../core/format/format"
import { Discount_displayValue$key } from "./__generated__/Discount_displayValue.graphql"

/** The Discount object in our schema */
interface Discount {
  name: string
  valueKind: Discount.VALUE_KIND
  valueAmount: number
  code: string | null
}

/** Namespace for Discount-related utilities */
namespace Discount {
  export enum VALUE_KIND {
    "DOLLARS" = "dollars",
    "PERCENT" = "percent",
  }

  /** Get the user-friendly value for a discount's value in dollars/percent. */
  export function useDisplayValue(
    discountKey: Discount_displayValue$key | null,
    currency?: string
  ): string {
    const discount = useFragment<Discount_displayValue$key>(
      graphql`
        fragment Discount_displayValue on Discount {
          valueKind
          valueAmount
        }
      `,
      discountKey
    )
    if (!discount) return ""
    const { valueKind, valueAmount } = discount
    switch (valueKind) {
      case Discount.VALUE_KIND.DOLLARS:
        return Format.asCurrency(valueAmount, { currency })
      case Discount.VALUE_KIND.PERCENT:
        return Format.asPercent(valueAmount)
      default:
        throw new Error(`Unknown discount.valueKind: ${valueKind}`)
    }
  }
}

export default Discount
