/**
 * @generated SignedSource<<5fd257f8a2604969d18afdb0accd0739>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssetTranscriptionJobSieveJobErrorReason = "no_audio" | "generic" | "%future added value";
export type AssetTranscriptionJobStatus = "pending" | "completed" | "failed" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InteractivePlayerTranscriptionStatusFragment$data = {
  readonly id: string;
  readonly videoAssetsEnabledAt: string | null;
  readonly transcriptionJob: {
    readonly status: AssetTranscriptionJobStatus;
    readonly sieveJobErrorReason: AssetTranscriptionJobSieveJobErrorReason | null;
  } | null;
  readonly transcription: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "InteractivePlayerTranscriptionStatusFragment";
};
export type InteractivePlayerTranscriptionStatusFragment$key = {
  readonly " $data"?: InteractivePlayerTranscriptionStatusFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InteractivePlayerTranscriptionStatusFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InteractivePlayerTranscriptionStatusFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoAssetsEnabledAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetTranscriptionJob",
      "kind": "LinkedField",
      "name": "transcriptionJob",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sieveJobErrorReason",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetTranscription",
      "kind": "LinkedField",
      "name": "transcription",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Asset",
  "abstractKey": null
};
})();

(node as any).hash = "36035b6b248c16387543d6a8f4779156";

export default node;
