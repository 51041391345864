import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { TableRow, TableRowProps } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { ReactNode } from "react"

interface DiscoTableRowProps extends TestIDProps, TableRowProps {
  children: ReactNode
  header?: boolean
}

function DiscoTableRow({
  children,
  testid = "DiscoTableRow",
  hover = true,
  ...rest
}: DiscoTableRowProps) {
  const classes = useStyles({
    clickable: Boolean(rest.onClick),
    hover,
  })
  return (
    <TableRow
      classes={{
        root: classes.root,
      }}
      data-testid={testid}
      {...rest}
    >
      {children}
    </TableRow>
  )
}

type StyleProps = {
  clickable: boolean
  hover: boolean
}

const useStyles = makeUseStyles((theme) => ({
  root: ({ clickable, hover }: StyleProps) => ({
    "& td": {
      ...styleIf(clickable, {
        cursor: "pointer",
      }),
    },
    "&:hover": {
      ...styleIf(hover, {
        backgroundColor:
          theme.palette.type === "dark"
            ? theme.palette.groovy.onDark[500]
            : theme.palette.groovy.neutral[100],
      }),

      /** header border radius */
      "& th": {
        "&:first-child": {
          borderTopLeftRadius: theme.measure.borderRadius.default,
          borderBottomLeftRadius: theme.measure.borderRadius.default,
        },
        "&:last-child": {
          borderTopRightRadius: theme.measure.borderRadius.default,
          borderBottomRightRadius: theme.measure.borderRadius.default,
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiTableCell-body:nth-child(1)": {
        paddingLeft: 0,
      },
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
}))

export default DiscoTableRow
