import makeUseStyles from "@assets/style/util/makeUseStyles"
import AvatarPlaceholder from "@components/avatar/placeholder/AvatarPlaceholder"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMultiSelect, {
  DiscoMultiSelectOption,
  DiscoMultiSelectProps,
} from "@disco-ui/select/DiscoMultiSelect"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { AutocompleteGetTagProps } from "@material-ui/lab"
import { EMAIL_REGEX } from "@utils/string/stringConstants"
import { TestIDProps } from "@utils/typeUtils"

interface DiscoEmailMultiSelectProps
  extends TestIDProps,
    Partial<Pick<DiscoMultiSelectProps, "renderOption" | "options" | "placeholder">> {
  values: string[]
  hasErrors?: boolean
  onChange: (emails: string[]) => void
}

function DiscoEmailMultiSelect(props: DiscoEmailMultiSelectProps) {
  const {
    testid = "DiscoEmailMultiSelect",
    placeholder,
    values,
    hasErrors,
    onChange,
    options,
    renderOption,
  } = props

  const classes = useStyles()

  // Create email options to allow default email values in multiselect
  const emailOptions: DiscoMultiSelectOption[] = values.map((email) => ({
    title: email,
    value: email,
  }))

  return (
    <DiscoMultiSelect
      freeSolo={true}
      testid={testid}
      placeholder={placeholder || "Enter email here"}
      values={values}
      classes={hasErrors ? { inputRoot: classes.inputRoot } : undefined}
      onChange={handleChange}
      renderTags={renderTags}
      options={options || emailOptions}
      renderOption={renderOption || defaultRenderOption}
      filterOptions={{ suggestionMatcher: EMAIL_REGEX }}
      additionalSubmitKeys={[",", " "]}
    />
  )

  function handleChange(emails: string[]) {
    onChange(emails)
  }

  function renderTags(
    value: DiscoMultiSelectOption[],
    getTagProps: AutocompleteGetTagProps
  ) {
    return value.map((o, index) => (
      <DiscoTag
        key={o.value}
        testid={`${testid}.option.remove.${o.title}`}
        {...getTagProps({ index })}
        name={o.title}
      />
    ))
  }

  function defaultRenderOption(option: DiscoMultiSelectOption) {
    return (
      <DiscoDropdownItem
        testid={testid}
        icon={
          <AvatarPlaceholder
            testid={`${testid}.avatar`}
            text={option.title[0]}
            size={40}
          />
        }
        title={option.title}
        subtitle={option.value}
      />
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  inputRoot: {
    border: `1.5px solid ${theme.palette.error.dark} !important`,
    boxShadow: "none",
  },
}))

export default DiscoEmailMultiSelect
