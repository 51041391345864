import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import { DiscoButtonProps } from "@disco-ui/button/DiscoButton"
import { Popover, PopoverOrigin, PopoverProps } from "@material-ui/core"
import classNames from "classnames"
import React, { createContext, useContext, useEffect, useRef, useState } from "react"

export const DropdownContext = createContext<{
  isParentOpen: boolean
  closeParent: (event: React.MouseEvent<HTMLElement>) => void
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ isParentOpen: false, closeParent: () => {} })

export const useDropdownContext = () => useContext(DropdownContext)

type MenuButtonProps = {
  onClick: (
    event: React.MouseEvent<
      HTMLDivElement | HTMLLIElement | HTMLButtonElement,
      MouseEvent
    >
  ) => void
  isOpen: boolean
} & Pick<DiscoButtonProps, "className" | "disabled" | "shouldDisplaySpinner">

export interface DiscoDropdownProps
  extends Omit<PopoverProps, "open" | "children" | "classes"> {
  open?: boolean
  children: React.ReactNode
  menuButton: (renderProps: MenuButtonProps) => JSX.Element
  menuClasses?: PopoverProps["classes"]
  vertical?: PopoverOrigin["vertical"]
  horizontal?: PopoverOrigin["horizontal"]
  testid?: string
  disableCloseOnClick?: boolean
  // because DiscoDropdown renders the menuButton, need to pass it these props
  // this way DiscoDropdown rerenders the button when their state changes
  disabled?: boolean
  shouldDisplaySpinner?: boolean
  /**
   * Always keep the children components mounted, even when the dropdown is closed
   * This may be necessary if children contain modals or other components that
   * are opened without the dropdown being opened
   */
  keepMounted?: boolean
  /**
   * Only mount the children components once the dropdown is opened, which
   * can be helpful to avoid running queries until opened
   */
  mountChildrenOnOpen?: boolean
  isNested?: boolean
  transformOrigin?: PopoverProps["transformOrigin"]
}

const DiscoDropdown = React.forwardRef<HTMLDivElement, DiscoDropdownProps>(
  function DiscoDropdown(
    {
      children,
      testid,
      menuButton,
      menuClasses,
      keepMounted,
      disableCloseOnClick,
      disabled,
      shouldDisplaySpinner,
      mountChildrenOnOpen = false,
      isNested = false,
      transformOrigin,
      ...props
    },
    ref
  ) {
    const { isParentOpen, closeParent } = useDropdownContext()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl && (!isNested || isParentOpen))

    // Track if we've ever opened the dropdown
    const hasOpened = useRef(false)
    hasOpened.current = hasOpened.current || open

    const classes = useStyles()

    useEffect(() => {
      if (isNested && !isParentOpen) {
        setAnchorEl(null)
      }
    }, [isNested, isParentOpen])

    return (
      <DropdownContext.Provider
        value={{
          isParentOpen: open,
          closeParent: handleClose,
        }}
      >
        {React.createElement(menuButton, {
          onClick: handleClick,
          className: classNames({ [classes.openMenuButton]: open }),
          disabled,
          shouldDisplaySpinner,
          isOpen: open,
        })}
        <Popover
          ref={ref}
          anchorEl={anchorEl}
          open={open}
          classes={mergeClasses({ paper: classes.paper }, menuClasses)}
          onClose={handleClose}
          elevation={1}
          // Only keep it mounted when it has been opened or if prop is set
          keepMounted={keepMounted || Boolean(hasOpened.current)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: props.vertical ?? isNested ? "top" : "bottom",
            horizontal: props.horizontal ?? "right",
          }}
          transformOrigin={
            transformOrigin ?? {
              vertical: "top",
              horizontal: isNested ? "left" : "right",
            }
          }
          onClick={handleCloseOnClick}
          data-testid={testid}
          disableEnforceFocus
          {...props}
        >
          <ul role={"menu"} data-testid={`${testid}.ul-wrapper`}>
            {/* When avoiding mounting until open, keep the children mounted
             even if closed after the first open. This avoids a weird animation where
             the contents of the dropdown disappear while it shrinks */}
            {mountChildrenOnOpen && !hasOpened.current ? null : children}
          </ul>
        </Popover>
      </DropdownContext.Provider>
    )

    function handleClick(
      event: React.MouseEvent<
        HTMLDivElement | HTMLLIElement | HTMLButtonElement,
        MouseEvent
      >
    ) {
      event.preventDefault()
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    }

    function handleCloseOnClick(event: React.MouseEvent<HTMLElement>) {
      if (disableCloseOnClick) return
      handleClose(event)
      if (isNested) closeParent(event)
    }

    function handleClose(event: React.MouseEvent<HTMLElement>) {
      event.stopPropagation()
      setAnchorEl(null)
    }
  }
)

const useStyles = makeUseStyles((theme) => ({
  paper: {
    maxWidth: "320px",
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.insideCard,
    backgroundColor: theme.palette.background.paper,
    padding: `${theme.spacing(1.5, 2)} !important`,
    "& li": {
      textAlign: "left",
      height: "40px",
      margin: theme.spacing(0.125, 0),
      width: "100%",
      padding: theme.spacing(1),
      borderRadius: theme.measure.borderRadius.big,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: theme.palette.groovy.neutral[100],
        color: theme.palette.text.primary,
      },
      "& a": {
        width: "100%",
      },
    },
  },
  // When menu button is open, never let it hide
  openMenuButton: {
    visibility: "visible !important",
  },
}))

export default DiscoDropdown
