import DiscoIcon from "@disco-ui/icon/DiscoIcon"
import { DiscoIconToIconsaxMap } from "@disco-ui/icon/iconMaps"

export type DiscoIconPickerIcons = keyof typeof pickerIcons

// Key: the name of the icon
// Value: human-readable name for the icon
export const pickerIcons = {
  "3d-box": "3D Box",
  achievement: "Achievement",
  alarm: "Alarm",
  archive: "Archive",
  "archive-load": "Archive with Arrow",
  "arrow-stem-right": "Arrow Right",
  attachment: "Attachment",
  bag: "Bag",
  "book-check": "Book with Checkmark",
  "book-closed": "Closed book",
  "book-open": "Open book",
  bookmark: "Bookmark",
  box: "Box",
  camera: "Camera",
  "chart-bar": "Bar Chart",
  "chart-pie": "Pie Chart",
  check: "Checkmark",
  code: "Code",
  compass: "Compass",
  "cursor-click": "Clicking Cursor",
  database: "Database",
  "date-range": "Calendar",
  diamond: "Diamond",
  email: "Email",
  event: "Circle",
  favourite: "Heart",
  file: "Document",
  "file-dock": "Clipboard",
  flag: "Flag",
  folder: "Folder",
  "folder-dock": "Folder",
  gift: "Gift",
  "graduation-cap": "Graduation",
  "group-share": "Group",
  headphone: "Headphone",
  image: "Image",
  inbox: "Inbox",
  layer: "Layers",
  lightbulb: "Lightbulb",
  lightning: "Lightning",
  link: "Link",
  lock: "Lock",
  mic: "Microphone",
  moon: "Moon",
  music: "Music",
  page: "Page",
  paper: "Paper",
  pen: "Ink Pen",
  pencil: "Pencil",
  phone: "Phone",
  pin: "Pin",
  play: "Play",
  pound: "Channel",
  "puzzle-piece": "Puzzle Piece",
  save: "Save",
  shield: "Shield",
  "sound-max": "Volume",
  star: "Star",
  stars: "Stars",
  store: "Store",
  sun: "Sun",
  ticket: "Ticket",
  timer: "Timer",
  user: "User",
  "video-square": "Video",
  wallet: "Wallet",
} as const

export const DiscoIconPickerIcons = Object.keys(DiscoIconToIconsaxMap).reduce(
  (acc, icon) => {
    acc[icon as DiscoIconPickerIcons] = {
      default: <DiscoIcon icon={icon as DiscoIconPickerIcons} />,
      active: <DiscoIcon icon={icon as DiscoIconPickerIcons} active />,
    }
    return acc
  },
  {} as Record<DiscoIconPickerIcons, { default: JSX.Element; active: JSX.Element }>
)
