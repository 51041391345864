import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { DiscoTabProps } from "@disco-ui/tabs/tab/DiscoTab"
import { useQueryParams } from "@utils/url/urlUtils"
import { useCallback, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"

export type QueryParamTab<T extends Record<string, string>> = {
  label: string
  params: Partial<T>
  testid: string
  content?: React.ReactElement
  notifications?: number
  count?: number
  notificationConfig?: DiscoTabProps["notificationConfig"]
}

export type QueryParamAction = "push" | "replace"

interface Props {
  tabs: QueryParamTab<Record<string, string>>[]
  className?: string
  testid: string
  size?: DiscoTabProps["size"]
  tabVariant?: DiscoTabProps["variant"]
  allowTabNavigationWithUnsavedChanges?: boolean
}

function DiscoQueryParamTabs(props: Props) {
  const {
    tabs,
    className,
    testid,
    tabVariant,
    size,
    allowTabNavigationWithUnsavedChanges = true,
  } = props

  const { handleLeave } = useUnsavedChangesModalContext()

  const [params, setParams] = useQueryParamState<Record<string, string>>()

  const discoTabs = tabs.map((tab) => ({
    label: tab.label,
    onClick: () => {
      if (allowTabNavigationWithUnsavedChanges) setParams(tab.params)
      else {
        handleLeave({
          onLeave: () => setParams(tab.params),
        })
      }
    },
    testid: tab.testid,
    active: Object.keys(tab.params).every((key) => tab.params[key] === params[key]),
    content: tab.content,
    notifications: tab.notifications,
    count: tab.count,
    notificationConfig: tab.notificationConfig,
  }))

  const activeTab = discoTabs.find((t) => t.active)

  // Select first tab by default.
  useEffect(() => {
    if (!activeTab) {
      setParams(tabs[0].params, "replace")
    }
  }, [activeTab, tabs, setParams])

  return (
    <>
      <DiscoTabs
        testid={testid}
        routes={discoTabs}
        className={className}
        size={size}
        tabVariant={tabVariant}
      />
      {activeTab?.content}
    </>
  )
}

export default DiscoQueryParamTabs

/** Use query param key values as state. */
export function useQueryParamState<T extends Record<string, string | undefined>>(): [
  T,
  (value: Partial<T>, action?: QueryParamAction) => void
] {
  const params = useQueryParams<T>()

  const location = useLocation()
  const history = useHistory()
  const setParams = useCallback(
    (p: Partial<T>, action: QueryParamAction = "push") => {
      // Use window.location instead of from the hook so successive calls to this won't undo
      // previous changes, such as when using drawer.close() followed by drawer.open()
      const searchParams = new URLSearchParams(window.location.search)
      Object.entries(p).forEach(([key, value]) => {
        if (value) {
          searchParams.set(key, value)
        } else {
          searchParams.delete(key)
        }
      })
      // Only replace the search params
      history[action]({ ...location, search: searchParams.toString() })
    },
    [location, history]
  )

  return [params, setParams]
}
