import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { TextVariantWithModifiers } from "@assets/style/appMuiTheme"
import makeTypographyStyles from "@assets/style/util/makeTypographyStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import useTruncateStyles from "@assets/style/util/useTruncateStyles"
import { DiscoIcon, DiscoIconKinds, DiscoLink, DiscoLinkProps } from "@disco-ui"
import { Button, ButtonClassKey, ButtonProps, useTheme } from "@material-ui/core"
import classNames from "classnames"
import React from "react"

type ColorVariant =
  | "primary"
  | "warning"
  | "danger"
  | "success"
  | "grey"
  | "white"
  | "currentColor"
export interface DiscoTextButtonProps extends Omit<ButtonProps, "color" | "variant"> {
  leftIcon?: React.ReactElement | DiscoIconKinds
  rightIcon?: React.ReactElement | DiscoIconKinds
  testid?: string
  classes?: ButtonProps["classes"]
  to?: DiscoLinkProps["to"]
  target?: string
  rel?: string
  linkClassname?: React.HTMLAttributes<HTMLLinkElement>["className"]
  leftIconClassname?: string
  rightIconClassname?: string
  variant?: ColorVariant
  textVariant?: TextVariantWithModifiers
  /** number of lines to truncate text */
  truncateText?: number
}

const DiscoTextButton = React.forwardRef<
  HTMLButtonElement,
  DiscoTextButtonProps & StyleProps
>((props, ref) => {
  const {
    children,
    color,
    classes,
    leftIcon,
    leftIconClassname,
    iconSize,
    rightIcon,
    rightIconClassname,
    testid,
    to,
    linkClassname,
    variant = "primary",
    textVariant = "body-md",
    truncateText,
    ...extra
  } = props

  const theme = useTheme()

  const mapVariantToColor: { [k in ColorVariant]: React.CSSProperties["color"] } = {
    primary: theme.palette.primary.main,
    warning: theme.palette.warning.main,
    danger: theme.palette.error.main,
    success: theme.palette.success.main,
    grey: theme.palette.groovy.grey[400],
    white: theme.palette.common.white,
    currentColor: "currentColor",
  }

  const defaultClasses = useStyles({
    color: color || mapVariantToColor[variant],
    iconSize,
  })
  const typographyClasses = useTypographyStyles()
  const { truncateMultipleLines } = useTruncateStyles({
    width: "100%",
    numberOfLines: truncateText,
  })

  const content = (
    <>
      {leftIcon && (
        <div className={classNames(defaultClasses.leftIcon, leftIconClassname)}>
          {typeof leftIcon === "string" ? <DiscoIcon icon={leftIcon} /> : leftIcon}
        </div>
      )}
      {children}
      {rightIcon && (
        <div className={classNames(defaultClasses.rightIcon, rightIconClassname)}>
          {typeof rightIcon === "string" ? <DiscoIcon icon={rightIcon} /> : rightIcon}
        </div>
      )}
    </>
  )

  return (
    <Button
      ref={ref}
      classes={mergeClasses<ButtonClassKey>(
        classes,
        { root: defaultClasses.root, label: defaultClasses.label },
        {
          label: classNames(typographyClasses[textVariant], {
            [truncateMultipleLines]: Boolean(truncateText),
          }),
        }
      )}
      data-testid={testid}
      {...extra}
    >
      {to ? (
        <DiscoLink
          to={to}
          target={props.target}
          className={classNames(
            defaultClasses.label,
            linkClassname,
            typographyClasses[textVariant],
            { [truncateMultipleLines]: Boolean(truncateText) }
          )}
        >
          {content}
        </DiscoLink>
      ) : (
        content
      )}
    </Button>
  )
})

type StyleProps = {
  color?: React.CSSProperties["color"]
  iconSize?: number
}

const useStyles = makeUseStyles((theme) => ({
  root: ({ color }: StyleProps) => ({
    color,
    textTransform: "none",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-disabled": {
      color: theme.palette.groovy.grey[300],
      "& svg ": {
        color: theme.palette.groovy.grey[300],
      },
    },
  }),
  label: {
    display: "flex",
    alignItems: "center",
    lineHeight: 1,
    color: "inherit",
    "&:visited": {
      color: "inherit",
    },
  },
  leftIcon: ({ color, iconSize = 24 }: StyleProps) => ({
    marginRight: theme.spacing(0.5),
    height: `${iconSize}px`,
    "& > svg": {
      height: `${iconSize}px`,
      width: `${iconSize}px`,
      color,
    },
  }),
  rightIcon: ({ color, iconSize = 24 }: StyleProps) => ({
    marginLeft: theme.spacing(0.5),
    height: `${iconSize}px`,
    "& > svg": {
      height: `${iconSize}px`,
      width: `${iconSize}px`,
      color,
    },
  }),
}))

const useTypographyStyles = makeTypographyStyles()
export default DiscoTextButton
