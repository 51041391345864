import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import styleIf from "@assets/style/util/styleIf"
import { FormControlLabel, FormControlLabelProps, Switch } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import classNames from "classnames"
import React, { ComponentProps } from "react"
import DiscoText from "../text/DiscoText"

type DiscoSwitchProps = {
  label?: React.ReactNode | string
  labelPlacement?: LabelPlacement
  checked?: boolean
  onChange?(checked: boolean, event: React.ChangeEvent<HTMLInputElement>): void
  sublabel?: React.ReactNode | string
  hideForm?: boolean
  /**
   * If true, the switch label will be shown as a strikethrough when checked
   */
  strikethrough?: boolean
  testid?: string
  /** Allow an array of switches on the same form */
  multipleSwitches?: Omit<DiscoSwitchProps, "multipleSwitches">[]
} & Omit<FormControlLabelProps, "control" | "onChange" | "label" | "labelPlacement">

type LabelPlacement = "start" | "end"

const DiscoSwitch: React.FC<DiscoSwitchProps> = (props) => {
  const {
    checked,
    onChange,
    label,
    labelPlacement = "start",
    sublabel,
    hideForm = false,
    strikethrough,
    testid = "DiscoSwitch",
    multipleSwitches,
    classes: customClasses,
    ...rest
  } = props

  const classes = useStyles({
    isDisabled: rest.disabled || false,
    labelPlacement: label ? labelPlacement : null,
  })
  const switchClasses: Record<keyof ComponentProps<typeof Switch>["classes"], string> = {
    root: classes.root,
    switchBase: classes.switchBase,
    checked: classes.checked,
    thumb: classes.thumb,
    track: classes.track,
  }

  return (
    <>
      {hideForm ? (
        <Switch
          disableRipple
          classes={switchClasses}
          checked={checked}
          color={"default"}
          onChange={onChange ? (e) => onChange(!checked, e) : undefined}
          data-testid={`${testid}.${checked ? "checked" : "unchecked"}`}
          disabled={rest.disabled}
        />
      ) : (
        <>
          <FormControlLabel
            {...rest}
            classes={mergeClasses({ root: classes.controlLabel }, customClasses)}
            control={
              multipleSwitches?.length ? (
                <div className={classes.switches}>
                  {multipleSwitches.map((s, index) => (
                    <Switch
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      classes={switchClasses}
                      data-testid={s.testid}
                      checked={s.checked}
                      onChange={
                        s.onChange ? (e) => s.onChange!(!s.checked, e) : undefined
                      }
                      disabled={s.disabled}
                    />
                  ))}
                </div>
              ) : (
                <Switch
                  disableRipple
                  color={"default"}
                  classes={switchClasses}
                  checked={checked}
                  onChange={onChange ? (e) => onChange(!checked, e) : undefined}
                  data-testid={`${testid}.${checked ? "checked" : "unchecked"}`}
                />
              )
            }
            label={
              typeof label === "string" ? (
                <DiscoText
                  data-testid={`${testid}.label`}
                  variant={"body-sm"}
                  className={classNames({
                    [classes.strikethrough]: checked && strikethrough,
                  })}
                  color={"text.secondary"}
                >
                  {label}
                </DiscoText>
              ) : (
                label
              )
            }
          />
          {sublabel &&
            (typeof sublabel === "string" ? (
              <div className={classes.sublabel}>
                <DiscoText variant={"body-sm"}>{sublabel}</DiscoText>
              </div>
            ) : (
              sublabel
            ))}
        </>
      )}
    </>
  )
}

export const DiscoSwitchSkeleton: React.FC = () => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Skeleton variant={"circle"} width={20} height={20} />
    </div>
  )
}

type StyleProps = {
  isDisabled: boolean
  labelPlacement: LabelPlacement | null
}

const useStyles = makeUseStyles((theme) => ({
  controlLabel: ({ labelPlacement }: StyleProps) => ({
    flexDirection: labelPlacement === "start" ? "row-reverse" : "row",
    justifyContent: "space-between",
    marginLeft: 0,
    marginRight: 0,
  }),
  root: ({ labelPlacement }: StyleProps) => ({
    width: 40,
    height: 24,
    ...styleIf(labelPlacement === "start", {
      marginLeft: theme.spacing(2),
    }),
    ...styleIf(labelPlacement === "end", {
      marginRight: theme.spacing(1),
    }),
    padding: 0,
    display: "flex",
  }),
  sublabel: {
    paddingLeft: theme.spacing(4),
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(2),
  },
  switchBase: ({ isDisabled }: StyleProps) => ({
    padding: 2,
    background: "transparent",
    "&$checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + $track": {
        opacity: 1,
        backgroundColor: isDisabled
          ? theme.palette.primary.light
          : theme.palette.primary.main,
      },
    },
  }),
  checked: () => ({
    transform: "translateX(16px)",
    color: "#fff",
  }),
  strikethrough: {
    textDecoration: "line-through",
    color: theme.palette.groovy.grey[200],
  },
  thumb: {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 10,
    color: "#fff",
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  /** Wrap in a arrow function somehow increases the specificity */
  track: ({ isDisabled }: StyleProps) => ({
    borderRadius: 25,
    opacity: `1 !important`, // important needed because mui provides more specificity for classes that change opacity when disabled
    backgroundColor: isDisabled
      ? theme.palette.groovy.neutral[100]
      : theme.palette.groovy.neutral[300],
  }),
  switches: {
    display: "flex",
  },
}))

export default DiscoSwitch
