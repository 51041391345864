import FormStore from "@/core/form/store/FormStore"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import DiscoTextButton from "@disco-ui/button/DiscoTextButton"
import DiscoFormControl from "@disco-ui/form-control/DiscoFormControl"
import DiscoSpinner from "@disco-ui/spinner/DiscoSpinner"
import DiscoTextField from "@disco-ui/text-field/DiscoTextField"
import DiscoText from "@disco-ui/text/DiscoText"
import { InteractivePlayerOverviewTabContentFragment$key } from "@disco-ui/video/interactive-player/tabs/__generated__/InteractivePlayerOverviewTabContentFragment.graphql"
import {
  DiscoInteractivePlayerFormMutation,
  UpdateAssetTranscriptionInput,
} from "@disco-ui/video/interactive-player/__generated__/DiscoInteractivePlayerFormMutation.graphql"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { Suspense } from "react"
import { graphql, useFragment } from "react-relay"
import { DiscoInteractivePlayerMode } from "../DiscoInteractivePlayer"

interface Props extends TestIDProps {
  assetTranscriptionKey: InteractivePlayerOverviewTabContentFragment$key | null
  form: FormStore<UpdateAssetTranscriptionInput, DiscoInteractivePlayerFormMutation>
  mode: DiscoInteractivePlayerMode
  onTimeChange: (time: number) => void
}

function InteractivePlayerOverviewTabContent(props: Props) {
  const {
    assetTranscriptionKey,
    form,
    mode,
    onTimeChange,
    testid = "InteractivePlayerOverviewTabContent",
  } = props
  const classes = useStyles()

  const transcription = useFragment<InteractivePlayerOverviewTabContentFragment$key>(
    graphql`
      fragment InteractivePlayerOverviewTabContentFragment on AssetTranscription {
        id
        summary
        chapters {
          title
          startTime
          timecode
        }
      }
    `,
    assetTranscriptionKey
  )

  return (
    <>
      <DiscoFormControl title={"Summary"}>
        {/** To prevent the attach blocks query from suspending the whole drawer  */}
        <Suspense fallback={<DiscoSpinner />}>
          {mode === "edit" ? (
            <DiscoEditor
              key={mode}
              config={"video_summary"}
              testid={testid}
              defaultValue={form.state.summary}
              placeholder={"Add a summary"}
              onChange={(v) => (form.state.summary = v)}
            />
          ) : transcription?.summary ? (
            <DiscoEditor
              key={mode}
              config={"video_summary"}
              testid={testid}
              defaultValue={transcription?.summary}
              placeholder={"Add a summary"}
              readOnly
              disableGutter
            />
          ) : (
            <DiscoText className={classes.text}>{"No Summary"}</DiscoText>
          )}
        </Suspense>
      </DiscoFormControl>
      <DiscoFormControl title={"Chapters"} errorMessages={form.errorsByField.chapters}>
        {mode === "edit" ? (
          <DiscoTextField
            error={Boolean(form.errorsByField.chapters)}
            className={classes.chaptersInput}
            value={form.state.chaptersText}
            placeholder={"00:00 Chapter 1\n00:10 Chapter 2\n00:20 Chapter 3"}
            onChange={(e) => (form.state.chaptersText = e.target.value)}
            multiline
            minRows={3}
          />
        ) : transcription?.chapters?.length ? (
          <div className={classes.chapters}>
            {transcription.chapters.map((c) => (
              <div key={c.startTime} className={classes.chapter}>
                <DiscoTextButton
                  onClick={() => onTimeChange(c.startTime)}
                  className={classes.timecode}
                >
                  {c.timecode}
                </DiscoTextButton>
                <DiscoText className={classes.text}>{c.title}</DiscoText>
              </div>
            ))}
          </div>
        ) : (
          <DiscoText className={classes.text}>{"No chapters"}</DiscoText>
        )}
      </DiscoFormControl>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  chapters: {
    padding: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  chapter: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  text: {
    color:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[100]
        : theme.palette.text.secondary,
  },
  timecode: {
    textAlign: "right",
    // Make all numbers to have the same space, this makes the timecode align vertically
    fontVariantNumeric: "tabular-nums",
    color:
      theme.palette.type === "dark"
        ? theme.palette.groovy.blue[300]
        : theme.palette.primary.main,
  },
  chaptersInput: {
    // Make all numbers to have the same space, this makes the timecode align vertically
    fontVariantNumeric: "tabular-nums",
    "& textarea": {
      lineHeight: "24px",
    },
  },
}))

export default observer(InteractivePlayerOverviewTabContent)
