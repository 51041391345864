import ChevronLeftIcon from "@/core/ui/iconsax/linear/arrow-left-1.svg"
import ChevronRightIcon from "@/core/ui/iconsax/linear/arrow-right-1.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import DiscoIconButton from "@disco-ui/button/DiscoIconButton"
import { DiscoIconKinds } from "@disco-ui/icon/DiscoIcon"
import DiscoTab, { DiscoTabProps } from "@disco-ui/tabs/tab/DiscoTab"
import { DiscoTextSkeleton } from "@disco-ui/text/DiscoText"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { range } from "@utils/array/arrayUtils"
import { hasHorizontalScrolls } from "@utils/dom/domUtils"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useOnWindowResize from "@utils/hook/useOnWindowResize"
import classNames from "classnames"
import { useCallback, useRef, useState } from "react"
import { useLocation } from "react-router-dom"

export interface DiscoTabsRoute {
  label?: string
  pathname?: string
  onClick?: () => void
  testid: string
  active?: boolean
  notificationConfig?: DiscoTabProps["notificationConfig"]
  leftIcon?: DiscoIconKinds | React.ReactElement
  rightContent?: React.ReactElement
  notifications?: number
  count?: number
  activeColor?: string
  tabClassname?: string
}

interface DiscoTabsProps {
  routes: DiscoTabsRoute[]
  testid: string
  exact?: boolean
  className?: string
  tabClassname?: string
  tabVariant?: DiscoTabProps["variant"]
  size?: DiscoTabProps["size"]
  classes?: ClassNameMap<"arrowsContainer">
  fullWidth?: boolean
  countBadgeProps?: DiscoTabProps["countBadgeProps"]
}

function DiscoTabs({
  routes,
  testid,
  exact,
  className,
  tabClassname,
  tabVariant,
  size,
  classes: customClasses,
  fullWidth = false,
  countBadgeProps,
}: DiscoTabsProps) {
  const componentRef = useRef<HTMLDivElement | null>(null)
  const isMobile = useIsMobile()
  const location = useLocation()
  // initial state set to false until updated in useEffect once componentRef is set
  const [horizontalScrolls, setHorizontalScrolls] = useState({
    leftScroll: false,
    rightScroll: false,
  })

  const canShowArrow = tabVariant !== "grey-track" && isMobile
  const showRightArrow = horizontalScrolls.rightScroll && canShowArrow
  const showLeftArrow = horizontalScrolls.leftScroll && canShowArrow

  const classes = useStyles({
    tabVariant,
    size,
    showRightArrow,
    showLeftArrow,
    fullWidth,
  })

  const updateHorizontalScrolls = useCallback(() => {
    setHorizontalScrolls(hasHorizontalScrolls(componentRef))
  }, [])

  useOnWindowResize(updateHorizontalScrolls)

  const handleRef = useCallback(
    (e: HTMLDivElement | null) => {
      componentRef.current = e
      // Set horizontal scrolls once componentRef is set
      if (e) {
        updateHorizontalScrolls()
      }
    },
    [updateHorizontalScrolls]
  )

  return (
    <div className={classNames(classes.arrowsContainer, customClasses?.arrowsContainer)}>
      {showLeftArrow && (
        <DiscoIconButton onClick={scrollLeft}>
          <ChevronLeftIcon />
        </DiscoIconButton>
      )}
      <div
        ref={handleRef}
        className={classNames(classes.tabsContainer, className)}
        onScroll={updateHorizontalScrolls}
      >
        {routes.map((route: DiscoTabsRoute) => {
          const isActiveDefault =
            route.pathname &&
            (exact
              ? location.pathname === route.pathname
              : location.pathname.includes(route.pathname))
          const { active = isActiveDefault } = route
          return (
            <DiscoTab
              key={route.label}
              testid={`${testid}.${route.testid}`}
              to={route.pathname}
              active={active || false}
              // Use route.tabClassname if it exists, otherwise fall back to tabClassname
              className={classNames(classes.tab, route.tabClassname || tabClassname)}
              variant={tabVariant}
              notificationConfig={route.notificationConfig}
              onClick={route.onClick}
              leftIcon={route.leftIcon}
              rightContent={route.rightContent}
              notifications={route.notifications}
              count={route.count}
              activeColor={route.activeColor}
              countBadgeProps={countBadgeProps}
              size={size}
            >
              {route.label}
            </DiscoTab>
          )
        })}
      </div>
      {showRightArrow && (
        <DiscoIconButton onClick={scrollRight}>
          <ChevronRightIcon />
        </DiscoIconButton>
      )}
    </div>
  )

  function scrollLeft() {
    if (componentRef.current) {
      componentRef.current.scroll({ left: componentRef.current.scrollLeft - 125 })
    }
  }

  function scrollRight() {
    if (componentRef.current) {
      componentRef.current.scroll({ left: componentRef.current.scrollLeft + 125 })
    }
  }
}

type StyleProps = {
  tabVariant: DiscoTabProps["variant"]
  size: DiscoTabProps["size"]
  showRightArrow: boolean
  showLeftArrow: boolean
  fullWidth: boolean
}

const useStyles = makeUseStyles((theme) => ({
  arrowsContainer: (props: StyleProps) => ({
    display: "grid",
    gridTemplateColumns: `${props.showLeftArrow ? "max-content" : ""} 1fr ${
      props.showRightArrow ? "max-content" : ""
    }`,
    ...styleIf(props.tabVariant === "grey-track", {
      gridTemplateColumns: props.fullWidth ? "1fr" : "max-content",
    }),
  }),
  tabsContainer: (props: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    scrollBehavior: "smooth",
    ...styleIf(props.tabVariant === "grey-track", {
      gap: theme.spacing(0.5),
      display: "inline-flex",
      backgroundColor: theme.palette.groovy.neutral[100],
      borderRadius: theme.measure.borderRadius.big,
      marginBottom: theme.spacing(1),
      padding: theme.spacing(0.5),
    }),
    ...styleIf(props.size === "small", {
      gap: 0,
    }),
  }),
  tab: (props: StyleProps) => ({
    ...styleIf(props.fullWidth, {
      flexGrow: 1,
      justifyContent: "center",
    }),
  }),
}))

export default DiscoTabs

export function DiscoTabsSkeleton(props: Partial<DiscoTabsProps> & { count?: number }) {
  const { count = 2, tabVariant, size, className, fullWidth = false } = props
  const classes = useStyles({
    tabVariant,
    size,
    showRightArrow: false,
    showLeftArrow: false,
    fullWidth,
  })

  return (
    <div className={classNames(classes.tabsContainer, className)}>
      {range(count).map((i) => (
        <DiscoTextSkeleton key={i} width={"100px"} />
      ))}
    </div>
  )
}
