/**
 * @generated SignedSource<<c0c74c9481f0fc4dc6253ce47ab2c3a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetTranscriptionStatus = "draft" | "published" | "%future added value";
export type UpdateAssetTranscriptionInput = {
  assetTranscriptionId: string;
  summary?: string | null;
  chaptersText?: string | null;
  status?: AssetTranscriptionStatus | null;
  productId?: string | null;
};
export type DiscoInteractivePlayerFormMutation$variables = {
  input: UpdateAssetTranscriptionInput;
};
export type DiscoInteractivePlayerFormMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly summary: string | null;
      readonly chapters: ReadonlyArray<{
        readonly startTime: number;
        readonly timecode: string;
        readonly title: string;
      }> | null;
      readonly " $fragmentSpreads": FragmentRefs<"InteractivePlayerOverviewTabContentFragment" | "InteractivePlayerTranscriptTabContentFragment">;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type DiscoInteractivePlayerFormMutation = {
  variables: DiscoInteractivePlayerFormMutation$variables;
  response: DiscoInteractivePlayerFormMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summary",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetTranscriptionChapter",
  "kind": "LinkedField",
  "name": "chapters",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timecode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DiscoInteractivePlayerFormMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateAssetTranscriptionResponse",
        "kind": "LinkedField",
        "name": "updateAssetTranscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetTranscription",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InteractivePlayerOverviewTabContentFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InteractivePlayerTranscriptTabContentFragment"
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DiscoInteractivePlayerFormMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateAssetTranscriptionResponse",
        "kind": "LinkedField",
        "name": "updateAssetTranscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetTranscription",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetTranscriptionSegment",
                "kind": "LinkedField",
                "name": "segments",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "start",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startTimecode",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3524e045453bf0a1e2e0e61eec9e6cff",
    "id": null,
    "metadata": {},
    "name": "DiscoInteractivePlayerFormMutation",
    "operationKind": "mutation",
    "text": "mutation DiscoInteractivePlayerFormMutation(\n  $input: UpdateAssetTranscriptionInput!\n) {\n  response: updateAssetTranscription(input: $input) {\n    node {\n      id\n      summary\n      chapters {\n        startTime\n        timecode\n        title\n      }\n      ...InteractivePlayerOverviewTabContentFragment\n      ...InteractivePlayerTranscriptTabContentFragment\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment InteractivePlayerOverviewTabContentFragment on AssetTranscription {\n  id\n  summary\n  chapters {\n    title\n    startTime\n    timecode\n  }\n}\n\nfragment InteractivePlayerTranscriptTabContentFragment on AssetTranscription {\n  ...TranscriptReaderFragment\n}\n\nfragment TranscriptReaderFragment on AssetTranscription {\n  segments {\n    text\n    start\n    startTimecode\n  }\n}\n"
  }
};
})();

(node as any).hash = "168f1b3dee30dd9f6f09b0841b8e9833";

export default node;
