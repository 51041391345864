import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import useSkeletonAnimationClasses from "@assets/style/util/useSkeletonAnimationClasses"
import { Rating, RatingProps } from "@material-ui/lab"
import React from "react"

interface DiscoRatingProps extends RatingProps {
  testid?: string
  className?: string
}

const DiscoRating: React.FC<DiscoRatingProps> = ({ testid, className, ...props }) => {
  const classes = useStyles({ hasIcon: Boolean(props.icon) })

  return (
    <Rating
      classes={{
        icon: classes.icon,
        iconActive: classes.iconActiveFilled,
        iconFilled: classes.iconActiveFilled,
      }}
      data-testid={testid}
      className={className}
      {...props}
    />
  )
}

type StyleProps = {
  hasIcon?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  icon: ({ hasIcon }: StyleProps) => ({
    /** Rating's size prop is not working for an unknown reason. Modify the svg size directly */
    "& svg": {
      ...styleIf(!hasIcon, {
        width: "24px",
        height: "24px",
      }),
    },
  }),
  iconActiveFilled: {
    "& svg": {
      color: theme.palette.groovy.yellow[400],
    },
  },
}))

export function DiscoRatingSkeleton() {
  const classes = useSkeletonAnimationClasses()
  return <DiscoRating value={0} readOnly disabled className={classes.pulse} />
}

export default DiscoRating
