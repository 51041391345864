import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoIconKinds, DiscoText } from "@disco-ui"
import { ReactNode } from "react"

export type DiscoSidebarHeaderProps = {
  title: string
  icon: DiscoIconKinds
  actions?: ReactNode
}

export default function DiscoSidebarHeader(props: DiscoSidebarHeaderProps) {
  const { title, icon, actions } = props
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <DiscoIcon icon={icon} active height={24} width={24} />
      </div>
      <DiscoText
        variant={"body-md-500"}
        truncateText={1}
        className={classes.title}
        testid={"DiscoSidebarHeader.title"}
      >
        {title}
      </DiscoText>
      {actions && <div className={classes.actions}>{actions}</div>}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  iconContainer: {
    display: "flex",
    padding: theme.spacing(1),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[400]
        : theme.palette.groovy.neutral[200],
    borderRadius: theme.measure.borderRadius.medium,
  },
  title: {
    flexGrow: 1,
  },
  actions: {
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
  },
}))
