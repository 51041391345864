import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import { FormLabel, FormLabelProps } from "@material-ui/core"

function DiscoFormLabel({ children, classes: propsClasses, ...props }: FormLabelProps) {
  const classes = useStyles()
  return (
    <FormLabel classes={mergeClasses(classes, propsClasses)} {...props}>
      {children}
    </FormLabel>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    textAlign: "left",
    ...theme.typography["body-sm"],
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    "&.Mui-focused": {
      color: theme.palette.text.secondary,
    },
    "&.Mui-error": {
      color: theme.palette.text.danger,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(0.5),
      fontSize: "12px",
      lineHeight: "12px",
      fontWeight: 400,
    },
  },
}))

export default DiscoFormLabel
