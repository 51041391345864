import StartAssetTranscriptionJobButton from "@/admin/asset/transcription/StartAssetTranscriptionJobButton"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AITag from "@components/ai/AITag"
import AiText from "@components/ai/AIText"
import AITextButton from "@components/ai/AITextButton"
import WithEntitlement from "@components/entitlement/WithEntitlement"
import DiscoAlert from "@disco-ui/alert/DiscoAlert"
import DiscoIcon from "@disco-ui/icon/DiscoIcon"
import DiscoSpinner from "@disco-ui/spinner/DiscoSpinner"
import DiscoText from "@disco-ui/text/DiscoText"
import DiscoTooltip from "@disco-ui/tooltip/DiscoTooltip"
import { DiscoInteractivePlayerMode } from "@disco-ui/video/interactive-player/DiscoInteractivePlayer"
import useTranscriptionPermissions from "@disco-ui/video/interactive-player/hooks/useTranscriptionPermissions"
import { InteractivePlayerTranscriptionStatusFragment$key } from "@disco-ui/video/interactive-player/__generated__/InteractivePlayerTranscriptionStatusFragment.graphql"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  assetKey: InteractivePlayerTranscriptionStatusFragment$key
  mode: DiscoInteractivePlayerMode
  onTranscriptionJobStart?: () => void
}

function InteractivePlayerTranscriptionStatus(props: Props) {
  const { assetKey, mode, onTranscriptionJobStart } = props

  const classes = useStyles()

  const { videoTranscription } = useFeatureFlags()
  const asset = useFragment<InteractivePlayerTranscriptionStatusFragment$key>(
    graphql`
      fragment InteractivePlayerTranscriptionStatusFragment on Asset {
        id
        videoAssetsEnabledAt
        transcriptionJob {
          status
          sieveJobErrorReason
        }
        transcription {
          id
        }
      }
    `,
    assetKey
  )

  const { transcriptionJob, transcription, videoAssetsEnabledAt } = asset
  const hasAIAssets = Boolean(transcription)

  const { canManage } = useTranscriptionPermissions()
  const canCreate = !hasAIAssets && videoTranscription && canManage

  return (
    <>
      {renderStatus()}
      {hasAIAssets && canManage && mode === "edit" && (
        <DiscoAlert
          margin={2}
          icon={<DiscoIcon icon={"info"} />}
          severity={"warning"}
          message={
            "Edit assets here to instantly update summary, chapters for this video wherever it's included."
          }
        />
      )}
    </>
  )

  function renderStatus() {
    if (transcription) {
      return null
    }

    // If the user can create AI assets, show a button to enable the generated assets
    if (canCreate && !videoAssetsEnabledAt) {
      return (
        <div className={classes.ctaContainer}>
          <WithEntitlement entitlement={"ai_video_transcription"}>
            {({ hasEntitlement }) => (
              <DiscoTooltip
                content={
                  "Create video summary, chapters, and transcript with Disco AI for free while it is in Beta."
                }
                placement={"bottom"}
                disabled={!hasEntitlement}
              >
                <span>
                  <StartAssetTranscriptionJobButton
                    assetId={asset.id}
                    onStart={onTranscriptionJobStart}
                  >
                    {(btnProps) => (
                      <AITextButton
                        {...btnProps}
                        leftIcon={"stars"}
                        disabled={!hasEntitlement}
                      >
                        {"Generate Video Assets with AI"}
                        <AITag className={classes.ctaBetaTag} name={"BETA"} />
                      </AITextButton>
                    )}
                  </StartAssetTranscriptionJobButton>
                </span>
              </DiscoTooltip>
            )}
          </WithEntitlement>
        </div>
      )
    }

    // If the job is pending, show a processing state
    if (transcriptionJob?.status === "pending") {
      return (
        <div className={classes.ctaContainer}>
          <DiscoSpinner size={"sm"} fullWidth={false} />
          <DiscoTooltip
            content={
              "Check back later for the transcription, or refresh the page to see if the assets have been generated."
            }
            placement={"bottom"}
          >
            <span>
              <AiText>{"Processing"}</AiText>
            </span>
          </DiscoTooltip>
        </div>
      )
    }

    // If the video has no audio
    // show a message and prevent the user from creating AI assets
    if (
      transcriptionJob?.status === "failed" &&
      transcriptionJob?.sieveJobErrorReason === "no_audio"
    ) {
      return <DiscoText>{"No audio detected in the video"}</DiscoText>
    }

    return null
  }
}

const useStyles = makeUseStyles((theme) => ({
  ctaContainer: {
    padding: theme.spacing(2.5, 2.5, 2.5),
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  ctaBetaTag: {
    marginLeft: theme.spacing(1),
  },
}))

export default InteractivePlayerTranscriptionStatus
