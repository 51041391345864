/**
 * @generated SignedSource<<50af2b6098ebaffab20a5eac60ff8a53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductAppKind = "content" | "link" | "curriculum" | "events" | "members" | "resources" | "slack" | "collection" | "product_link" | "organization_events" | "organization_members" | "chat_channel" | "posts" | "nav_folder" | "%future added value";
export type ProductAppStatus = "active" | "inactive" | "%future added value";
export type ProductAppVisibility = "all" | "admin" | "membership" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductAppListItemFragment$data = {
  readonly id: string;
  readonly kind: ProductAppKind;
  readonly status: ProductAppStatus;
  readonly visibility: ProductAppVisibility;
  readonly customAppTitle: string | null;
  readonly customAppUrl: string | null;
  readonly badge: {
    readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
  };
  readonly product: {
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  } | null;
  readonly chatChannel: {
    readonly id: string;
  } | null;
  readonly feed: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly collection: {
    readonly id: string;
  } | null;
  readonly contentUsage: {
    readonly id: string;
  } | null;
  readonly productApps: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"ProductAppListItemChildFragment">;
      };
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"RemoveAppButtonFragment" | "ToggleAppStatusButtonFragment">;
  readonly " $fragmentType": "ProductAppListItemFragment";
};
export type ProductAppListItemFragment$key = {
  readonly " $data"?: ProductAppListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductAppListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductAppListItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BadgeFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "usePermissionsFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatChannel",
      "kind": "LinkedField",
      "name": "chatChannel",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Feed",
      "kind": "LinkedField",
      "name": "feed",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Collection",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentUsage",
      "kind": "LinkedField",
      "name": "contentUsage",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoveAppButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ToggleAppStatusButtonFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAppNodeConnection",
      "kind": "LinkedField",
      "name": "productApps",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAppNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductApp",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProductAppListItemChildFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};
})();

(node as any).hash = "4dab0ca5e5884376cf8c942964920598";

export default node;
