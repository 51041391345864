import ChevronIcon from "@/core/ui/iconsax/linear/arrow-up-1.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Swiper, { SwiperProps } from "@components/carousel/Swiper"
import SwiperSlide from "@components/carousel/SwiperSlide"
import { DiscoIconButtonSkeleton, DiscoSection, DiscoTextSkeleton } from "@disco-ui"
import { DiscoCarouselItemSkeleton } from "@disco-ui/carousel/DiscoCarouselItem"
import { roundDownToNearestOdd } from "@disco-ui/carousel/utils/CarouselUtils"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import DiscoText from "@disco-ui/text/DiscoText"
import { IconButton, Theme, useMediaQuery, useTheme } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { SkeletonProps } from "@material-ui/lab/Skeleton"
import { range } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import React, { Fragment, useMemo, useState } from "react"
import { Swiper as SwiperType } from "swiper"

export type DiscoCarouselProps<T> = TestIDProps & {
  classes?: Partial<ClassNameMap<"carousel" | "actions">>
  /** The connection data */
  data: T[]
  /**
   * Number of visible slides in the carousel
   * prefer to pass `breakpoints` as this will set a fixed number of slides for all screen sizes
   */
  slidesPerView?: SwiperProps["config"]["slidesPerView"]
  centeredSlides?: boolean
  hideIndicator?: boolean
  /**
   * Specify the number of slides that should be visible at designated breakpoints
   * @key {0} = xs+
   * @key {600} = sm+
   * @key {1280} = lg+
   * these are logical breakpoints that work with DiscoPage/Content
   */
  breakpoints?: NonNullable<SwiperProps["config"]>["breakpoints"]
  title?: string | React.ReactNode
  subTitle?: string | React.ReactNode
  /** Optional empty state, returns `null` by default if empty */
  emptyState?: React.ReactNode
  className?: string
  /** Additional CTA's beside the carousel navigation buttons */
  moreActions?: React.ReactNode | null
  /** Optional alternative starting index
   * @example
   * items = ["cat", "cat", "dog"]
   * condition = "dog"
   * @returns {Number} startingIndex = 2
   * Since we want to find the index which "dog" first appears in our array
   */
  startingIndex?: number
  totalCount: number
  refetch?: {
    isLoading: boolean
    hasNext: boolean
    hasPrevious: boolean
    loadMore: VoidFunction
  }
  /** Render method for each item */
  item: (item: T, index: number) => React.ReactElement<{ key: string }>
  itemSkeleton?: React.ReactElement
  isDashboardBlock?: boolean
}

const DiscoCarousel = <T extends Record<string, any>>({
  title,
  subTitle,
  emptyState = null,
  className,
  moreActions,
  testid = "DiscoCarousel",

  startingIndex,
  slidesPerView,
  breakpoints,
  centeredSlides = true,
  hideIndicator = false,

  totalCount,
  refetch,
  data,
  item: _renderItem,
  itemSkeleton,
  classes: customClasses,
  isDashboardBlock = false,
}: DiscoCarouselProps<T>) => {
  const [carouselState, setCarouselState] = useState<SwiperType | null>(null)
  const [nextEl, setNextEl] = useState<HTMLButtonElement | null>(null)
  const [prevEl, setPrevEl] = useState<HTMLButtonElement | null>(null)

  const config = useMemo(() => {
    return {
      navigation: { nextEl, prevEl },
      initialSlide: startingIndex,
      slidesPerView,
      breakpoints,
      setParentCarouselState: setCarouselState,
      totalCount,
      currentSliceSize: data.length,
      centeredSlides,
    }
  }, [
    breakpoints,
    nextEl,
    prevEl,
    slidesPerView,
    centeredSlides,
    startingIndex,
    totalCount,
    data.length,
  ])

  const { slidesPerView: currentSlidesPerView } = carouselState?.params ?? {}
  const currentSPVIsNum = typeof currentSlidesPerView === "number"
  const sectionPadding = 2
  const classes = useStyles({
    slidesPerView: currentSPVIsNum ? currentSlidesPerView : undefined,
    sectionPadding,
    isDashboardBlock,
  })
  const theme = useTheme()

  // fix issue where buttons are not disabled when all items are visible within the carousel track
  const navButtonsDisabled = currentSPVIsNum
    ? totalCount < currentSlidesPerView
    : undefined
  const isNextButtonDisabled = navButtonsDisabled ?? carouselState?.isEnd
  const isPrevButtonDisabled = navButtonsDisabled ?? carouselState?.isBeginning

  const shouldShowIndicator =
    Boolean(totalCount) &&
    currentSlidesPerView &&
    carouselState?.activeIndex !== undefined &&
    currentSPVIsNum &&
    !hideIndicator
  const shouldShowHeader = title || shouldShowIndicator || moreActions

  return (
    <DiscoSection
      data-testid={testid}
      className={classNames(classes.paperContainer, className)}
      paddingTop={sectionPadding}
      paddingLeft={sectionPadding}
      paddingRight={sectionPadding}
      // bottom padding is 20px - 16px bottom margin on items for dropshadow = 4px
      paddingBottom={0.5}
    >
      {/* title and page indicator will overflow if tittle is too long */}
      {shouldShowHeader && (
        <div className={classes.header}>
          <div className={classes.titleContainer}>
            {/* Title */}
            {title &&
              (typeof title === "string" ? (
                <DiscoText variant={"body-lg-600"} testid={`${testid}.title`}>
                  {title}
                </DiscoText>
              ) : (
                title
              ))}
            {subTitle &&
              (typeof subTitle === "string" ? (
                <DiscoText
                  variant={"body-xs"}
                  truncateText={1}
                  component={"span"}
                  testid={`${testid}.subTitle`}
                >
                  {subTitle}
                </DiscoText>
              ) : (
                subTitle
              ))}
          </div>
          <div className={classNames(classes.actions, customClasses?.actions)}>
            {/* Page indicator */}
            {shouldShowIndicator && (
              <div className={classes.pageIndicatorContainer}>
                <DiscoTag
                  name={`${
                    totalCount < currentSlidesPerView
                      ? totalCount
                      : Math.max(Math.ceil(Math.floor(currentSlidesPerView) / 2), 1) +
                        carouselState.activeIndex
                  }/${totalCount}`}
                  backgroundColor={theme.palette.primary.light}
                  color={theme.palette.primary.main}
                />
              </div>
            )}
            {/* Actions */}
            {moreActions}
          </div>
        </div>
      )}

      {/* Items */}
      {data.length ? (
        <>
          <Swiper
            config={config}
            className={classNames(classes.carousel, customClasses?.carousel)}
          >
            {data.map((item, i) => (
              <SwiperSlide key={item.id} index={i}>
                {_renderItem(item, i)}
              </SwiperSlide>
            ))}

            {data.length !== totalCount && refetch?.hasNext && (
              <SwiperSlide>
                <DiscoScrolledIntoView
                  className={classes.loadingContainer}
                  isLoading={refetch.isLoading}
                  onScrolledIntoView={refetch.loadMore}
                  skeleton={itemSkeleton ?? <DiscoCarouselItemSkeleton />}
                />
              </SwiperSlide>
            )}
          </Swiper>

          {/* Back */}
          <IconButton
            ref={(el) => setPrevEl(el)}
            className={classNames(classes.navButton, classes.prevNavButton)}
            data-testid={`${testid}.previous-button`}
            disabled={isPrevButtonDisabled}
          >
            <ChevronIcon
              className={classes.leftChevron}
              width={24}
              height={24}
              fill={theme.palette.groovy.neutral[500]}
            />
          </IconButton>

          {/* Forward */}
          <IconButton
            ref={(el) => setNextEl(el)}
            className={classNames(classes.navButton, classes.nextNavButton)}
            data-testid={`${testid}.next-button`}
            disabled={isNextButtonDisabled}
          >
            <ChevronIcon
              className={classes.rightChevron}
              width={24}
              height={24}
              fill={theme.palette.groovy.neutral[500]}
            />
          </IconButton>
        </>
      ) : (
        emptyState
      )}
    </DiscoSection>
  )
}

type StyleProps = {
  slidesPerView?: number
  sectionPadding?: number
  isDashboardBlock?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  paperContainer: ({ isDashboardBlock }: StyleProps) => ({
    position: "relative",
    border: isDashboardBlock ? theme.palette.constants.borderSmall : undefined,
    boxShadow: isDashboardBlock ? theme.palette.groovyDepths.xs : undefined,
  }),
  carousel: {
    // use a negative margin to extend the carousel to the edges of the DiscoSection
    // when only 1 item is visible, we want to respect the padding around the DiscoSection
    margin: ({ slidesPerView, sectionPadding = 2 }: StyleProps) =>
      slidesPerView === 1 ? theme.spacing(0) : theme.spacing(0, -sectionPadding),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    flexWrap: "wrap",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  pageIndicatorContainer: {
    minWidth: "44px",
    textAlign: "center",
  },
  actions: {
    display: "flex",
    gap: theme.spacing(0.25),
    alignItems: "center",
  },
  leftChevron: {
    transform: "rotate(270deg)",
  },
  rightChevron: {
    transform: "rotate(90deg)",
  },
  navButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,

    transition: "background-color 0.4s ease",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.groovyDepths.boxShadow,

    "&.swiper-button-disabled, &.swiper-button-lock, &.Mui-disabled": {
      display: "none",
    },

    "&:hover": {
      background: theme.palette.groovy.neutral[100],
    },
  },
  prevNavButton: {
    left: -24,

    [theme.breakpoints.down("xs")]: {
      left: -4,
    },
  },
  nextNavButton: {
    right: -24,

    [theme.breakpoints.down("xs")]: {
      right: -4,
    },
  },
  loadingContainer: {
    display: "flex",
  },
}))

type DiscoCarouselSkeletonProps = Omit<
  DiscoCarouselProps<any>,
  "data" | "Item" | "totalCount" | "refetch" | "title" | "item"
> &
  SkeletonProps & {
    item: React.ReactElement
    showTitle?: boolean
    showHeader?: boolean
  }

function useCarouselSkeletonSliceSize(maxSize: number | undefined = 3) {
  // breakpoints should match the breakpoints passed to Swiper on initialization
  const isLgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"))
  const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  // round up to the nearest whole number for skeletons
  if (isLgUp) return Math.max(roundDownToNearestOdd(maxSize), 1)
  if (isSmUp) return Math.max(maxSize - 1, 1)
  return 1
}

export const DiscoCarouselSkeleton: React.FC<DiscoCarouselSkeletonProps> = ({
  item,
  showTitle = false,
  showHeader = true,
  ...props
}) => {
  const classes = useStyles({ slidesPerView: 1 })
  const theme = useTheme()
  // handle responsive slidesPerView
  const slidesPerView = useCarouselSkeletonSliceSize(
    typeof props?.slidesPerView === "number" ? props.slidesPerView : undefined
  )

  return (
    <DiscoSection
      className={classNames(classes.paperContainer, props.className)}
      padding={2.5}
    >
      {showHeader && (
        <div className={classes.header} style={{ width: "100%" }}>
          {showTitle && <DiscoTextSkeleton variant={"body-lg-600"} width={"60%"} />}

          <div
            className={classNames(classes.actions, props.classes?.actions)}
            style={{ justifyContent: "flex-end" }}
          >
            <DiscoIconButtonSkeleton width={24} height={24} />
            <DiscoIconButtonSkeleton width={24} height={24} />
          </div>
        </div>
      )}

      <div style={{ display: "flex", gap: theme.spacing(2), width: "100%" }}>
        {range(Math.round(slidesPerView)).map((i) => (
          <Fragment key={i}>{item}</Fragment>
        ))}
      </div>
    </DiscoSection>
  )
}

export default DiscoCarousel
